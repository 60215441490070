// Normalize Styles
// @import "cc-solutions/variables";

// Import Node Modules
// SCSS
// CSS
@import 'node_modules/flickity/css/flickity';

// Import Modules
// @import '../_modules/features/features';

@import "cc-solutions/common";
@import "cc-solutions/legacy";
@import "cc-solutions/cardconnect";
@import "cc-solutions/additions";


// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
