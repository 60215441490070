@font-face {
  font-family: MarkPro;
  font-weight: 400;
  src: url("../fonts/MarkPro.otf") format("opentype")
}

@font-face {
  font-family: MarkPro;
  font-weight: 300;
  src: url("../fonts/MarkPro-Light.otf") format("opentype")
}

@font-face {
  font-family: MarkPro;
  font-weight: 500;
  src: url("../fonts/MarkPro-Medium.otf") format("opentype")
}

@font-face {
  font-family: MarkPro;
  font-weight: 700;
  src: url("../fonts/MarkPro-Bold.otf") format("opentype")
}

.footer__list {
  list-style-type: none;
  padding-left: 0
}

.footer__section-title, .footer__smallprint p, .header__contact {
  font-size: 12px
}

.footer__item-text, .footer__link {
  font-size: 15px
}

.header {
  position: absolute;
  width: 100%;
  z-index: 5;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.header--subnav-active {
  background: #fff
}

.header__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 0
}

@media (min-width:576px) {
  .header__container {
      padding: 24px 0
  }
}

.header__contact {
  left: 15px;
  position: absolute;
  top: 100%;
  z-index: 2
}

@media (min-width:576px) {
  .header__contact {
      font-size: 14px
  }
}

.header__contact-item {
  color: #0a4a6e;
  display: block
}

.header__contact-item:focus, .header__contact-item:hover {
  color: #005d92
}

.page--partner:not(.page--signup) .header__container {
  padding: 15px 0
}

.header-logo {
  position: relative;
  width: 152px;
  z-index: 3
}

.header-logo svg {
  display: block;
  height: 33px;
  width: 152px
}

@media (min-width:576px) {
  .header-logo svg {
      height: 37px;
      width: 170px
  }
}

.header-logo path {
  fill: #0a4a6e;
  -webkit-transition: fill .2s ease-out;
  transition: fill .2s ease-out
}

.header-logo:focus path, .header-logo:hover path {
  fill: #005d92
}

@media (min-width:576px) {
  .header-logo {
      width: 170px
  }
}

.header--subnav-active .header-logo path {
  fill: #0a4a6e !important
}

.header__nav-opener {
  background: none;
  border: none;
  display: inline-block;
  height: 14px;
  margin-left: auto;
  position: relative;
  width: 18px;
  z-index: 3
}

.header__nav-opener .menu {
  display: block
}

.header__nav-opener .menu_bg {
  fill: #8e939e
}

.header__nav-opener:focus .menu_bg, .header__nav-opener:hover .menu_bg {
  fill: #676d78
}

@media (min-width:576px) {
  .header__nav-opener {
      display: none
  }
}

.page--blog.page--tertiary:not(.page--tags) .header-logo path,
.page--blog.page--tertiary:not(.page--tags) .header__open-nav .menu_bg,
.page--erp-payments-news .header-logo path, .page--erp-payments-news .header__open-nav .menu_bg,
.page--ignite .header-logo path, .page--ignite .header__open-nav .menu_bg,
.page--investor-relations .header-logo path, .page--investor-relations .header__open-nav .menu_bg,
.page--news.page--tertiary:not(.page--tags) .header-logo path, .page--news.page--tertiary:not(.page--tags) .header__open-nav .menu_bg,
.page--newsletter .header-logo path, .page--newsletter .header__open-nav .menu_bg,
.page--privacy-policy .header-logo path, .page--privacy-policy .header__open-nav .menu_bg,
.page--retailnow .header-logo path, .page--retailnow .header__open-nav .menu_bg {
  fill: #fff
}

.page--blog.page--tertiary:not(.page--tags) .header-logo:focus path,
.page--blog.page--tertiary:not(.page--tags) .header-logo:hover path,
.page--erp-payments-news .header-logo:focus path, .page--erp-payments-news .header-logo:hover path,
.page--ignite .header-logo:focus path, .page--ignite .header-logo:hover path,
.page--investor-relations .header-logo:focus path, .page--investor-relations .header-logo:hover path,
.page--news.page--tertiary:not(.page--tags) .header-logo:focus path,
.page--news.page--tertiary:not(.page--tags) .header-logo:hover path,
.page--newsletter .header-logo:focus path, .page--newsletter .header-logo:hover path,
.page--privacy-policy .header-logo:focus path, .page--privacy-policy .header-logo:hover path,
.page--retailnow .header-logo:focus path, .page--retailnow .header-logo:hover path {
  fill: hsla(0, 0%, 100%, .9)
}

.page--blog.page--tertiary:not(.page--tags) .menu-trigger:not(.active) .line,
.page--erp-payments-news .menu-trigger:not(.active) .line, .page--ignite .menu-trigger:not(.active) .line,
.page--investor-relations .menu-trigger:not(.active) .line, .page--news.page--tertiary:not(.page--tags) .menu-trigger:not(.active) .line,
.page--newsletter .menu-trigger:not(.active) .line, .page--privacy-policy .menu-trigger:not(.active) .line,
.page--retailnow .menu-trigger:not(.active) .line {
  background: #fff
}

.menu-trigger {
  position: relative;
  cursor: pointer;
  vertical-align: top;
  right: 15px;
  top: 3px
}

.menu-trigger:after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%)
}

.menu-trigger .line {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  background: #676d78;
  -webkit-transition: .3s;
  transition: .3s
}

.menu-trigger .line.line-1 {
  -webkit-transition: all .3s .3s, -webkit-transform .3s 0s;
  transition: all .3s .3s, -webkit-transform .3s 0s;
  transition: all .3s .3s, transform .3s 0s;
  transition: all .3s .3s, transform .3s 0s, -webkit-transform .3s 0s
}

.menu-trigger .line.line-2 {
  top: 50%;
  margin-top: -1px;
  left: 0;
  -webkit-transition: all .3s .3s;
  transition: all .3s .3s
}

.menu-trigger .line.line-3 {
  bottom: 0;
  top: auto;
  left: 0;
  -webkit-transition: all .3s .3s, -webkit-transform .3s 0s;
  transition: all .3s .3s, -webkit-transform .3s 0s;
  transition: all .3s .3s, transform .3s 0s;
  transition: all .3s .3s, transform .3s 0s, -webkit-transform .3s 0s
}

.menu-trigger.active .line-1 {
  top: 50%;
  margin-top: -1px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all .3s, -webkit-transform .3s .3s;
  transition: all .3s, -webkit-transform .3s .3s;
  transition: all .3s, transform .3s .3s;
  transition: all .3s, transform .3s .3s, -webkit-transform .3s .3s
}

.menu-trigger.active .line-2 {
  opacity: 0;
  -webkit-transition: all .3s, opacity .3s 0s;
  transition: all .3s, opacity .3s 0s
}

.menu-trigger.active .line-3 {
  bottom: 50%;
  margin-bottom: -1px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all .3s, -webkit-transform .3s .3s;
  transition: all .3s, -webkit-transform .3s .3s;
  transition: all .3s, transform .3s .3s;
  transition: all .3s, transform .3s .3s, -webkit-transform .3s .3s
}

.footer {
  background: #173750
}

.footer__home {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 45px;
  text-align: center
}

@media (min-width:576px) {
  .footer__home {
      text-align: left
  }
}

.footer__home .footer__home-link {
  opacity: .4;
  color: #fff
}

.footer__home .footer__home-link path {
  fill: currentColor
}

.footer__home .footer__home-link:focus, .footer__home .footer__home-link:hover {
  opacity: .7
}

.footer__container {
  max-width: 1120px;
  padding-top: 65px
}

.footer__section-container {
  margin-bottom: 15px
}

.footer__section, .footer__section-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.footer__section-title {
  color: #fff;
  text-transform: uppercase
}

.footer__list {
  margin-bottom: 30px;
  margin-top: 20px
}

.footer__item {
  display: block
}

.footer__item--no-icon {
  margin-left: 22px
}

.footer__item--blog svg {
  width: 20px;
  height: 15.2px
}

.footer__item-text, .footer__link {
  color: #fff;
  line-height: 1.73;
  white-space: nowrap
}

.footer__link {
  opacity: .4
}

.footer__link .icon {
  position: relative;
  top: 3px;
  margin-right: 6px
}

.footer__link .icon--youtube {
  padding-left: 3px;
  padding-right: 4px;
  top: 0;
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

.footer__link .icon--linkedin {
  top: 0;
  left: 3px;
  margin-right: 13px
}

.footer__link .icon__bg, .footer__link .icon path, .footer__link .icon svg {
  fill: currentColor
}

.footer__link:focus, .footer__link:hover {
  opacity: .7;
  color: #fff
}

.footer__link:focus .icon__bg, .footer__link:hover .icon__bg {
  fill: currentColor
}

.footer__notice {
  background: #122c40
}

.footer__notice-container {
  max-width: 1120px
}

.footer__smallprint {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center
}

.footer__smallprint p {
  margin-bottom: 0;
  margin-top: 0;
  opacity: .4;
  color: #fff
}

.footer__smallprint p a {
  color: #fff
}

.footer__smallprint p a:focus, .footer__smallprint p a:hover {
  text-decoration: underline
}

.nav {
  position: relative;
  z-index: 2
}

@media (max-width:575px) {
  .nav {
      padding: 20px 0;
      background: #fff
  }
}

@media (max-width:575px) {
  .nav__container {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: #fff;
      -webkit-transform: translateY(63px);
      transform: translateY(63px);
      -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .1);
      box-shadow: 0 4px 5px rgba(0, 0, 0, .1);
      -webkit-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out
  }

  .nav__container:not(.nav--open) {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%)
  }
}

.nav__list {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0
}

@media (min-width:576px) {
  .nav__list {
      text-align: right
  }
}

@media (max-width:575px) {
  .nav__list {
      margin: 0;
      padding: 25px 10px 0;
      border-top: 1px solid #dfdfdf
  }
}

@media (max-width:575px) {
  .nav__item {
      width: 104px;
      margin: 0 0 10px
  }
}

@media (min-width:576px) {
  .nav__item {
      display: inline-block;
      vertical-align: middle
  }
}

@media (max-width:575px) {
  .nav__item--solutions {
      display: none
  }
}

@media (min-width:576px) {
  .nav__item--log-in {
      margin-left: 2.5rem
  }
}

@media (min-width:576px) {
  .nav__item--sign-up {
      margin-left: .5rem
  }
}

.nav__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
  transition: all .3s cubic-bezier(.645, .045, .355, 1)
}

.nav__link, .nav__link:focus, .nav__link:hover {
  color: #173750
}

.header--subnav-active .nav__link {
  color: #173750 !important
}

.nav__link--btn {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  padding: 1rem 1.75rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
  transition: all .3s cubic-bezier(.645, .045, .355, 1)
}

@media (min-width:576px) {
  .nav__link--btn {
      padding: 1rem 2rem;
      font-size: 16px
  }
}

.nav__item--log-in .nav__link {
  color: #173750;
  border: 1px solid #173750
}

.nav__item--log-in .nav__link:focus, .nav__item--log-in .nav__link:hover {
  color: #fff;
  background: #173750
}

.header--subnav-active .nav__item--log-in .nav__link {
  color: #173750 !important;
  border-color: #173750 !important
}

.header--subnav-active .nav__item--log-in .nav__link:focus, .header--subnav-active .nav__item--log-in .nav__link:hover {
  color: #fff !important
}

.nav__item--sign-up .nav__link {
  color: #fff;
  background-color: #0ab7c5;
  border-color: #0ab7c5
}

.nav__item--sign-up .nav__link:focus, .nav__item--sign-up .nav__link:hover {
  color: #fff;
  background-color: #068893;
  border-color: #068893
}

.header--subnav-active .nav__item--sign-up .nav__link {
  color: #fff !important
}

.nav__close {
  background: none;
  border: 0;
  padding: 2px 16px
}

.nav__close:focus, .nav__close:hover {
  color: #5b5f6b
}

@media (max-width:575px) {
  .nav__close {
      position: absolute;
      top: 15px;
      right: 0
  }
}

@media (min-width:576px) {
  .nav__close {
      display: none
  }
}

@media (min-width:576px) {
  .page--blog.page--tertiary:not(.page--tags) .nav__link, .page--erp-payments-news .nav__link,
  .page--ignite .nav__link, .page--investor-relations .nav__link, .page--news.page--tertiary:not(.page--tags) .nav__link,
  .page--newsletter .nav__link, .page--privacy-policy .nav__link, .page--retailnow .nav__link {
      color: #fff
  }

  .page--blog.page--tertiary:not(.page--tags) .nav__link:focus,
  .page--blog.page--tertiary:not(.page--tags) .nav__link:hover, .page--erp-payments-news .nav__link:focus,
  .page--erp-payments-news .nav__link:hover, .page--ignite .nav__link:focus,
  .page--ignite .nav__link:hover, .page--investor-relations .nav__link:focus,
  .page--investor-relations .nav__link:hover, .page--news.page--tertiary:not(.page--tags) .nav__link:focus,
  .page--news.page--tertiary:not(.page--tags) .nav__link:hover, .page--newsletter .nav__link:focus,
  .page--newsletter .nav__link:hover, .page--privacy-policy .nav__link:focus,
  .page--privacy-policy .nav__link:hover, .page--retailnow .nav__link:focus,
  .page--retailnow .nav__link:hover {
      color: hsla(0, 0%, 100%, .9)
  }

  .page--blog.page--tertiary:not(.page--tags) .nav__item--log-in .nav__link,
  .page--erp-payments-news .nav__item--log-in .nav__link, .page--ignite .nav__item--log-in .nav__link,
  .page--investor-relations .nav__item--log-in .nav__link, .page--news.page--tertiary:not(.page--tags) .nav__item--log-in .nav__link,
  .page--newsletter .nav__item--log-in .nav__link, .page--privacy-policy .nav__item--log-in .nav__link,
  .page--retailnow .nav__item--log-in .nav__link {
      border-color: #fff
  }

  .page--blog.page--tertiary:not(.page--tags) .nav__item--log-in .nav__link:focus,
  .page--blog.page--tertiary:not(.page--tags) .nav__item--log-in .nav__link:hover,
  .page--erp-payments-news .nav__item--log-in .nav__link:focus, .page--erp-payments-news .nav__item--log-in .nav__link:hover,
  .page--ignite .nav__item--log-in .nav__link:focus, .page--ignite .nav__item--log-in .nav__link:hover,
  .page--investor-relations .nav__item--log-in .nav__link:focus, .page--investor-relations .nav__item--log-in .nav__link:hover,
  .page--news.page--tertiary:not(.page--tags) .nav__item--log-in .nav__link:focus,
  .page--news.page--tertiary:not(.page--tags) .nav__item--log-in .nav__link:hover,
  .page--newsletter .nav__item--log-in .nav__link:focus, .page--newsletter .nav__item--log-in .nav__link:hover,
  .page--privacy-policy .nav__item--log-in .nav__link:focus, .page--privacy-policy .nav__item--log-in .nav__link:hover,
  .page--retailnow .nav__item--log-in .nav__link:focus, .page--retailnow .nav__item--log-in .nav__link:hover {
      color: #173750 !important;
      background: #fff;
      border-color: #fff
  }

  .page--blog.page--tertiary:not(.page--tags) .nav__item--sign-up .nav__link:focus,
  .page--blog.page--tertiary:not(.page--tags) .nav__item--sign-up .nav__link:hover,
  .page--erp-payments-news .nav__item--sign-up .nav__link:focus, .page--erp-payments-news .nav__item--sign-up .nav__link:hover,
  .page--ignite .nav__item--sign-up .nav__link:focus, .page--ignite .nav__item--sign-up .nav__link:hover,
  .page--investor-relations .nav__item--sign-up .nav__link:focus,
  .page--investor-relations .nav__item--sign-up .nav__link:hover,
  .page--news.page--tertiary:not(.page--tags) .nav__item--sign-up .nav__link:focus,
  .page--news.page--tertiary:not(.page--tags) .nav__item--sign-up .nav__link:hover,
  .page--newsletter .nav__item--sign-up .nav__link:focus, .page--newsletter .nav__item--sign-up .nav__link:hover,
  .page--privacy-policy .nav__item--sign-up .nav__link:focus, .page--privacy-policy .nav__item--sign-up .nav__link:hover,
  .page--retailnow .nav__item--sign-up .nav__link:focus, .page--retailnow .nav__item--sign-up .nav__link:hover {
      color: #fff
  }
}

.subnav {
  width: 100%;
  z-index: 4;
  -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
  transition: all .3s cubic-bezier(.645, .045, .355, 1)
}

@media (min-width:576px) {
  .subnav {
      position: absolute;
      top: 96px;
      left: 0;
      -webkit-box-shadow: 0 2px 17px rgba(0, 0, 0, .2);
      box-shadow: 0 2px 17px rgba(0, 0, 0, .2)
  }

  .page--partner .subnav {
      top: 77px
  }
}

@media (max-width:575px) {
  .subnav {
      margin-top: 5px
  }
}

@media (min-width:576px) {
  .subnav:not(.active) {
      display: none
  }
}

@media (min-width:576px) {
  .subnav:not(.show) {
      opacity: 0
  }
}

@media (min-width:576px) {
  .subnav:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 30px;
      top: 0;
      left: 0;
      z-index: 5;
      -webkit-box-shadow: inset 0 3px 4px rgba(0, 0, 0, .15);
      box-shadow: inset 0 3px 4px rgba(0, 0, 0, .15)
  }
}

.subnav__item-desc {
  margin-top: 16px;
  margin-bottom: 16px
}

.subnav__dropdown-arrow {
  display: inline-block;
  margin-left: 12px;
  line-height: 0
}

.subnav__dropdown-arrow .svg {
  fill: currentColor;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.active .subnav__dropdown-arrow .svg {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1)
}

.subnav__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center
}

@media (max-width:575px) {
  .subnav__menu {
      margin: 0 0 35px;
      text-align: left;
      background: #fff
  }
}

.subnav__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  height: 325px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(181, 189, 202, .97);
  -webkit-transition: background .2s ease-in-out;
  transition: background .2s ease-in-out
}

@media (min-width:992px) {
  .subnav__item {
      width: 25%
  }

  .subnav__item:not(:last-child) {
      border-right: 1px solid #a3afbf
  }
}

@media (max-width:991px) {
  .subnav__item {
      width: 50%;
      border-bottom: 1px solid #a3afbf
  }

  .subnav__item:nth-child(odd) {
      border-right: 1px solid #a3afbf
  }
}

@media (max-width:575px) {
  .subnav__item {
      width: 100%;
      height: auto;
      margin: 0 25px;
      padding: 18px 25px 15px;
      background: #fff;
      border: 1px solid #a3afbf;
      border-radius: 4px
  }

  .subnav__item:not(:last-child) {
      margin-bottom: 10px
  }
}

.subnav__item:hover {
  background: #fff
}

.subnav__item:hover .subnav__item-icon .icon--white {
  opacity: 0
}

.subnav__item:hover .subnav__item-icon .icon--color {
  opacity: 1
}

.subnav__item:hover .subnav__item-desc {
  color: #0a4a6e
}

.subnav__item:hover .subnav__item-byline {
  color: #979797
}

@media (min-width:576px) {
  .subnav__item:not(:hover) .subnav__item-title svg g, .subnav__item:not(:hover) .subnav__item-title svg path {
      fill: #fff
  }
}

.subnav__item a {
  display: block;
  width: 100%;
  height: 100%
}

.subnav__item-icon {
  position: relative;
  height: 105px;
  margin: 25px auto;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out
}

.subnav__item-icon .svg {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0
}

.subnav__item-icon .svg.icon--white {
  opacity: 1
}

@media (max-width:575px) {
  .subnav__item-icon {
      display: none
  }
}

.subnav__item-title {
  -webkit-transition: fill .2s ease-in-out;
  transition: fill .2s ease-in-out
}

.subnav__item-desc {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  color: #fff;
  -webkit-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out
}

@media (max-width:575px) {
  .subnav__item-desc {
      display: none
  }
}

.subnav__item-byline {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  color: hsla(0, 0%, 100%, .5);
  -webkit-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out
}

@media (max-width:575px) {
  .subnav__item-byline {
      margin: 0;
      color: #9e9e9e
  }
}
