@font-face {
  font-family: MarkPro;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MarkPro.eot?#iefix") format("embedded-opentype"), url("../fonts/MarkPro.otf") format("opentype")
}

@font-face {
  font-family: MarkPro;
  font-style: bold;
  font-weight: 600;
  src: url("../fonts/MarkPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/MarkPro-Medium.otf") format("opentype")
}

@font-face {
  font-family: MarkPro;
  font-weight: 300;
  src: url("../fonts/MarkPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/MarkPro-Light.otf") format("opentype")
}

@font-face {
  font-family: MarkPro;
  font-weight: 700;
  src: url("../fonts/MarkPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/MarkPro-Bold.otf") format("opentype")
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

article, aside, details, figcaption, figure, footer, header, hgroup,
main, menu, nav, section, summary {
  display: block
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden], template {
  display: none
}

a {
  background-color: transparent
}

a:active, a:hover {
  outline: 0
}

abbr[title] {
  border-bottom: 1px dotted
}

b, strong {
  font-weight: 700
}

dfn {
  font-style: italic
}

h1 {
  font-size: 2em;
  margin: .75em 0
}

mark {
  background: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -.5em
}

sub {
  bottom: -.25em
}

img {
  border: 0
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 1.5em 40px
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0
}

pre {
  overflow: auto
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

button {
  overflow: visible
}

button, select {
  text-transform: none
}

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled], html input[disabled] {
  cursor: default
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input {
  line-height: normal
}

input[type=checkbox], input[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}

legend {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto
}

optgroup {
  font-weight: 700
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td, th {
  padding: 0
}

.align-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.align-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.align-justify {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.align-spaced {
  -ms-flex-pack: distribute;
  justify-content: space-around
}

.align-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start
}

.align-self-top {
  -ms-flex-item-align: start;
  align-self: flex-start
}

.align-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

.align-self-bottom {
  -ms-flex-item-align: end;
  align-self: flex-end
}

.align-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.align-self-middle {
  -ms-flex-item-align: center;
  align-self: center
}

.align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch
}

.small {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6
}

@media screen and (min-width:40em) {
  .medium {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6
  }
}

@media screen and (min-width:68.75em) {
  .large {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6
  }
}

.row {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap
}

.row .row {
  margin-left: -.9375rem;
  margin-right: -.9375rem
}

.row.expanded {
  max-width: none
}

.row.collapse > .column, .row.collapse > .columns {
  padding-left: 0;
  padding-right: 0
}

.column, .columns {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  padding-left: .9375rem;
  padding-right: .9375rem
}

.column.row.row, .row.row.columns {
  float: none
}

.row .column.row.row, .row .row.row.columns {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0
}

.small-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333333%;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%
}

.small-offset-0 {
  margin-left: 0
}

.small-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%
}

.small-offset-1 {
  margin-left: 8.33333333%
}

.small-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.small-offset-2 {
  margin-left: 16.66666667%
}

.small-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%
}

.small-offset-3 {
  margin-left: 25%
}

.small-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66666667%;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%
}

.small-offset-4 {
  margin-left: 33.33333333%
}

.small-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.small-offset-5 {
  margin-left: 41.66666667%
}

.small-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333333%;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%
}

.small-offset-6 {
  margin-left: 50%
}

.small-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66666667%;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%
}

.small-offset-7 {
  margin-left: 58.33333333%
}

.small-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%
}

.small-offset-8 {
  margin-left: 66.66666667%
}

.small-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333333%;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%
}

.small-offset-9 {
  margin-left: 75%
}

.small-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66666667%;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%
}

.small-offset-10 {
  margin-left: 83.33333333%
}

.small-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.small-offset-11 {
  margin-left: 91.66666667%
}

.small-order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1
}

.small-up-1 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.small-up-1 > .column, .small-up-1 > .columns {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.small-order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2
}

.small-up-2 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.small-up-2 > .column, .small-up-2 > .columns {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.small-order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3
}

.small-up-3 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.small-up-3 > .column, .small-up-3 > .columns {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%
}

.small-order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4
}

.small-up-4 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.small-up-4 > .column, .small-up-4 > .columns {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.small-order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5
}

.small-up-5 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.small-up-5 > .column, .small-up-5 > .columns {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%
}

.small-order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6
}

.small-up-6 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.small-up-6 > .column, .small-up-6 > .columns {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%
}

.small-up-1 > .column, .small-up-1 > .columns {
  width: 100%;
  float: left
}

.small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
  clear: none
}

.small-up-1 > .column:nth-of-type(1n + 1), .small-up-1 > .columns:nth-of-type(1n + 1) {
  clear: both
}

.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left
}

.small-up-2 > .column, .small-up-2 > .columns {
  width: 50%;
  float: left
}

.small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
  clear: none
}

.small-up-2 > .column:nth-of-type(odd), .small-up-2 > .columns:nth-of-type(odd) {
  clear: both
}

.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left
}

.small-up-3 > .column, .small-up-3 > .columns {
  width: 33.33333333%;
  float: left
}

.small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
  clear: none
}

.small-up-3 > .column:nth-of-type(3n + 1), .small-up-3 > .columns:nth-of-type(3n + 1) {
  clear: both
}

.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left
}

.small-up-4 > .column, .small-up-4 > .columns {
  width: 25%;
  float: left
}

.small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
  clear: none
}

.small-up-4 > .column:nth-of-type(4n + 1), .small-up-4 > .columns:nth-of-type(4n + 1) {
  clear: both
}

.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left
}

.small-up-5 > .column, .small-up-5 > .columns {
  width: 20%;
  float: left
}

.small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
  clear: none
}

.small-up-5 > .column:nth-of-type(5n + 1), .small-up-5 > .columns:nth-of-type(5n + 1) {
  clear: both
}

.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left
}

.small-up-6 > .column, .small-up-6 > .columns {
  width: 16.66666667%;
  float: left
}

.small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
  clear: none
}

.small-up-6 > .column:nth-of-type(6n + 1), .small-up-6 > .columns:nth-of-type(6n + 1) {
  clear: both
}

.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left
}

.small-up-7 > .column, .small-up-7 > .columns {
  width: 14.28571429%;
  float: left
}

.small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
  clear: none
}

.small-up-7 > .column:nth-of-type(7n + 1), .small-up-7 > .columns:nth-of-type(7n + 1) {
  clear: both
}

.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left
}

.small-up-8 > .column, .small-up-8 > .columns {
  width: 12.5%;
  float: left
}

.small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
  clear: none
}

.small-up-8 > .column:nth-of-type(8n + 1), .small-up-8 > .columns:nth-of-type(8n + 1) {
  clear: both
}

.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left
}

.small-collapse > .column, .small-collapse > .columns {
  padding-left: 0;
  padding-right: 0
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-left: .9375rem;
  padding-right: .9375rem
}

@media screen and (min-width:40em) {
  .medium-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333333%;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%
  }

  .medium-offset-0 {
      margin-left: 0
  }

  .medium-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%
  }

  .medium-offset-1 {
      margin-left: 8.33333333%
  }

  .medium-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
  }

  .medium-offset-2 {
      margin-left: 16.66666667%
  }

  .medium-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%
  }

  .medium-offset-3 {
      margin-left: 25%
  }

  .medium-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66666667%;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%
  }

  .medium-offset-4 {
      margin-left: 33.33333333%
  }

  .medium-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
  }

  .medium-offset-5 {
      margin-left: 41.66666667%
  }

  .medium-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333333%;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%
  }

  .medium-offset-6 {
      margin-left: 50%
  }

  .medium-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66666667%;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%
  }

  .medium-offset-7 {
      margin-left: 58.33333333%
  }

  .medium-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
  }

  .medium-offset-8 {
      margin-left: 66.66666667%
  }

  .medium-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333333%;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%
  }

  .medium-offset-9 {
      margin-left: 75%
  }

  .medium-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66666667%;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%
  }

  .medium-offset-10 {
      margin-left: 83.33333333%
  }

  .medium-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }

  .medium-offset-11 {
      margin-left: 91.66666667%
  }

  .medium-order-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1
  }

  .medium-up-1 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .medium-up-1 > .column, .medium-up-1 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }

  .medium-order-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
  }

  .medium-up-2 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .medium-up-2 > .column, .medium-up-2 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
  }

  .medium-order-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3
  }

  .medium-up-3 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .medium-up-3 > .column, .medium-up-3 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%
  }

  .medium-order-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4
  }

  .medium-up-4 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .medium-up-4 > .column, .medium-up-4 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
  }

  .medium-order-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5
  }

  .medium-up-5 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .medium-up-5 > .column, .medium-up-5 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%
  }

  .medium-order-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6
  }

  .medium-up-6 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .medium-up-6 > .column, .medium-up-6 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%
  }

  .medium-up-1 > .column, .medium-up-1 > .columns {
      width: 100%;
      float: left
  }

  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none
  }

  .medium-up-1 > .column:nth-of-type(1n + 1), .medium-up-1 > .columns:nth-of-type(1n + 1) {
      clear: both
  }

  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left
  }

  .medium-up-2 > .column, .medium-up-2 > .columns {
      width: 50%;
      float: left
  }

  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none
  }

  .medium-up-2 > .column:nth-of-type(odd), .medium-up-2 > .columns:nth-of-type(odd) {
      clear: both
  }

  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left
  }

  .medium-up-3 > .column, .medium-up-3 > .columns {
      width: 33.33333333%;
      float: left
  }

  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none
  }

  .medium-up-3 > .column:nth-of-type(3n + 1), .medium-up-3 > .columns:nth-of-type(3n + 1) {
      clear: both
  }

  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left
  }

  .medium-up-4 > .column, .medium-up-4 > .columns {
      width: 25%;
      float: left
  }

  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none
  }

  .medium-up-4 > .column:nth-of-type(4n + 1), .medium-up-4 > .columns:nth-of-type(4n + 1) {
      clear: both
  }

  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left
  }

  .medium-up-5 > .column, .medium-up-5 > .columns {
      width: 20%;
      float: left
  }

  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none
  }

  .medium-up-5 > .column:nth-of-type(5n + 1), .medium-up-5 > .columns:nth-of-type(5n + 1) {
      clear: both
  }

  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left
  }

  .medium-up-6 > .column, .medium-up-6 > .columns {
      width: 16.66666667%;
      float: left
  }

  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none
  }

  .medium-up-6 > .column:nth-of-type(6n + 1), .medium-up-6 > .columns:nth-of-type(6n + 1) {
      clear: both
  }

  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left
  }

  .medium-up-7 > .column, .medium-up-7 > .columns {
      width: 14.28571429%;
      float: left
  }

  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none
  }

  .medium-up-7 > .column:nth-of-type(7n + 1), .medium-up-7 > .columns:nth-of-type(7n + 1) {
      clear: both
  }

  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left
  }

  .medium-up-8 > .column, .medium-up-8 > .columns {
      width: 12.5%;
      float: left
  }

  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none
  }

  .medium-up-8 > .column:nth-of-type(8n + 1), .medium-up-8 > .columns:nth-of-type(8n + 1) {
      clear: both
  }

  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left
  }
}

@media screen and (min-width:40em) and (min-width:40em) {
  .medium-expand {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0
  }
}

.row.medium-unstack .column, .row.medium-unstack .columns {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%
}

@media screen and (min-width:40em) {
  .row.medium-unstack .column, .row.medium-unstack .columns {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0
  }
}

@media screen and (min-width:40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
      padding-left: 0;
      padding-right: 0
  }

  .medium-uncollapse > .column, .medium-uncollapse > .columns {
      padding-left: .9375rem;
      padding-right: .9375rem
  }
}

@media screen and (min-width:68.75em) {
  .large-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333333%;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%
  }

  .large-offset-0 {
      margin-left: 0
  }

  .large-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%
  }

  .large-offset-1 {
      margin-left: 8.33333333%
  }

  .large-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
  }

  .large-offset-2 {
      margin-left: 16.66666667%
  }

  .large-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%
  }

  .large-offset-3 {
      margin-left: 25%
  }

  .large-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66666667%;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%
  }

  .large-offset-4 {
      margin-left: 33.33333333%
  }

  .large-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
  }

  .large-offset-5 {
      margin-left: 41.66666667%
  }

  .large-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333333%;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%
  }

  .large-offset-6 {
      margin-left: 50%
  }

  .large-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66666667%;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%
  }

  .large-offset-7 {
      margin-left: 58.33333333%
  }

  .large-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
  }

  .large-offset-8 {
      margin-left: 66.66666667%
  }

  .large-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333333%;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%
  }

  .large-offset-9 {
      margin-left: 75%
  }

  .large-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66666667%;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%
  }

  .large-offset-10 {
      margin-left: 83.33333333%
  }

  .large-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }

  .large-offset-11 {
      margin-left: 91.66666667%
  }

  .large-order-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1
  }

  .large-up-1 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .large-up-1 > .column, .large-up-1 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }

  .large-order-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
  }

  .large-up-2 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .large-up-2 > .column, .large-up-2 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
  }

  .large-order-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3
  }

  .large-up-3 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .large-up-3 > .column, .large-up-3 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%
  }

  .large-order-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4
  }

  .large-up-4 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .large-up-4 > .column, .large-up-4 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
  }

  .large-order-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5
  }

  .large-up-5 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .large-up-5 > .column, .large-up-5 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%
  }

  .large-order-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6
  }

  .large-up-6 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .large-up-6 > .column, .large-up-6 > .columns {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%
  }

  .large-up-1 > .column, .large-up-1 > .columns {
      width: 100%;
      float: left
  }

  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none
  }

  .large-up-1 > .column:nth-of-type(1n + 1), .large-up-1 > .columns:nth-of-type(1n + 1) {
      clear: both
  }

  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left
  }

  .large-up-2 > .column, .large-up-2 > .columns {
      width: 50%;
      float: left
  }

  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none
  }

  .large-up-2 > .column:nth-of-type(odd), .large-up-2 > .columns:nth-of-type(odd) {
      clear: both
  }

  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left
  }

  .large-up-3 > .column, .large-up-3 > .columns {
      width: 33.33333333%;
      float: left
  }

  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none
  }

  .large-up-3 > .column:nth-of-type(3n + 1), .large-up-3 > .columns:nth-of-type(3n + 1) {
      clear: both
  }

  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left
  }

  .large-up-4 > .column, .large-up-4 > .columns {
      width: 25%;
      float: left
  }

  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none
  }

  .large-up-4 > .column:nth-of-type(4n + 1), .large-up-4 > .columns:nth-of-type(4n + 1) {
      clear: both
  }

  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left
  }

  .large-up-5 > .column, .large-up-5 > .columns {
      width: 20%;
      float: left
  }

  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none
  }

  .large-up-5 > .column:nth-of-type(5n + 1), .large-up-5 > .columns:nth-of-type(5n + 1) {
      clear: both
  }

  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left
  }

  .large-up-6 > .column, .large-up-6 > .columns {
      width: 16.66666667%;
      float: left
  }

  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none
  }

  .large-up-6 > .column:nth-of-type(6n + 1), .large-up-6 > .columns:nth-of-type(6n + 1) {
      clear: both
  }

  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left
  }

  .large-up-7 > .column, .large-up-7 > .columns {
      width: 14.28571429%;
      float: left
  }

  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none
  }

  .large-up-7 > .column:nth-of-type(7n + 1), .large-up-7 > .columns:nth-of-type(7n + 1) {
      clear: both
  }

  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left
  }

  .large-up-8 > .column, .large-up-8 > .columns {
      width: 12.5%;
      float: left
  }

  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none
  }

  .large-up-8 > .column:nth-of-type(8n + 1), .large-up-8 > .columns:nth-of-type(8n + 1) {
      clear: both
  }

  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left
  }
}

@media screen and (min-width:68.75em) and (min-width:68.75em) {
  .large-expand {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0
  }
}

.row.large-unstack .column, .row.large-unstack .columns {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%
}

@media screen and (min-width:68.75em) {
  .row.large-unstack .column, .row.large-unstack .columns {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0
  }
}

@media screen and (min-width:68.75em) {
  .large-collapse > .column, .large-collapse > .columns {
      padding-left: 0;
      padding-right: 0
  }

  .large-uncollapse > .column, .large-uncollapse > .columns {
      padding-left: .9375rem;
      padding-right: .9375rem
  }
}

.shrink {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%
}

.align-top.columns, .column.align-top {
  -ms-flex-item-align: start;
  align-self: flex-start
}

.align-bottom.columns, .column.align-bottom {
  -ms-flex-item-align: end;
  align-self: flex-end
}

.align-middle.columns, .column.align-middle {
  -ms-flex-item-align: center;
  align-self: center
}

.align-stretch.columns, .column.align-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch
}

html {
  color: #8e929e;
  font-family: MarkPro, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.55;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

body {
  font-size: inherit;
  overflow-x: hidden
}

a {
  color: #8e929e;
  text-decoration: none
}

a:focus, a:hover {
  color: #5b5f6b
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
  color: #2a2a2a
}

a, button {
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out
}

a:focus, a:hover, button:focus, button:hover {
  outline: none
}

.accordion, .accordion__details, .blog__posts-list, .business-type__container,
.business-type__list, .cards__list, .careers__department-list, .checklist__list,
.comparison__control-list, .comparison__item-list, .comparison__list,
.devices__icons, .devices__list, .document-list__list, .erp-banner__list,
.fancy-list__list, .features-banner__list, .features-diagram__list,
.features-grid__list, .hardware__list, .hero__tag-list, .how-it-works__list,
.how__list, .icon-list__list, .integrate-buttons__list, .integration-list__container,
.leadership__team, .list-banner__list, .map__card-list, .more-features__list,
.news-header__tags-list, .option-list, .pagination-buttons, .partner-grid__list,
.partner-logos__list, .post__connect-list, .post__share-list, .pricing__box-list,
.pricing__features-list, .pricing__partners-list, .slider__content-list,
.slider__icons-list, .support__list, .tabs__tab-list, .tags__list,
.team__row, .timeline__list, .videos__list {
  list-style-type: none;
  padding-left: 0
}

.card__meta, .careers__department-link, .comparison__item-smallprint,
.partner-logos__title, .post__connect-title, .post__share-title {
  font-size: 12px
}

.accordion__content li, .accordion__content p, .accordion__detail-description,
.accordion__detail-title, .accordion__title, .advanced-media__title,
.bolt-loop__title, .cards__title, .careers__description, .checklist__item,
.comparison__description, .comparison__title, .develop-banner__description,
.devices__description, .devices__item p, .erp-banner__description,
.features-banner__byline, .features-banner__item-byline, .features-banner__item-title,
.features-block__description, .features-block__item p, .features-grid__item-description,
.form--block .form__input, .form--block .form__textarea, .form__checkbox-label,
.form__description, .hero__description, .how__item-description,
.icon-list__content, .leadership-lightbox__content p, .list-banner__byline,
.list-banner__item, .malware__description, .map__card-key, .map__card-val,
.media__description, .more-features__item-byline, .more-features__item-title,
.more-features__title, .news-header__back, .news-list__date, .news-list__tag,
.partner-grid__button, .partner-grid__description, .partner-grid__item,
.partner__description img, .partner__description ol, .partner__description p,
.partner__description ul, .post__author-about p, .post__author-title,
.post__back, .post__date, .post__details-date, .post__member-name,
.post__read-time, .post__tag, .selectables__label, .slider__content-description,
.split-banner__description, .support__call-description, .support__call-number,
.support__description, .tabs__tab, .team__title, .testimonial__description,
.testimonial__sub-title, .title__description, .title__upper, .videos__description,
.videos__link {
  font-size: 14px
}

.advanced-media__text h1, .advanced-media__text h2, .advanced-media__text h3,
.advanced-media__text h4, .advanced-media__text h5, .advanced-media__text h6,
.advanced-media__text p, .document-list__description, .integrate-buttons__title,
.integration-list__title, .media--integration .media__content h1,
.media--integration .media__content h2, .media--integration .media__content h3,
.media--integration .media__content h4, .media--integration .media__content h5,
.media--integration .media__content h6, .media--integration .media__content p,
.media--investor .media__content p, .testimonial--small .testimonial__description,
.video-row__name {
  font-size: 15px
}

.hero--company .hero__description, .leadership-lightbox__position {
  font-size: 16px
}

.accordion__content h1, .accordion__content h2, .accordion__content h3,
.accordion__content h4, .accordion__content h5, .accordion__content h6,
.cards__sub-title, .careers__department-title, .devices__item-title,
.document-list__title, .features-grid__item-title, .form--block .form__title,
.hero--company .hero__description span, .icon-list__button-title,
.leadership-lightbox__name, .leadership__name, .lightbox__content ol,
.lightbox__content p, .lightbox__content ul, .malware__sub-title,
.news-list__title-link, .newsletter__description, .partner__description h1,
.partner__description h2, .partner__description h3, .partner__description h4,
.partner__description h5, .partner__description h6, .post__content ol,
.post__content p, .post__content ul, .split-banner__description, .subscribe__title,
.tags__title, .timeline__item {
  font-size: 18px
}

.advanced-media__sub-title, .bolt-loop__sub-title, .cardsecure-banner__title,
.careers__title, .comparison__sub-title, .develop-banner__title,
.devices__title, .document-list__header-title, .erp-banner__title,
.features-banner__title, .features-block__title, .features-diagram__title,
.form__title, .hero--ignite .hero__title, .hero--investor .hero__title,
.how__title, .list-banner__title, .malware__title, .map__title, .media__title,
.more-features__sub-title, .newsletter__title, .partner-grid__title,
.partner__title, .ready__title, .related-posts__title, .slider__title,
.support__title, .title__main, .video-row__title, .videos__title {
  font-size: 32px
}

.hero__title, .how-it-works__title, .leadership__title, .news-header__title {
  font-size: 40px
}

@media screen and (min-width:40em) {
  .hero__title, .how-it-works__title, .leadership__title, .news-header__title {
      font-size: 52px
  }
}

.accordion__details-button, .advanced-media__button, .bolt-loop__button,
.develop-banner__button, .event__button, .fm__button, .form-card__button,
.form__button, .hero__button, .hero__link, .icon-list__button, .newsletter__button,
.option-list__button--primary, .option-list__button--secondary,
.pagination-buttons__link, .partner-grid__button, .post--blog .post__back,
.pricing__button, .ready__button, .rn-form__button, .split-banner--company .split-banner__button,
.split-banner--integrate .split-banner__button, .subscribe__button,
.timeline__button {
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  font-weight: 700;
  line-height: 1.3;
  padding: 12px 35px;
  text-align: center;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  white-space: nowrap
}

.accordion__details-button:focus, .accordion__details-button:hover,
.advanced-media__button:focus, .advanced-media__button:hover, .bolt-loop__button:focus,
.bolt-loop__button:hover, .develop-banner__button:focus, .develop-banner__button:hover,
.event__button:focus, .event__button:hover, .fm__button:focus, .fm__button:hover,
.form-card__button:focus, .form-card__button:hover, .form__button:focus,
.form__button:hover, .hero__button:focus, .hero__button:hover, .hero__link:focus,
.hero__link:hover, .icon-list__button:focus, .icon-list__button:hover,
.newsletter__button:focus, .newsletter__button:hover, .option-list__button--primary:focus,
.option-list__button--primary:hover, .option-list__button--secondary:focus,
.option-list__button--secondary:hover, .pagination-buttons__link:focus,
.pagination-buttons__link:hover, .partner-grid__button:focus, .partner-grid__button:hover,
.post--blog .post__back:focus, .post--blog .post__back:hover, .pricing__button:focus,
.pricing__button:hover, .ready__button:focus, .ready__button:hover,
.rn-form__button:focus, .rn-form__button:hover, .split-banner--company .split-banner__button:focus,
.split-banner--company .split-banner__button:hover, .split-banner--integrate .split-banner__button:focus,
.split-banner--integrate .split-banner__button:hover, .subscribe__button:focus,
.subscribe__button:hover, .timeline__button:focus, .timeline__button:hover {
  text-decoration: none
}

.fm__button, .hero__link, .partner-grid__button, .post--blog .post__back,
.pricing__button, .split-banner--company .split-banner__button {
  border: 1px solid #dfdfdf
}

.fm__button:focus, .fm__button:hover, .hero__link:focus, .hero__link:hover,
.partner-grid__button:focus, .partner-grid__button:hover, .post--blog .post__back:focus,
.post--blog .post__back:hover, .pricing__button:focus, .pricing__button:hover,
.split-banner--company .split-banner__button:focus, .split-banner--company .split-banner__button:hover {
  border-color: #acacac;
  color: #5b5f6b
}

.accordion__details-button, .advanced-media__button, .bolt-loop__button,
.event__button, .fm__button, .form-card__button, .form__button, .hero__button,
.icon-list__button, .newsletter__button, .option-list__button--primary,
.pricing__button, .ready__button, .rn-form__button, .subscribe__button,
.timeline__button {
  background-color: #e06116;
  border: none;
  color: #fff
}

.accordion__details-button:focus, .accordion__details-button:hover,
.advanced-media__button:focus, .advanced-media__button:hover, .bolt-loop__button:focus,
.bolt-loop__button:hover, .event__button:focus, .event__button:hover,
.fm__button:focus, .fm__button:hover, .form-card__button:focus,
.form-card__button:hover, .form__button:focus, .form__button:hover,
.hero__button:focus, .hero__button:hover, .icon-list__button:focus,
.icon-list__button:hover, .newsletter__button:focus, .newsletter__button:hover,
.option-list__button--primary:focus, .option-list__button--primary:hover,
.pricing__button:focus, .pricing__button:hover, .ready__button:focus,
.ready__button:hover, .rn-form__button:focus, .rn-form__button:hover,
.subscribe__button:focus, .subscribe__button:hover, .timeline__button:focus,
.timeline__button:hover {
  background-color: #ef5d07;
  color: #fff
}

.option-list__button--secondary, .pagination-buttons__link {
  background-color: #0a4a6e;
  border: none;
  color: #fff
}

.option-list__button--secondary:focus, .option-list__button--secondary:hover,
.pagination-buttons__link:focus, .pagination-buttons__link:hover {
  background-color: #005d92;
  color: #fff
}

.develop-banner__button, .split-banner--integrate .split-banner__button {
  background-color: #fff;
  border: none;
  color: #0a4a6e
}

.develop-banner__button:focus, .develop-banner__button:hover,
.split-banner--integrate .split-banner__button:focus, .split-banner--integrate .split-banner__button:hover {
  color: #005d92
}

.hero__button, .hero__link, .pricing__button {
  padding: 17px 45px
}

.comparison__section-container:after, .devices__icons:after {
  content: "";
  display: table;
  clear: both
}

.bolt-loop__sub-title, .develop-banner__title, .erp-banner__title,
.features-banner__title, .list-banner__title, .more-features__sub-title {
  color: #fff;
  font-weight: 700;
  line-height: 40px
}

.bolt-loop__sub-title svg, .develop-banner__title svg, .erp-banner__title svg,
.features-banner__title svg, .list-banner__title svg, .more-features__sub-title svg {
  width: 100%;
  display: block;
  margin-bottom: 20px
}

.develop-banner__description, .erp-banner__description, .features-banner__byline,
.list-banner__byline, .more-features__item-byline, .more-features__item-title,
.split-banner__description {
  margin-bottom: 0;
  margin-top: 20px;
  width: 100%
}

@media screen and (min-width:40em) {
  .develop-banner__description, .erp-banner__description, .features-banner__byline,
  .list-banner__byline, .more-features__item-byline, .more-features__item-title,
  .split-banner__description {
      font-size: 18px
  }
}

.develop-banner__button, .split-banner--integrate .split-banner__button {
  margin-top: 25px
}

@media screen and (min-width:40em) {
  .develop-banner__button, .split-banner--integrate .split-banner__button {
      margin-top: 45px
  }
}

@-webkit-keyframes investor-rotation {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

@keyframes investor-rotation {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

@-webkit-keyframes investor-opacity {
  0% {
      opacity: 1
  }

  to {
      opacity: .25
  }
}

@keyframes investor-opacity {
  0% {
      opacity: 1
  }

  to {
      opacity: .25
  }
}

.hero {
  background-color: #fbfcfd;
  background-image: linear-gradient(0deg, rgba(10, 74, 110, .1), rgba(251, 252, 253, 0) 64px);
  background-position: bottom, top;
  background-repeat: no-repeat;
  background-size: 100% 64px;
  position: relative;
  width: 100%
}

.hero__content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.header + .hero {
  padding-top: 85px
}

@media screen and (min-width:40em) {
  .header + .hero {
      padding-top: 96px
  }
}

.hero--home {
  background-image: none;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  overflow: hidden
}

.hero--home .hero__container {
  padding-bottom: 50px;
  padding-top: 340px !important;
  position: relative;
  z-index: 1
}

@media screen and (min-width:40em) {
  .hero--home .hero__container {
      padding-bottom: 65px;
      padding-top: 440px !important;
      padding-bottom: 80px;
      padding-top: 539.2px !important
  }
}

@media screen and (min-width:68.75em) {
  .hero--home .hero__container {
      max-width: 1070px;
      padding-bottom: 310px;
      padding-top: 191.2px !important;
      text-align: left
  }

  .hero--home .hero__container .hero__description {
      margin-left: 0;
      margin-right: 0
  }
}

.hero--home .hero__content {
  max-width: 735px
}

@media screen and (max-width:68.6875em) {
  .hero--home .hero__content {
      margin-left: auto;
      margin-right: auto
  }
}

.hero--home .hero__button {
  margin-top: 35px
}

.hero--home .hero__floor-left, .hero--home .hero__floor-right {
  bottom: 0;
  height: 0;
  position: absolute
}

@media screen and (max-width:39.9375em) {
  .hero--home .hero__floor-left, .hero--home .hero__floor-right {
      margin-bottom: -315px !important
  }
}

@media (min-width:640px) and (max-width:639px) {
  .hero--home .hero__floor-left, .hero--home .hero__floor-right {
      margin-bottom: -415px !important
  }
}

@media (min-width:640px) and (max-width:1099px) {
  .hero--home .hero__floor-left, .hero--home .hero__floor-right {
      margin-bottom: -514.2px !important
  }
}

@media screen and (max-width:68.6875em) {
  .hero--home .hero__floor-left, .hero--home .hero__floor-right {
      bottom: 100%
  }
}

.hero--home .hero__floor-left {
  padding-top: 20.31722054%;
  left: 0;
  width: 50%
}

@media screen and (max-width:39.9375em) {
  .hero--home .hero__floor-left {
      padding-top: 44.6978852%;
      width: 110%
  }
}

@media (min-width:640px) and (max-width:639px) {
  .hero--home .hero__floor-left {
      padding-top: 36.57099698%;
      width: 90%
  }
}

@media (min-width:640px) and (max-width:1099px) {
  .hero--home .hero__floor-left {
      padding-top: 28.44410876%;
      width: 70%
  }
}

.hero--home .hero__floor-right {
  padding-top: 30.02061856%;
  right: 0;
  width: 65%
}

@media screen and (max-width:39.9375em) {
  .hero--home .hero__floor-right {
      padding-top: 46.18556701%;
      width: 100%
  }
}

@media (min-width:640px) and (max-width:639px) {
  .hero--home .hero__floor-right {
      padding-top: 39.25773196%;
      width: 85%
  }
}

@media (min-width:640px) and (max-width:1099px) {
  .hero--home .hero__floor-right {
      padding-top: 34.63917526%;
      width: 75%
  }
}

.hero--home .svg--delivery, .hero--home .svg--painter, .hero--home .svg--shop,
.hero--home .svg--shopper, .hero--home .svg--waiter {
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out
}

.hero--home.hero--hide-svgs .svg--delivery, .hero--home.hero--hide-svgs .svg--painter,
.hero--home.hero--hide-svgs .svg--shop, .hero--home.hero--hide-svgs .svg--shopper,
.hero--home.hero--hide-svgs .svg--waiter {
  opacity: 0
}

.hero--home .svg--floor-left, .hero--home .svg--floor-right {
  bottom: 0;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  width: 100%
}

.hero--home .svg--shop {
  position: absolute
}

@media screen and (max-width:39.9375em) {
  .hero--home .svg--shop {
      top: 90px !important;
      height: 191.7201px;
      margin-left: -108.08985px;
      width: 216.8991px
  }
}

@media (min-width:640px) and (max-width:639px) {
  .hero--home .svg--shop {
      top: 95px !important;
      height: 278.8656px;
      margin-left: -157.2216px;
      width: 315.4896px
  }
}

@media (min-width:640px) and (max-width:1099px) {
  .hero--home .svg--shop {
      top: 144.2px !important;
      height: 313.7238px;
      margin-left: -176.8743px;
      width: 354.9258px
  }
}

@media screen and (max-width:68.6875em) {
  .hero--home .svg--shop {
      left: 50%
  }
}

@media screen and (min-width:68.75em) {
  .hero--home .svg--shop {
      height: 348.582px;
      right: -20px;
      top: 190px;
      width: 393.054px
  }
}

@media screen and (min-width:84.375em) {
  .hero--home .svg--shop {
      right: -90px
  }
}

.hero--home .svg--shop svg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

.hero--home .svg--delivery {
  position: absolute
}

@media screen and (max-width:39.9375em) {
  .hero--home .svg--delivery {
      top: 233.95px !important;
      height: 58.85px;
      margin-left: 130.75px;
      width: 40.7px
  }
}

@media (min-width:640px) and (max-width:639px) {
  .hero--home .svg--delivery {
      top: 286.2px !important;
      height: 85.6px;
      margin-left: 192px;
      width: 59.2px
  }
}

@media (min-width:640px) and (max-width:1099px) {
  .hero--home .svg--delivery {
      top: 374.3px !important;
      height: 96.3px;
      margin-left: 208.5px;
      width: 66.6px
  }
}

@media screen and (max-width:68.6875em) {
  .hero--home .svg--delivery {
      left: 50%
  }
}

@media screen and (min-width:68.75em) {
  .hero--home .svg--delivery {
      height: 107px;
      right: -60px;
      top: 520px;
      width: 63px
  }
}

@media screen and (min-width:84.375em) {
  .hero--home .svg--delivery {
      right: -150px
  }
}

.hero--home .svg--delivery svg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(1.25);
  transform: scale(1.25)
}

.hero--home .svg--shopper {
  position: absolute
}

@media screen and (max-width:39.9375em) {
  .hero--home .svg--shopper {
      top: 208.45px !important;
      height: 64.35px;
      margin-right: 109.15px;
      width: 47.85px
  }
}

@media (min-width:640px) and (max-width:639px) {
  .hero--home .svg--shopper {
      top: 268.2px !important;
      height: 93.6px;
      margin-right: 162.4px;
      width: 69.6px
  }
}

@media (min-width:640px) and (max-width:1099px) {
  .hero--home .svg--shopper {
      top: 345.3px !important;
      height: 105.3px;
      margin-right: 177.7px;
      width: 78.3px
  }
}

@media screen and (max-width:68.6875em) {
  .hero--home .svg--shopper {
      right: 50%
  }
}

@media screen and (min-width:68.75em) {
  .hero--home .svg--shopper {
      height: 117px;
      right: 380px;
      top: 510px;
      width: 87px
  }
}

@media screen and (min-width:84.375em) {
  .hero--home .svg--shopper {
      right: 290px
  }
}

.hero--home .svg--shopper svg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

.hero--home .svg--waiter {
  position: absolute
}

@media screen and (max-width:68.6875em) {
  .hero--home .svg--waiter {
      display: none
  }
}

@media screen and (min-width:68.75em) {
  .hero--home .svg--waiter {
      height: 101px;
      left: -40px;
      top: 500px;
      width: 45px
  }
}

@media screen and (min-width:84.375em) {
  .hero--home .svg--waiter {
      left: -90px;
      top: 490px
  }
}

.hero--home .svg--waiter svg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(1.5);
  transform: scale(1.5)
}

.hero--home .svg--painter {
  position: absolute
}

@media screen and (max-width:68.6875em) {
  .hero--home .svg--painter {
      display: none
  }
}

@media screen and (min-width:68.75em) {
  .hero--home .svg--painter {
      height: 95px;
      left: 150px;
      top: 580px;
      width: 84px
  }
}

@media screen and (min-width:84.375em) {
  .hero--home .svg--painter {
      left: 100px
  }
}

.hero--home .svg--painter svg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(1.15);
  transform: scale(1.15)
}

.hero--plain {
  background-image: none;
  padding-bottom: 30px;
  padding-top: 105px !important
}

@media screen and (min-width:40em) {
  .hero--plain {
      padding-top: 156.2px !important
  }
}

.hero--plain .hero__description {
  color: #2a2a2a
}

.hero--integration {
  padding-top: 190px !important
}

@media screen and (min-width:40em) {
  .hero--integration {
      padding-top: 184.2px !important
  }
}

.hero--cardpointe, .hero--p2pe {
  padding-top: 165px !important
}

@media screen and (min-width:40em) {
  .hero--cardpointe, .hero--p2pe {
      padding-top: 164.2px !important
  }
}

.hero--copilot {
  padding-bottom: 96px;
  padding-top: 104px !important
}

@media screen and (min-width:40em) {
  .hero--copilot {
      padding-top: 133.2px !important
  }
}

.hero--cardsecure {
  padding-bottom: 350px;
  padding-top: 150px !important
}

@media screen and (min-width:40em) {
  .hero--cardsecure {
      padding-top: 204.2px !important
  }
}

@media screen and (min-width:68.75em) {
  .hero--cardsecure {
      padding-bottom: 190px
  }
}

.hero--cardpointe {
  padding-bottom: 120px
}

@media screen and (min-width:68.75em) {
  .hero--cardpointe {
      padding-bottom: 130px
  }
}

.hero--p2pe {
  padding-bottom: 225px
}

.hero--integrate {
  padding-bottom: 250px;
  padding-top: 115px !important
}

@media screen and (min-width:68.75em) {
  .hero--integrate {
      padding-top: 244.2px !important;
      padding-bottom: 200px
  }
}

.hero--integration {
  padding-bottom: 220px
}

@media screen and (min-width:68.75em) {
  .hero--integration {
      padding-bottom: 200px
  }
}

.hero--integration .hero__description {
  max-width: 840px
}

.hero--integration .hero__img {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0a4a6e;
  bottom: -315px;
  border-radius: 95px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 190px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 50%;
  margin-left: -95px;
  width: 190px
}

@media screen and (min-width:68.75em) {
  .hero--integration .hero__img {
      bottom: -295px
  }
}

.hero--career {
  background-image: linear-gradient(0deg, rgba(10, 74, 110, .1), rgba(251, 252, 253, 0) 64px), url("../img/hero/careers.jpg");
  background-position: bottom, top left 65%;
  background-size: 100% 64px, cover;
  padding-top: 195px !important;
  padding-bottom: 305px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .hero--career {
      background-image: linear-gradient(0deg, rgba(10, 74, 110, .1), rgba(251, 252, 253, 0) 64px), url("../img/hero/careers@2x.jpg")
  }
}

@media screen and (min-width:40em) {
  .hero--career {
      padding-top: 229.2px !important;
      padding-bottom: 225px
  }
}

.hero--company {
  padding-top: 135px !important;
  padding-bottom: 275px
}

@media screen and (min-width:40em) {
  .hero--company {
      padding-top: 164.2px !important;
      padding-bottom: 210px
  }
}

.hero--company .hero__description {
  max-width: 840px
}

.hero--company .hero__description span {
  color: #e06116;
  display: inline-block;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 5px
}

.hero--post {
  background-color: #4060ba;
  background-image: linear-gradient(65deg, #644a9c, #4060ba 45%, #2471d0);
  background-size: 100% 100%;
  padding-top: 195px !important;
  padding-bottom: 180px;
  position: relative
}

@media screen and (min-width:40em) {
  .hero--post {
      padding-top: 244.2px !important;
      padding-bottom: 215px
  }
}

.hero--post .hero__title {
  color: #fff;
  width: 100%
}

.hero--post .hero__img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: 50%
}

.hero--blog-post {
  padding-top: 300px !important;
  padding-bottom: 0
}

@media screen and (min-width:40em) {
  .hero--blog-post {
      padding-top: 570px !important;
      padding-bottom: 0
  }
}

.hero--blog {
  background-color: #eceff4;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#eceff4), to(#fff));
  background-image: linear-gradient(0deg, #eceff4, #fff);
  background-size: 100%;
  padding-top: 89px !important;
  padding-bottom: 149px
}

@media screen and (min-width:40em) {
  .hero--blog {
      background-image: -webkit-gradient(linear, left bottom, left top, from(#eceff4), to(#fff));
      background-image: linear-gradient(0deg, #eceff4, #fff);
      padding-top: 229.2px !important;
      padding-bottom: 226px
  }
}

.hero--blog .hero__title {
  color: #8e929e
}

.hero--blog .hero__title strong {
  color: #e06116;
  text-decoration: underline
}

.hero--bolt {
  padding-bottom: 60px;
  padding-top: 85px !important
}

@media screen and (min-width:40em) {
  .hero--bolt {
      padding-top: 159.2px !important
  }
}

.hero--bolt .hero__max-width {
  margin-left: auto;
  margin-right: auto;
  max-width: 633px
}

.hero--bolt .hero__aspect-ratio {
  margin-bottom: 25px;
  padding-bottom: 43.286%
}

@media screen and (min-width:40em) {
  .hero--bolt .hero__aspect-ratio {
      margin-bottom: 55px
  }
}

.header + .hero--partner .hero__img-container {
  top: 63px
}

@media screen and (min-width:40em) {
  .header + .hero--partner .hero__img-container {
      top: 77px
  }
}

.hero--partner {
  background: #fff;
  position: relative
}

.hero--partner .hero__img-container {
  bottom: 20px;
  height: auto;
  left: 0;
  margin: 0;
  max-width: 100%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0
}

@media screen and (min-width:56.3125em) {
  .hero--partner .hero__img-container {
      bottom: 0
  }
}

.hero--partner .hero__img {
  background-position: 50%;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%
}

.hero--partner .hero__content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 80px;
  padding-top: 80px !important
}

@media screen and (min-width:56.3125em) {
  .hero--partner .hero__content {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      min-height: 560px;
      text-align: left;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%
  }
}

.hero--partner .hero__form {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 420px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto
}

@media screen and (min-width:56.3125em) {
  .hero--partner .hero__form {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.6667%;
      flex: 0 0 41.6667%
  }
}

.hero--partner .hero__description, .hero--partner .hero__title {
  color: #fff;
  width: 100%
}

.hero--partner .hero__container .hero__description {
  margin-left: 0;
  margin-right: 0
}

.hero--company-partner {
  padding-bottom: 220px;
  padding-top: 115px !important
}

@media screen and (min-width:68.75em) {
  .hero--company-partner {
      padding-top: 204.2px !important;
      padding-bottom: 210px
  }
}

.hero--company-partner .hero__img {
  background-image: url("../img/hero/partner-small.png");
  background-size: 100% 100%;
  bottom: -40px;
  height: 216px;
  margin-right: -180px;
  right: 50%;
  width: 360px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .hero--company-partner .hero__img {
      background-image: url("../img/hero/partner-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .hero--company-partner .hero__img {
      background-image: url("../img/hero/partner.png");
      bottom: 10px;
      height: 462px;
      margin-right: -690px;
      width: 770px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .hero--company-partner .hero__img {
      background-image: url("../img/hero/partner@2x.png")
  }
}

.hero--error {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 75px;
  padding-top: 140px !important
}

@media screen and (min-width:40em) {
  .hero--error {
      padding-top: 169.2px !important
  }
}

.hero--error .hero__title svg {
  padding-bottom: 20px
}

.hero--error .hero__description {
  max-width: 840px
}

.hero--error .hero__button {
  margin-top: 45px
}

.hero--login {
  background: #fbfcfd;
  padding-bottom: 100px;
  padding-top: 145px !important
}

@media screen and (min-width:68.75em) {
  .hero--login {
      padding-bottom: 100px;
      padding-top: 174.2px !important
  }
}

.hero--enterprise {
  background-image: url("../img/hero/signup.jpg");
  background-position: 50%;
  background-size: cover;
  padding-bottom: 70px;
  padding-top: 135px !important
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .hero--enterprise {
      background-image: url("../img/hero/signup@2x.jpg")
  }
}

@media screen and (min-width:68.75em) {
  .hero--enterprise {
      padding-bottom: 70px;
      padding-top: 164.2px !important
  }
}

.hero--enterprise .hero__description, .hero--enterprise .hero__title {
  color: #fff
}

.hero--black .hero__title span {
  color: #2a2a2a
}

.hero--purple .hero__title span {
  color: #896ec5
}

.hero--green .hero__title span {
  color: #44ba91
}

.hero--blue .hero__title span {
  color: #0a4a6e
}

.hero__container {
  position: relative;
  text-align: center
}

.hero__container .hero__description {
  margin-left: auto;
  margin-right: auto
}

@media screen and (min-width:68.75em) {
  .hero__container--left {
      text-align: left
  }

  .hero__container--left .hero__description {
      margin-left: 0;
      margin-right: 0
  }
}

.hero__title {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: lighter;
  line-height: 1.15
}

.hero__title svg {
  display: block;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto
}

.hero__title .cardpointe-color {
  width: 285px
}

@media screen and (min-width:40em) {
  .hero__title .cardpointe-color {
      width: auto
  }
}

.hero__title .cardsecure-color {
  height: 28px;
  width: 186px
}

@media screen and (min-width:40em) {
  .hero__title .cardsecure-color {
      height: 38px;
      width: 253px
  }
}

@media screen and (min-width:68.75em) {
  .hero__title .cardsecure-color {
      margin-left: 0
  }
}

.hero__title .cardsecure-p2pe {
  width: 228px
}

@media screen and (min-width:40em) {
  .hero__title .cardsecure-p2pe {
      width: auto
  }
}

.hero__title span {
  color: #e06116
}

.hero__description {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 30px;
  max-width: 650px
}

@media screen and (min-width:40em) {
  .hero__description {
      font-size: 18px
  }
}

.hero__description a {
  color: #0a4a6e;
  display: inline-block;
  font-weight: 700
}

.hero__description a:focus, .hero__description a:hover {
  color: #005d92
}

.hero__description a:focus svg circle, .hero__description a:hover svg circle {
  fill: #005d92
}

.hero__description a svg {
  height: 18px;
  margin-left: 4px;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  width: 18px
}

.hero__buttons {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 35px
}

@media screen and (max-width:39.9375em) {
  .hero__buttons {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }
}

@media screen and (max-width:68.6875em) {
  .hero__buttons {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }
}

.hero__buttons--center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.hero__buttons .hero__button, .hero__buttons .hero__link {
  margin-top: 10px
}

@media screen and (min-width:40em) {
  .hero__buttons .hero__button:not(:first-child), .hero__buttons .hero__link:not(:first-child) {
      margin-left: 5px
  }
}

@media screen and (min-width:40em) {
  .hero__buttons .hero__button:not(:last-child), .hero__buttons .hero__link:not(:last-child) {
      margin-right: 5px
  }
}

.hero__button, .hero__link {
  margin-top: 45px;
  position: relative;
  display: inline-block
}

.hero__play-button {
  background: transparent;
  border: none;
  color: #e06116;
  font-weight: 700;
  margin-top: 45px;
  padding: 0
}

.hero__play-button:focus, .hero__play-button:hover {
  color: #ef5d07
}

.hero__play-button:focus svg path, .hero__play-button:hover svg path {
  stroke: #ef5d07
}

.hero__play-button:focus svg path + path, .hero__play-button:hover svg path + path {
  stroke: none;
  fill: #ef5d07
}

.hero__play-button svg {
  height: 64px;
  margin-top: 20px;
  width: 100%
}

.hero__play-button svg path {
  stroke: #e06116;
  stroke-width: 1px
}

.hero__play-button svg path + path {
  stroke: none;
  fill: #e06116
}

.hero__aspect-ratio {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%
}

.hero__aspect-ratio .svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.hero__img-container {
  height: 0;
  padding-bottom: 52%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  max-width: 665px;
  overflow: hidden;
  position: relative
}

@media screen and (min-width:53.125em) {
  .hero__img-container {
      margin-top: 30px
  }
}

@media screen and (min-width:28.125em) {
  .hero__img-container {
      padding-bottom: 54%
  }
}

@media screen and (min-width:33.75em) {
  .hero__img-container {
      padding-bottom: 56%
  }
}

@media screen and (min-width:41.5625em) {
  .hero__img-container {
      padding-bottom: 375px
  }
}

.hero__img {
  position: absolute;
  background-size: 100% 100%;
  display: block
}

.hero__svg-container {
  position: relative;
  width: 100%
}

.hero--integrate .hero__img {
  background-image: url("../img/hero/laptop1-small.png");
  height: 258px;
  left: 50%;
  margin-left: -188px;
  bottom: -293px;
  width: 375px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .hero--integrate .hero__img {
      background-image: url("../img/hero/laptop1-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .hero--integrate .hero__img {
      background-image: url("../img/hero/laptop1.png");
      height: 498px;
      margin-left: 0;
      left: 580px;
      top: -100px;
      width: 725px;
      opacity: 0;
      -webkit-transition: opacity .2s ease-in-out;
      transition: opacity .2s ease-in-out
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .hero--integrate .hero__img {
      background-image: url("../img/hero/laptop1@2x.png")
  }
}

.hero--integrate .hero__img:after, .hero--integrate .hero__img:before {
  background-size: 100% 100%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

@media screen and (min-width:68.75em) {
  .hero--integrate .hero__img:after, .hero--integrate .hero__img:before {
      opacity: 0
  }
}

.hero--integrate .hero__img:before {
  background-image: url("../img/hero/laptop2-small.png");
  -webkit-transition: all .3s ease-in-out .2s;
  transition: all .3s ease-in-out .2s
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .hero--integrate .hero__img:before {
      background-image: url("../img/hero/laptop2-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .hero--integrate .hero__img:before {
      background-image: url("../img/hero/laptop2.png");
      background-position: -50px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .hero--integrate .hero__img:before {
      background-image: url("../img/hero/laptop2@2x.png")
  }
}

.hero--integrate .hero__img:after {
  background-image: url("../img/hero/laptop3-small.png");
  -webkit-transition: all .3s ease-in-out .5s;
  transition: all .3s ease-in-out .5s
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .hero--integrate .hero__img:after {
      background-image: url("../img/hero/laptop3-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .hero--integrate .hero__img:after {
      background-image: url("../img/hero/laptop3.png");
      background-position: -50px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .hero--integrate .hero__img:after {
      background-image: url("../img/hero/laptop3@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .hero--integrate .hero__img--show {
      opacity: 1
  }
}

@media screen and (min-width:68.75em) {
  .hero--integrate .hero__img--show:after, .hero--integrate .hero__img--show:before {
      background-position: 50%;
      opacity: 1
  }
}

.hero--p2pe .hero__img {
  background-image: url("../img/hero/cardsecure.png");
  height: 289px;
  left: 50%;
  margin-left: -103px;
  position: absolute;
  bottom: -369px;
  width: 206px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .hero--p2pe .hero__img {
      background-image: url("../img/hero/cardsecure@2x.png")
  }
}

@media screen and (min-width:40em) {
  .hero--p2pe .hero__img {
      bottom: -329px
  }
}

.hero--retailnow {
  padding-bottom: 65px
}

.hero--retailnow .hero__img {
  background-position: 50%;
  background-size: cover;
  bottom: 260px;
  left: 0;
  right: 0;
  top: 0
}

.hero--retailnow .hero__logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 112px;
  position: relative
}

.hero--retailnow .hero__logos svg {
  display: block;
  height: 53.25px;
  width: 245.25px
}

.hero--retailnow .hero__logos path {
  fill: #fff
}

.hero--retailnow .hero__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.hero--retailnow .hero__description {
  color: #fff
}

.hero--ignite, .hero--investor {
  overflow: hidden;
  position: relative
}

.hero--ignite .hero__container, .hero--investor .hero__container {
  position: relative
}

.hero--ignite .hero__title, .hero--investor .hero__title {
  color: #fff
}

.hero--ignite .hero__title svg, .hero--investor .hero__title svg {
  margin-bottom: 0
}

.hero--ignite .svg--circles, .hero--investor .svg--circles {
  height: 1163px;
  left: 50%;
  margin-left: -581px;
  margin-top: -581px;
  overflow: visible;
  position: absolute;
  top: 50%;
  width: 1163px
}

.hero--ignite .svg--investor-count, .hero--investor .svg--investor-count {
  display: block;
  position: absolute
}

.hero--ignite .svg--investor-count:nth-child(2), .hero--investor .svg--investor-count:nth-child(2) {
  bottom: 50px;
  margin-right: 550px;
  right: 50%
}

.hero--ignite .svg--investor-count:nth-child(3), .hero--investor .svg--investor-count:nth-child(3) {
  left: 50%;
  margin-left: 800px;
  top: 150px
}

.hero--ignite .svg-circle-1, .hero--ignite .svg-circle-2, .hero--ignite .svg-circle-3,
.hero--investor .svg-circle-1, .hero--investor .svg-circle-2, .hero--investor .svg-circle-3 {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.hero--ignite .hero__circle, .hero--investor .hero__circle {
  border-radius: 50%;
  position: absolute
}

.hero--ignite .hero__circle:after, .hero--investor .hero__circle:after {
  border-radius: 50%;
  content: "";
  opacity: .85;
  position: absolute
}

.hero--ignite .hero__circle:first-of-type, .hero--ignite .hero__circle:nth-of-type(2),
.hero--ignite .hero__circle:nth-of-type(3), .hero--ignite .hero__circle:nth-of-type(4),
.hero--investor .hero__circle:first-of-type, .hero--investor .hero__circle:nth-of-type(2),
.hero--investor .hero__circle:nth-of-type(3), .hero--investor .hero__circle:nth-of-type(4) {
  height: 20px;
  width: 20px
}

.hero--ignite .hero__circle:first-of-type::after, .hero--ignite .hero__circle:nth-of-type(2):after,
.hero--ignite .hero__circle:nth-of-type(3):after, .hero--ignite .hero__circle:nth-of-type(4):after,
.hero--investor .hero__circle:first-of-type::after, .hero--investor .hero__circle:nth-of-type(2):after,
.hero--investor .hero__circle:nth-of-type(3):after, .hero--investor .hero__circle:nth-of-type(4):after {
  height: 14px;
  left: 3px;
  top: 3px;
  width: 14px
}

.hero--ignite .hero__circle:nth-of-type(5), .hero--ignite .hero__circle:nth-of-type(6),
.hero--ignite .hero__circle:nth-of-type(7), .hero--investor .hero__circle:nth-of-type(5),
.hero--investor .hero__circle:nth-of-type(6), .hero--investor .hero__circle:nth-of-type(7) {
  height: 14px;
  width: 14px
}

.hero--ignite .hero__circle:nth-of-type(5):after, .hero--ignite .hero__circle:nth-of-type(6):after,
.hero--ignite .hero__circle:nth-of-type(7):after, .hero--investor .hero__circle:nth-of-type(5):after,
.hero--investor .hero__circle:nth-of-type(6):after, .hero--investor .hero__circle:nth-of-type(7):after {
  height: 10px;
  left: 2px;
  top: 2px;
  width: 10px
}

.hero--ignite .hero__circle:nth-of-type(8), .hero--ignite .hero__circle:nth-of-type(9),
.hero--ignite .hero__circle:nth-of-type(10), .hero--ignite .hero__circle:nth-of-type(11),
.hero--ignite .hero__circle:nth-of-type(12), .hero--investor .hero__circle:nth-of-type(8),
.hero--investor .hero__circle:nth-of-type(9), .hero--investor .hero__circle:nth-of-type(10),
.hero--investor .hero__circle:nth-of-type(11), .hero--investor .hero__circle:nth-of-type(12) {
  background-color: hsla(0, 0%, 100%, .22);
  height: 4px;
  width: 4px
}

.hero--ignite .hero__circle:nth-of-type(8):after, .hero--ignite .hero__circle:nth-of-type(9):after,
.hero--ignite .hero__circle:nth-of-type(10):after, .hero--ignite .hero__circle:nth-of-type(11):after,
.hero--ignite .hero__circle:nth-of-type(12):after, .hero--investor .hero__circle:nth-of-type(8):after,
.hero--investor .hero__circle:nth-of-type(9):after, .hero--investor .hero__circle:nth-of-type(10):after,
.hero--investor .hero__circle:nth-of-type(11):after, .hero--investor .hero__circle:nth-of-type(12):after {
  display: none
}

.hero--ignite .hero__circle:first-of-type, .hero--investor .hero__circle:first-of-type {
  -webkit-animation-delay: .25s;
  animation-delay: .25s;
  left: 50%;
  margin-left: 600px;
  margin-top: 200px;
  top: 50%
}

.hero--ignite .hero__circle:nth-of-type(2), .hero--investor .hero__circle:nth-of-type(2) {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
  left: 50%;
  margin-left: 800px;
  margin-top: 300px;
  top: 50%
}

.hero--ignite .hero__circle:nth-of-type(3), .hero--investor .hero__circle:nth-of-type(3) {
  -webkit-animation-delay: .75s;
  animation-delay: .75s;
  bottom: 50%;
  margin-bottom: 150px;
  margin-right: 500px;
  right: 50%
}

.hero--ignite .hero__circle:nth-of-type(4), .hero--investor .hero__circle:nth-of-type(4) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  right: 50%;
  margin-right: 700px;
  margin-top: 50px;
  top: 50%
}

.hero--ignite .hero__circle:nth-of-type(5), .hero--investor .hero__circle:nth-of-type(5) {
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
  bottom: 50%;
  left: 50%;
  margin-bottom: 200px;
  margin-left: 200px
}

.hero--ignite .hero__circle:nth-of-type(6), .hero--investor .hero__circle:nth-of-type(6) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  bottom: 50%;
  left: 50%;
  margin-bottom: 70px;
  margin-left: 530px
}

.hero--ignite .hero__circle:nth-of-type(7), .hero--investor .hero__circle:nth-of-type(7) {
  -webkit-animation-delay: 1.75s;
  animation-delay: 1.75s;
  top: 50%;
  right: 50%;
  margin-top: 150px;
  margin-right: 300px
}

.hero--ignite .hero__circle:nth-of-type(8), .hero--investor .hero__circle:nth-of-type(8) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  bottom: 50%;
  left: 50%;
  margin-bottom: 250px;
  margin-left: 50px
}

.hero--ignite .hero__circle:nth-of-type(9), .hero--investor .hero__circle:nth-of-type(9) {
  -webkit-animation-delay: 2.25s;
  animation-delay: 2.25s;
  bottom: 50%;
  margin-bottom: 150px;
  margin-right: 200px;
  right: 50%
}

.hero--ignite .hero__circle:nth-of-type(10), .hero--investor .hero__circle:nth-of-type(10) {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  bottom: 50%;
  margin-bottom: 200px;
  margin-right: 750px;
  right: 50%
}

.hero--ignite .hero__circle:nth-of-type(11), .hero--investor .hero__circle:nth-of-type(11) {
  -webkit-animation-delay: 2.75s;
  animation-delay: 2.75s;
  bottom: 50%;
  left: 50%;
  margin-bottom: 250px;
  margin-left: 650px
}

.hero--ignite .hero__circle:nth-of-type(12), .hero--investor .hero__circle:nth-of-type(12) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  top: 50%;
  left: 50%;
  margin-top: 250px;
  margin-left: 150px
}

.hero--ignite .hero__button, .hero--investor .hero__button {
  max-width: 100%;
  white-space: normal
}

.hero--investor {
  background: #0a4a6e;
  padding-bottom: 200px;
  padding-top: 195px !important
}

@media screen and (min-width:40em) {
  .hero--investor {
      padding-bottom: 280px;
      padding-top: 294.2px !important
  }
}

.hero--investor .hero__title svg {
  display: block;
  height: 30px;
  margin-top: 30px;
  width: 164px
}

.hero--investor .hero__circle {
  background-color: #005374
}

.hero--investor .hero__circle:after {
  background-image: radial-gradient(#0aa0e3, #005273)
}

.hero--ignite {
  background: #181818;
  padding-bottom: 100px;
  padding-top: 95px !important
}

@media screen and (min-width:40em) {
  .hero--ignite {
      padding-bottom: 150px;
      padding-top: 164.2px !important
  }
}

.hero--ignite .hero__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.hero--ignite .hero__title {
  line-height: 46px;
  margin-top: 52px;
  max-width: 550px
}

.hero--ignite .hero__title svg {
  display: inline-block;
  height: 46px;
  width: 114px;
  vertical-align: middle
}

.hero--ignite .hero__preline {
  color: #fff;
  font-size: 22px;
  font-weight: 300
}

.hero--ignite .hero__byline {
  color: #fff;
  font-size: 17px;
  line-height: 32px;
  margin-top: 22px;
  max-width: 575px
}

.hero--ignite .hero__circle {
  background-color: #2f0782
}

.hero--ignite .hero__circle:after {
  background-image: radial-gradient(#650ae3, #2f0782)
}

.hero__tag-list {
  max-width: 720px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 55px;
  position: relative;
  margin: 0 auto;
  text-align: left
}

.hero__tag-list:before {
  content: "Tags";
  position: absolute;
  left: 0;
  top: 14px;
  font-weight: 700;
  color: #0a4a6e
}

.hero__tag-item {
  display: inline-block
}

.hero__tag-link {
  display: block;
  background: #fff;
  font-weight: 700;
  border-radius: 3px;
  padding: 2px 10px;
  margin-right: 10px;
  margin-top: 12px
}

.hero__integrate-buttons {
  top: 100%;
  left: 15px;
  margin-top: 80px;
  position: absolute;
  right: 15px
}

@media screen and (min-width:31.875em) {
  .hero__integrate-buttons {
      left: auto;
      right: 50%;
      margin-right: -240px
  }
}

@media screen and (min-width:68.75em) {
  .hero__integrate-buttons {
      top: -50px;
      right: 15px;
      margin-right: auto;
      margin-top: 0
  }
}

.partner-logos {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
  border-top: 1px solid #efefef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 45px
}

.partner-logos__title {
  color: #8e939e;
  font-weight: 600;
  margin-top: 30px;
  text-transform: uppercase
}

@media screen and (min-width:40em) {
  .partner-logos__title {
      margin-top: 35px
  }
}

.partner-logos__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 3px;
  max-width: 1240px;
  width: 100%
}

@media screen and (min-width:40em) {
  .partner-logos__list {
      margin-top: 10px
  }
}

.partner-logos__item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 88px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 10px 15px 0
}

@media screen and (max-width:39.9375em) {
  .partner-logos__item {
      max-width: 300px;
      width: 100%
  }
}

@media screen and (min-width:40em) {
  .partner-logos__item {
      height: 126px;
      margin-left: 5px;
      margin-right: 5px;
      width: 274px
  }
}

.partner-logos__img {
  width: 125px;
  height: 65px
}

@media screen and (min-width:40em) {
  .partner-logos__img {
      width: 140px;
      height: 80px
  }
}

.ready {
  background: #0a4a6e;
  overflow: hidden;
  position: relative
}

.ready--light {
  background: #fff;
  border-top: 1px solid #efefef;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='35' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 35'%3E%3Cpath fill='%23D3DADA' d='M0 0h10v3.34H0z'/%3E%3Cpath fill='%23F7F7F6' d='M0 35h10V7.6H0z'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 10px 36px;
  position: relative
}

.ready--light .ready__container {
  height: 301px
}

.ready--light .ready__title {
  color: #0a4a6e
}

.ready--light .svg {
  display: block;
  height: 301px;
  position: absolute;
  top: 0
}

.ready--light .svg--ready-light-left {
  left: -350px;
  width: 446px
}

@media screen and (min-width:40em) {
  .ready--light .svg--ready-light-left {
      left: -180px
  }
}

@media screen and (min-width:68.75em) {
  .ready--light .svg--ready-light-left {
      left: 0
  }
}

.ready--light .svg--ready-light-right {
  right: -350px;
  width: 448px
}

@media screen and (min-width:40em) {
  .ready--light .svg--ready-light-right {
      right: -180px
  }
}

@media screen and (min-width:68.75em) {
  .ready--light .svg--ready-light-right {
      right: 0
  }
}

.ready__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 265px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 15px;
  position: relative
}

.ready__title {
  color: #fff;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  width: 100%
}

.ready__button {
  margin-top: 20px
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1120px;
  position: relative
}

.svg-container--top {
  height: 825px;
  position: relative;
  width: 100%
}

@media screen and (min-width:40.6875em) {
  .svg-container--top {
      height: 1100px
  }
}

@media screen and (min-width:53.1875em) {
  .svg-container--top {
      height: 90%;
      left: 0;
      position: absolute;
      top: 0
  }
}

.svg-container--bottom {
  height: 435px;
  position: relative;
  width: 100%
}

@media screen and (min-width:53.1875em) {
  .svg-container--bottom {
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      top: -415px;
      height: auto
  }
}

.svg-container--integrations {
  margin-left: auto;
  margin-right: auto;
  max-width: 525px;
  width: 100%
}

@media screen and (min-width:53.1875em) {
  .svg-container--integrations {
      max-width: 100%;
      position: absolute;
      right: 40px;
      top: -40px;
      width: 48%
  }
}

@media screen and (min-width:68.5em) {
  .svg-container--integrations {
      top: -70px
  }
}

.svg-container--copilot-line, .svg-container--real-time {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  position: relative
}

.svg-container--team {
  bottom: -180px;
  display: block;
  position: absolute;
  top: -20px;
  width: 100%;
  text-align: center
}

.svg-container--blog {
  bottom: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.svg--bolt {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%
}

.svg--bolt .circle--purple {
  fill: #896ec5;
  stroke: rgba(137, 110, 197, .2)
}

.svg--bolt .circle--orange {
  fill: #faa041;
  stroke: rgba(250, 160, 65, .2)
}

.svg--bolt .circle--fade {
  opacity: .3
}

.svg--bolt-loop .circle--purple {
  fill: #896ec5;
  stroke: rgba(137, 110, 197, .2)
}

.svg--bolt-loop .circle--orange {
  fill: #faa041;
  stroke: rgba(250, 160, 65, .2)
}

.svg--bolt-loop .circle--fade {
  opacity: .3
}

.svg--bolt-loop .line {
  fill: none;
  stroke: #dadde4
}

.svg--how-it-works-1 .circle--purple, .svg--how-it-works-2 .circle--purple,
.svg--how-it-works-3 .circle--purple, .svg--how-it-works-4 .circle--purple,
.svg--how-it-works-5 .circle--purple {
  fill: #896ec5;
  stroke: rgba(137, 110, 197, .2)
}

.svg--how-it-works-1 .circle--orange, .svg--how-it-works-2 .circle--orange,
.svg--how-it-works-3 .circle--orange, .svg--how-it-works-4 .circle--orange,
.svg--how-it-works-5 .circle--orange {
  fill: #faa041;
  stroke: rgba(250, 160, 65, .2)
}

.svg--how-it-works-1 .circle--fade, .svg--how-it-works-2 .circle--fade,
.svg--how-it-works-3 .circle--fade, .svg--how-it-works-4 .circle--fade,
.svg--how-it-works-5 .circle--fade {
  opacity: .3
}

.svg--how-it-works-1 .line, .svg--how-it-works-2 .line, .svg--how-it-works-3 .line,
.svg--how-it-works-4 .line, .svg--how-it-works-5 .line {
  fill: none;
  stroke: #dadde4
}

.svg--hero {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%
}

.svg--hero .circle--left {
  fill: #82a3b7;
  stroke: rgba(130, 163, 183, .2)
}

.svg--hero .circle--right {
  fill: #fca16b;
  stroke: hsla(22, 96%, 70%, .2)
}

.svg--hero .shape {
  fill: none
}

.svg--hero .shape--left {
  stroke: #82a3b7
}

.svg--hero .shape--right {
  stroke: #fca16b
}

.ratio {
  height: 0;
  position: relative;
  width: 100%
}

.ratio--integrations {
  padding-bottom: 30%
}

.svg--ready {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%
}

.svg--ready .circle {
  fill: #1a74a8;
  stroke: rgba(26, 116, 168, .2)
}

.svg--ready .shape {
  fill: none;
  stroke: #1a74a8
}

.svg--ready .shape--barcode path:nth-child(2) {
  fill: #1a74a8;
  stroke: none
}

.svg--copilot-line, .svg--real-time {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%
}

.svg--copilot-line .line, .svg--real-time .line {
  fill: none;
  stroke: #b5bdca
}

.svg--copilot-line .line-cover, .svg--real-time .line-cover {
  fill: none;
  stroke: #fff
}

.svg--copilot-line .circle--grey, .svg--real-time .circle--grey {
  fill: #b5bdca;
  stroke: rgba(181, 189, 202, .2)
}

.svg--copilot-line .circle--red, .svg--real-time .circle--red {
  fill: #f47875;
  stroke: hsla(1, 85%, 71%, .2)
}

.svg--copilot-line .circle--orange, .svg--real-time .circle--orange {
  fill: #faa041;
  stroke: rgba(250, 160, 65, .2)
}

.svg--copilot-line .circle--purple, .svg--real-time .circle--purple {
  fill: #896ec5;
  stroke: rgba(137, 110, 197, .2)
}

.svg--bolt, .svg--browser, .svg--card, .svg--mobile, .svg--terminal {
  overflow: visible !important
}

.svg--bolt .line, .svg--browser .line, .svg--card .line, .svg--mobile .line,
.svg--terminal .line {
  fill: none;
  stroke: #e06116
}

.svg--bolt .block, .svg--browser .block, .svg--card .block, .svg--mobile .block,
.svg--terminal .block {
  fill: #e06116
}

.svg--cardpointe-features, .svg--copilot-features {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%
}

.svg--cardpointe-features .line, .svg--copilot-features .line {
  fill: none;
  stroke: #b5bdca
}

.svg--cardpointe-features .circle--grey, .svg--copilot-features .circle--grey {
  fill: #b5bdca;
  stroke: rgba(181, 189, 202, .2)
}

.svg--cardpointe-features .circle--purple, .svg--copilot-features .circle--purple {
  fill: #896ec5;
  stroke: rgba(137, 110, 197, .2)
}

.svg--cardpointe-features .circle--blue, .svg--copilot-features .circle--blue {
  fill: #37cbd9;
  stroke: rgba(55, 203, 217, .2)
}

.svg--cardpointe-features .circle--orange, .svg--copilot-features .circle--orange {
  fill: #faa041;
  stroke: rgba(250, 160, 65, .2)
}

.svg--cardpointe-features .circle--red, .svg--copilot-features .circle--red {
  fill: #f47875;
  stroke: hsla(1, 85%, 71%, .2)
}

@media screen and (max-width:58.125em) {
  .svg--cardpointe-features {
      display: none
  }
}

.svg--cardpointe-features .logo path:first-child {
  fill: #fff
}

.svg--cardpointe-features .logo path:nth-child(2) {
  fill: #e06116
}

.svg--cardpointe-features .logo path:nth-child(3) {
  fill: #0a4a6e
}

@media screen and (max-width:70.3125em) {
  .svg--copilot-features {
      display: none
  }
}

.svg--malware {
  height: 516px;
  left: 50%;
  margin-left: -258px;
  position: absolute;
  top: 0;
  width: 516px;
  overflow: visible !important
}

.svg--malware .icon path {
  fill: #fff
}

.svg--malware .icon path:first-child {
  fill: #c8293c
}

.svg--malware .line {
  fill: none;
  stroke: rgba(159, 4, 27, .25)
}

.svg--malware .fade {
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .8)), color-stop(50%, hsla(0, 0%, 100%, .8)), to(hsla(0, 0%, 100%, 0)));
  background-image: linear-gradient(hsla(0, 0%, 100%, .8), hsla(0, 0%, 100%, .8) 50%, hsla(0, 0%, 100%, 0));
  height: 100%;
  width: 100%;
  display: block
}

.svg--blog, .svg--team {
  height: 100%;
  max-width: 1200px;
  overflow: visible !important;
  width: 100%
}

.svg--blog .circle--blue, .svg--team .circle--blue {
  fill: #37cbd9;
  stroke: rgba(55, 203, 217, .2)
}

.svg--blog .circle--orange, .svg--team .circle--orange {
  fill: #faa041;
  stroke: rgba(250, 160, 65, .2)
}

.svg--blog .circle--purple, .svg--team .circle--purple {
  fill: #896ec5;
  stroke: rgba(137, 110, 197, .2)
}

.svg--blog .circle--grey, .svg--team .circle--grey {
  fill: #b5bdca;
  stroke: rgba(181, 189, 202, .2)
}

.svg--blog .circle--fade, .svg--team .circle--fade {
  opacity: .3
}

.svg--erp {
  max-width: 100%;
  height: auto;
  overflow: visible !important
}

.svg--erp .border {
  fill: none;
  stroke: #637cd7
}

.svg--erp .circle {
  fill: #fff;
  stroke: #ededed
}

.svg--erp .line {
  stroke: #637cd7
}

.svg--erp .part1 path {
  fill: #fff
}

.svg--erp .part1 path:first-child {
  fill: #637cd7
}

.svg--erp .part2 path {
  fill: #fff
}

.svg--erp .part2 path:first-child, .svg--erp .part3 path:first-child {
  stroke: #b5bdca;
  fill: none
}

.svg--erp .part3 path:nth-child(2) {
  fill: #fff
}

.svg--erp .part3 path:last-child {
  stroke: #fff;
  fill: none
}

.svg--erp .part4 path {
  fill: #fff
}

.svg--erp .part4 path:last-child {
  fill: none;
  stroke: #b5bdca
}

.features-grid__list {
  max-width: 1040px;
  margin-top: 15px;
  margin-bottom: 80px
}

@media screen and (min-width:40em) {
  .features-grid__list {
      margin-top: 15px
  }
}

.features-grid__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 20px;
  position: relative;
  padding-left: 60px
}

@media screen and (min-width:40em) {
  .features-grid__item {
      margin-top: 45px;
      opacity: 0
  }
}

.features-grid__item:first-child {
  -webkit-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out
}

.features-grid__item:nth-child(2) {
  -webkit-transition: opacity .15s ease-in-out .15s;
  transition: opacity .15s ease-in-out .15s
}

.features-grid__item:nth-child(3) {
  -webkit-transition: opacity .15s ease-in-out .3s;
  transition: opacity .15s ease-in-out .3s
}

.features-grid__item:nth-child(4) {
  -webkit-transition: opacity .15s ease-in-out .45s;
  transition: opacity .15s ease-in-out .45s
}

@media screen and (min-width:40em) {
  .features-grid__list--show .features-grid__item {
      opacity: 1
  }
}

.features-grid__item-img {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 2px;
  display: block;
  height: 30px;
  position: absolute;
  left: 15px;
  top: -2px;
  width: 30px
}

.features-grid__item-description {
  margin-bottom: 0;
  margin-top: 10px;
  max-width: 400px
}

@media screen and (min-width:40em) {
  .features-grid__item-description {
      font-size: 18px
  }
}

.erp-banner {
  background-color: #4060ba;
  background-image: linear-gradient(65deg, #644a9c, #4060ba 45%, #2471d0)
}

.erp-banner__container {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-top: 60px;
  padding-bottom: 50px;
  max-width: 1040px
}

@media screen and (min-width:68.75em) {
  .erp-banner__container {
      padding-top: 130px;
      padding-bottom: 135px
  }
}

.erp-banner__content, .erp-banner__img {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

@media screen and (max-width:68.6875em) {
  .erp-banner__content {
      margin-top: 30px
  }
}

.erp-banner__description {
  color: #b1c1e8
}

.erp-banner__list {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 0;
  margin-top: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.erp-banner__item {
  margin-top: 20px
}

.erp-banner__item:not(:last-child) {
  margin-right: 35px
}

.malware {
  position: relative;
  padding-bottom: 80px;
  padding-top: 160px;
  margin-bottom: 40px;
  margin-top: 50px
}

@media screen and (min-width:40em) {
  .malware {
      padding-bottom: 60px;
      margin-bottom: 60px;
      margin-top: 60px
  }
}

.malware__img {
  bottom: 0;
  top: 0
}

.malware__img, .malware__img:before {
  left: 0;
  position: absolute;
  width: 100%
}

.malware__img:before {
  border-top: 1px solid #ededed;
  content: "";
  top: 60%
}

.malware__content {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 557px;
  padding: 30px 20px;
  position: relative;
  text-align: center
}

@media screen and (min-width:40em) {
  .malware__content {
      padding: 30px 45px
  }
}

.malware__content:before {
  background: #fff;
  border-left: 1px solid #ededed;
  border-top: 1px solid #ededed;
  content: "";
  display: block;
  height: 16px;
  left: 50%;
  margin-left: -8px;
  position: absolute;
  top: -9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 16px
}

.malware__title {
  color: #bc1d32;
  font-weight: 700
}

.malware__description {
  margin-top: 20px;
  margin-bottom: 0
}

@media screen and (min-width:40em) {
  .malware__description {
      font-size: 16px
  }
}

.devices {
  background: #fbfcfd;
  padding-top: 80px;
  padding-bottom: 45px
}

@media screen and (min-width:40em) {
  .devices {
      padding-bottom: 120px
  }
}

.devices__title {
  font-weight: 700;
  text-align: center;
  width: 100%
}

.devices__title svg {
  display: block;
  margin-bottom: 20px;
  width: 100%
}

.devices__description {
  margin-bottom: 0;
  margin-top: 20px;
  max-width: 560px;
  text-align: center
}

@media screen and (min-width:40em) {
  .devices__description {
      font-size: 18px
  }
}

.devices__list {
  margin-bottom: 0;
  margin-top: 0;
  width: 100%
}

.devices__item {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;
  position: relative;
  padding-left: 115px
}

@media screen and (min-width:40em) {
  .devices__item {
      margin-top: 100px
  }
}

@media screen and (min-width:68.75em) {
  .devices__item {
      padding-left: 0
  }
}

.devices__item p {
  margin-bottom: 0
}

@media screen and (min-width:40em) {
  .devices__item p {
      font-size: 16px
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:after, .devices__item:before {
      content: "";
      display: block;
      position: absolute;
      top: 57px;
      z-index: 1
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:before {
      background: #fff;
      border: 0 solid #e06116;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 0;
      height: 0;
      width: 0
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:after {
      border-top: 1px solid #e06116
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:first-child .devices__img {
      -webkit-transition: background-size .15s ease-in-out .2s;
      transition: background-size .15s ease-in-out .2s
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:first-child::before {
      -webkit-transition: all .2s cubic-bezier(.175, .885, .32, 1.275) .2s;
      transition: all .2s cubic-bezier(.175, .885, .32, 1.275) .2s
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:first-child::after {
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:nth-child(2) .devices__img {
      -webkit-transition: background-size .15s ease-in-out .8s;
      transition: background-size .15s ease-in-out .8s
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:nth-child(2):before {
      -webkit-transition: all .2s cubic-bezier(.175, .885, .32, 1.275) .8s;
      transition: all .2s cubic-bezier(.175, .885, .32, 1.275) .8s
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:nth-child(2):after {
      -webkit-transition: all .2s ease-in-out .6s;
      transition: all .2s ease-in-out .6s
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:nth-child(3) .devices__img {
      -webkit-transition: background-size .15s ease-in-out 1.4s;
      transition: background-size .15s ease-in-out 1.4s
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:nth-child(3):before {
      -webkit-transition: all .2s cubic-bezier(.175, .885, .32, 1.275) 1.4s;
      transition: all .2s cubic-bezier(.175, .885, .32, 1.275) 1.4s
  }
}

@media screen and (min-width:68.75em) {
  .devices__item:nth-child(3):after {
      -webkit-transition: all .2s ease-in-out 1.2s;
      transition: all .2s ease-in-out 1.2s
  }
}

.devices__item--p2pe-1 .devices__img {
  background-image: url("../images/devices/device1-small.png")
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .devices__item--p2pe-1 .devices__img {
      background-image: url("../images/devices/device1-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--p2pe-1 .devices__img {
      background-image: url("../images/devices/device1.png")
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .devices__item--p2pe-1 .devices__img {
      background-image: url("../images/devices/device1@2x.png")
  }
}

.devices__item--p2pe-2 .devices__img {
  background-image: url("../images/devices/device2-small.png")
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .devices__item--p2pe-2 .devices__img {
      background-image: url("../images/devices/device2-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--p2pe-2 .devices__img {
      background-image: url("../images/devices/device2.png")
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .devices__item--p2pe-2 .devices__img {
      background-image: url("../images/devices/device2@2x.png")
  }
}

.devices__item--bolt-1 .devices__img {
  background-image: url("../images/devices/device3-small.png")
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .devices__item--bolt-1 .devices__img {
      background-image: url("../images/devices/device3-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-1 .devices__img {
      background-image: url("../images/devices/device3.png")
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .devices__item--bolt-1 .devices__img {
      background-image: url("../images/devices/device3@2x.png")
  }
}

.devices__item--bolt-2 .devices__img {
  background-image: url("../images/devices/device4-small.png")
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .devices__item--bolt-2 .devices__img {
      background-image: url("../images/devices/device4-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-2 .devices__img {
      background-image: url("../images/devices/device4.png")
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .devices__item--bolt-2 .devices__img {
      background-image: url("../images/devices/device4@2x.png")
  }
}

.devices__item--bolt-3 .devices__img {
  background-image: url("../images/devices/device5-small.png")
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .devices__item--bolt-3 .devices__img {
      background-image: url("../images/devices/device5-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-3 .devices__img {
      background-image: url("../images/devices/device5.png")
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .devices__item--bolt-3 .devices__img {
      background-image: url("../images/devices/device5@2x.png")
  }
}

.devices__item--bolt-2 .devices__img, .devices__item--p2pe-1 .devices__img {
  height: 173px;
  left: 0;
  top: -10px;
  width: 98px
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-2 .devices__img, .devices__item--p2pe-1 .devices__img {
      height: 276px;
      left: -191px;
      top: -67px;
      width: 156px
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-2:before, .devices__item--p2pe-1:before {
      left: -85px
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-2:after, .devices__item--p2pe-1:after {
      left: -10px;
      width: 0
  }
}

.devices__item--bolt-1 .devices__img, .devices__item--p2pe-2 .devices__img {
  height: 115px;
  left: -20px;
  top: -5px;
  width: 135px
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-1 .devices__img, .devices__item--p2pe-2 .devices__img {
      height: 241px;
      left: auto;
      right: -318px;
      top: -17px;
      width: 283px
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-1:before, .devices__item--p2pe-2:before {
      left: 485px
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-1:after, .devices__item--p2pe-2:after {
      left: 250px;
      width: 0
  }
}

.devices__item--bolt-3 .devices__img {
  height: 110px;
  left: -35px;
  top: -5px;
  width: 135px
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-3 .devices__img {
      height: 218px;
      left: auto;
      right: -302px;
      top: -25px;
      width: 267px
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-3:before {
      left: 485px
  }
}

@media screen and (min-width:68.75em) {
  .devices__item--bolt-3:after {
      left: 250px;
      width: 0
  }
}

.devices__img {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  display: block;
  position: absolute
}

@media screen and (min-width:68.75em) {
  .devices__img {
      background-size: 0 0
  }
}

@media screen and (min-width:68.75em) {
  .devices__list--show .devices__img {
      background-size: 100% 100%
  }
}

@media screen and (min-width:68.75em) {
  .devices__list--show .devices__item:before {
      border: 1px solid #e06116;
      border-radius: 5.5px;
      height: 11px;
      width: 11px
  }
}

@media screen and (min-width:68.75em) {
  .devices__list--show .devices__item--bolt-2:before, .devices__list--show .devices__item--p2pe-1:before {
      top: 52px
  }
}

@media screen and (min-width:68.75em) {
  .devices__list--show .devices__item--bolt-2:after, .devices__list--show .devices__item--p2pe-1:after {
      left: -75px;
      width: 65px
  }
}

@media screen and (min-width:68.75em) {
  .devices__list--show .devices__item--bolt-1:before, .devices__list--show .devices__item--bolt-3:before,
  .devices__list--show .devices__item--p2pe-2:before {
      top: 52px
  }
}

@media screen and (min-width:68.75em) {
  .devices__list--show .devices__item--bolt-1:after, .devices__list--show .devices__item--bolt-3:after,
  .devices__list--show .devices__item--p2pe-2:after {
      width: 235px
  }
}

.devices__icon {
  float: left
}

.devices__icon svg {
  display: block
}

.devices__icon:not(:first-child) {
  margin-left: 6px
}

.devices__item-title {
  margin-top: 15px
}

.devices__item-description, .devices__perfect-for {
  margin-top: 20px
}

.devices__features {
  margin-top: 15px
}

.devices__features, .devices__perfect-for {
  font-weight: lighter
}

.devices__features strong, .devices__perfect-for strong {
  color: #e06116
}

.devices__coming-soon {
  background-color: #ff842a;
  color: #fff;
  display: inline-block;
  line-height: 30px;
  margin-left: 9px;
  padding-left: 11px;
  padding-right: 18px;
  position: relative
}

.devices__coming-soon:before {
  border-bottom-width: 15px;
  border-left-width: 9px;
  border-right: 9px solid #ff842a;
  border-style: solid;
  border-top-width: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  content: "";
  height: 30px;
  left: -18px;
  position: absolute;
  width: 18px
}

.how {
  padding-bottom: 65px;
  padding-top: 45px
}

@media screen and (min-width:40em) {
  .how {
      padding-bottom: 80px;
      padding-top: 65px
  }
}

.how--cardsecure .how__icon {
  min-height: 80px
}

.how--integration {
  background: #fbfcfd;
  padding-top: 0
}

.how--integration .how__list {
  padding-top: 0
}

.how--integration .how__item:first-child {
  margin-top: 0
}

@media screen and (min-width:40em) {
  .how--integration .how__item {
      margin-top: 0
  }
}

.how--integration .how__icon {
  min-height: 73px
}

.how__title {
  font-weight: 700;
  text-align: center;
  width: 100%
}

.how__title svg {
  display: block;
  margin-bottom: 20px;
  width: 100%
}

.how__list {
  margin-top: 0;
  max-width: 1120px;
  padding-top: 20px
}

.how__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;
  position: relative;
  text-align: center
}

.how__item:after, .how__item:before {
  content: "";
  display: block;
  position: absolute
}

@media screen and (min-width:68.75em) {
  .how__item:before {
      background-image: -webkit-gradient(linear, left top, right top, from(#b5bdca), color-stop(42.85%, #b5bdca), color-stop(42.85%, hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
      background-image: linear-gradient(90deg, #b5bdca, #b5bdca 42.85%, hsla(0, 0%, 100%, 0) 0, hsla(0, 0%, 100%, 0));
      background-size: 7px 2px;
      height: 2px;
      left: 0;
      right: 0;
      top: 149.8px
  }
}

@media screen and (min-width:68.75em) {
  .how__item:after {
      background: radial-gradient(#b5bdca, #b5bdca 5px, rgba(181, 189, 202, .2) 0, rgba(181, 189, 202, .2));
      border-radius: 13px;
      height: 26px;
      left: 50%;
      margin-left: -13px;
      top: 137.8px;
      width: 26px
  }
}

@media screen and (min-width:68.75em) {
  .how__item:first-child:before {
      left: 50%
  }
}

@media screen and (min-width:68.75em) {
  .how__item:last-child:before {
      right: 50%
  }
}

.how__icon {
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.how__icon img, .how__icon svg {
  display: block
}

.how__icon--cart {
  padding-right: 7px
}

.how__item-title {
  color: #0a4a6e;
  font-weight: 700;
  margin-top: 20px
}

.how__item-description {
  margin: 20px auto 0;
  max-width: 300px;
  position: relative
}

@media screen and (min-width:68.75em) {
  .how__item-description {
      margin-top: 50px
  }
}

.how__item-description--left {
  text-align: left
}

.develop-banner {
  background-color: rgba(30, 173, 167, .9);
  background-image: linear-gradient(65deg, rgba(30, 173, 167, .9), rgba(36, 113, 208, .9)), url("../img/banner/develop.jpg");
  background-size: cover
}

.develop-banner__container {
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: 800px;
  text-align: center
}

@media screen and (min-width:40em) {
  .develop-banner__container {
      padding-top: 110px;
      padding-bottom: 110px
  }
}

.develop-banner__description {
  color: #fff
}

.form {
  padding-bottom: 60px;
  padding-top: 60px
}

@media screen and (min-width:40em) {
  .form {
      padding-bottom: 80px;
      padding-top: 90px
  }
}

.form--hidden, .form__input--hidden {
  display: none
}

.form--light {
  background: #fbfcfd
}

.form--light .form__file-name, .form--light .form__input {
  border-bottom: 1px solid #b5bdca
}

.form--light .form__file-name:focus, .form--light .form__input:focus {
  border-color: #0a4a6e
}

.form--light .form__input, .form--light .form__item--file, .form--light .form__textarea {
  color: #0a4a6e
}

.form--light .form__input::-webkit-input-placeholder, .form--light .form__item--file::-webkit-input-placeholder,
.form--light .form__textarea::-webkit-input-placeholder {
  color: #0a4a6e
}

.form--light .form__input:-ms-input-placeholder, .form--light .form__input::-ms-input-placeholder,
.form--light .form__item--file:-ms-input-placeholder, .form--light .form__item--file::-ms-input-placeholder,
.form--light .form__textarea:-ms-input-placeholder, .form--light .form__textarea::-ms-input-placeholder {
  color: #0a4a6e
}

.form--light .form__input::placeholder, .form--light .form__item--file::placeholder,
.form--light .form__textarea::placeholder {
  color: #0a4a6e
}

.form--light .form__input:-webkit-autofill, .form--light .form__item--file:-webkit-autofill,
.form--light .form__textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fbfcfd inset;
  -webkit-text-fill-color: #0a4a6e !important
}

.form--light .form__checkbox:not(:checked) + .form__checkbox-label {
  background: #fff;
  color: #0a4a6e
}

.form--light .form__checkbox:focus + .form__checkbox-label, .form--light .form__checkbox:hover + .form__checkbox-label {
  border-color: #0a4a6e
}

.form--light .form__description--contrast {
  color: #2a2a2a
}

.form--white {
  background: #fff
}

.form--dark {
  background: #0a4a6e
}

.form--dark .form__description {
  color: #89b9d4
}

.form--dark .form__description--left {
  color: #fff
}

.form--dark .form__input, .form--dark .form__input::-webkit-input-placeholder,
.form--dark .form__textarea, .form--dark .form__title {
  color: #fff
}

.form--dark .form__input, .form--dark .form__input:-ms-input-placeholder,
.form--dark .form__input::-ms-input-placeholder, .form--dark .form__textarea,
.form--dark .form__title {
  color: #fff
}

.form--dark .form__input, .form--dark .form__input::placeholder,
.form--dark .form__textarea, .form--dark .form__title {
  color: #fff
}

.form--dark .form__input, .form--dark .form__textarea {
  background-color: #0a4a6e;
  border-bottom: 1px solid #297199;
  border-color: #297199
}

.form--dark .form__input:focus, .form--dark .form__textarea:focus {
  border-color: #56a6d2
}

.form--dark .form__input:-webkit-autofill, .form--dark .form__textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #0a4a6e inset;
  -webkit-text-fill-color: #fff !important
}

.form--dark .form__checkbox:not(:checked) + .form__checkbox-label {
  background: #0a4a6e;
  color: #89b9d4
}

.form--dark .form__checkbox:focus + .form__checkbox-label, .form--dark .form__checkbox:hover + .form__checkbox-label {
  border-color: #56a6d2
}

.form__container {
  max-width: 1040px;
  text-align: center;
  margin-left: auto;
  margin-right: auto
}

.form__title {
  font-weight: 700;
  padding-left: 15px;
  padding-right: 15px
}

.form__title span {
  color: #e06116
}

.form__description {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 45px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%
}

@media screen and (min-width:40em) {
  .form__description {
      font-size: 16px
  }
}

.form__description--left {
  text-align: left
}

.form__description--above {
  font-size: 14px;
  margin-top: 10px
}

.form__item, .form__title + .form__description {
  margin-top: 20px
}

.form__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative
}

.form__item--checkbox {
  padding-left: 6px;
  padding-right: 6px
}

.form__wrapper--left {
  padding-left: 9px;
  padding-right: 9px
}

.form__item--file {
  cursor: pointer;
  position: relative;
  text-align: left
}

.form__item--file svg {
  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 15px
}

.form__item--file .form__input {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1
}

.form__input {
  border: none;
  background: transparent;
  outline: none;
  width: 100%
}

.form__file-name, .form__input {
  line-height: 1.55;
  padding-bottom: 12.6px;
  padding-top: 12.6px
}

.form__file-name {
  display: block;
  width: 100%
}

.form__checkbox {
  position: absolute;
  top: 5px
}

.form__checkbox-label {
  border: 1px solid;
  border-radius: 5px;
  display: block;
  padding: 10px 20px;
  position: relative;
  white-space: nowrap;
  z-index: 1
}

@media screen and (min-width:40em) {
  .form__checkbox-label {
      font-size: 16px
  }
}

.form__checkbox-label:focus, .form__checkbox-label:hover {
  cursor: pointer
}

.form__checkbox:checked + .form__checkbox-label {
  background: #0f567e;
  border-color: #0f567e;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  color: #fff
}

.form__checkbox:not(:checked) + .form__checkbox-label {
  border-color: #b5bdca
}

.form__textarea-container {
  padding-left: 15px;
  padding-right: 15px
}

.form__textarea, .form__textarea-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%
}

.form__textarea {
  border: 1px solid #ececec;
  border-radius: 5px;
  margin-top: 20px;
  min-height: 140px;
  padding: 25px 15px
}

.form__textarea:focus, .form__textarea:hover {
  outline: none
}

.form__checkbox-label, .form__input, .form__textarea {
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out
}

.form__button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px
}

.form__button[disabled] {
  opacity: .5
}

.form__radio-container {
  padding-top: 20px
}

.form__radio-label {
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap
}

.form__select-container {
  background: #fff;
  border: 1px solid #0a4a6e;
  border-radius: 5px;
  color: #0a4a6e;
  display: inline-block;
  margin-top: 20px;
  position: relative
}

.form__select-container svg {
  margin-top: -3px;
  position: absolute;
  right: 10px;
  top: 50%
}

.form__select {
  background: transparent;
  border: none;
  font-size: 16px;
  height: 46px;
  padding-left: 20px;
  padding-right: 42px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.form__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000
}

.form__option {
  outline: none
}

.form--block {
  background: #fff;
  border: 1px solid #ececec;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  padding: 30px 40px
}

.form--block .form__title {
  color: #0a4a6e;
  font-weight: 700
}

.form--block .form__title svg {
  margin-top: 10px
}

.form--block .form__input {
  margin-top: 15px;
  padding-bottom: 6.65px;
  padding-top: 6.65px
}

.form--block .form__input:first-of-type {
  margin-top: 30px
}

.form--block .form__textarea {
  display: block;
  min-height: 70px;
  padding: 11.15px 15px
}

.form--block .form__textarea-container {
  padding-left: 0;
  padding-right: 0
}

.form--block .form__button {
  margin-top: 20px;
  width: 100%
}

.form--hero {
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, .3);
  box-shadow: 0 10px 40px rgba(0, 0, 0, .3);
  border: none;
  left: 10px;
  right: 10px;
  bottom: -20px
}

@media screen and (min-width:56.3125em) {
  .form--hero {
      position: absolute
  }
}

.form__recaptcha {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px
}

.form__recaptcha--no-padding {
  padding-left: 0;
  padding-right: 0
}

.media {
  position: relative
}

.media--hpp .media__container, .media--integration .media__container,
.media--mobile-app .media__container, .media--p2pe-terminal .media__container,
.media--real-time .media__container, .media--virtual-terminal .media__container {
  max-width: 1040px
}

.media--real-time .media__container {
  margin-bottom: 80px;
  margin-top: 210px;
  padding-top: 260px
}

@media screen and (min-width:68.75em) {
  .media--real-time .media__container {
      margin-bottom: 110px;
      margin-top: 119px;
      padding-bottom: 270px;
      padding-top: 100px
  }
}

.media--real-time .media__img {
  background-image: url("../images/media/phone-small.png");
  height: 398px;
  margin-right: -94px;
  right: 50%;
  top: -165px;
  width: 188px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--real-time .media__img {
      background-image: url("../images/media/phone-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--real-time .media__img {
      background-image: url("../images/media/phone.png");
      height: 509px;
      margin-right: 0;
      right: -256px;
      top: -55px;
      opacity: 0;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      width: 216px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .media--real-time .media__img {
      background-image: url("../images/media/phone@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--real-time .media__img--show {
      right: 40px;
      opacity: 1
  }
}

.media--virtual-terminal {
  overflow: hidden
}

.media--virtual-terminal .media__description, .media--virtual-terminal .media__title {
  color: #fff
}

.media--virtual-terminal .svg--browser path {
  stroke: #fff
}

.media--virtual-terminal .media__container {
  padding-bottom: 160px;
  padding-top: 160px
}

@media screen and (min-width:68.75em) {
  .media--virtual-terminal .media__container {
      padding-bottom: 195px;
      padding-top: 195px
  }
}

.media--virtual-terminal .media__img {
  background-image: -webkit-gradient(linear, right top, left top, from(#e06116), to(rgba(224, 97, 22, .5))), url("../images/media/table.jpg");
  background-image: linear-gradient(270deg, #e06116, rgba(224, 97, 22, .5)), url("../images/media/table.jpg");
  background-position: 50%;
  background-size: cover;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--virtual-terminal .media__img {
      background-image: -webkit-gradient(linear, right top, left top, from(#e06116), to(rgba(224, 97, 22, .5))), url("../images/media/table@2x.jpg");
      background-image: linear-gradient(270deg, #e06116, rgba(224, 97, 22, .5)), url("../images/media/table@2x.jpg")
  }
}

@media screen and (min-width:68.75em) {
  .media--virtual-terminal .media__img {
      background-image: -webkit-gradient(linear, right top, left top, from(#e06116), to(hsla(0, 0%, 100%, 0))), url("../images/media/table.jpg");
      background-image: linear-gradient(270deg, #e06116, hsla(0, 0%, 100%, 0)), url("../images/media/table.jpg")
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .media--virtual-terminal .media__img {
      background-image: -webkit-gradient(linear, right top, left top, from(#e06116), to(hsla(0, 0%, 100%, 0))), url("../images/media/table@2x.jpg");
      background-image: linear-gradient(270deg, #e06116, hsla(0, 0%, 100%, 0)), url("../images/media/table@2x.jpg")
  }
}

.media--mobile-app {
  background-color: #fbfcfd;
  background-image: linear-gradient(180deg, rgba(10, 74, 110, .1), rgba(251, 252, 253, 0) 64px), linear-gradient(0deg, rgba(10, 74, 110, .1), rgba(251, 252, 253, 0) 64px)
}

.media--mobile-app .media__container {
  padding-bottom: 365px;
  padding-top: 60px
}

@media screen and (min-width:68.75em) {
  .media--mobile-app .media__container {
      padding-bottom: 190px;
      padding-top: 190px
  }
}

.media--mobile-app .media__img {
  background-image: url("../images/media/hand-small.png");
  bottom: 0;
  height: 316px;
  margin-right: -110px;
  right: 50%;
  width: 219px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--mobile-app .media__img {
      background-image: url("../images/media/hand-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--mobile-app .media__img {
      background-image: url("../images/media/hand.png");
      height: 617px;
      margin-right: 0;
      opacity: 0;
      right: -448px;
      -webkit-transition: all .15s ease-in-out;
      transition: all .15s ease-in-out;
      width: 428px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .media--mobile-app .media__img {
      background-image: url("../images/media/hand@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--mobile-app .media__img--show {
      right: -20px;
      opacity: 1
  }
}

.media--p2pe-terminal {
  padding-bottom: 40px;
  padding-top: 250px
}

@media screen and (min-width:68.75em) {
  .media--p2pe-terminal {
      padding-bottom: 155px;
      padding-top: 155px
  }
}

.media--p2pe-terminal .media__img {
  background-image: url("../images/media/terminal-small.png");
  height: 193px;
  margin-right: -87px;
  right: 50%;
  top: -193px;
  width: 173px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--p2pe-terminal .media__img {
      background-image: url("../images/media/terminal-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--p2pe-terminal .media__img {
      background-image: url("../images/media/terminal.png");
      height: 405px;
      left: -344px;
      margin-right: 0;
      opacity: 0;
      right: auto;
      top: -70px;
      -webkit-transition: all .15s ease-in-out;
      transition: all .15s ease-in-out;
      width: 364px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .media--p2pe-terminal .media__img {
      background-image: url("../images/media/terminal@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--p2pe-terminal .media__img--show {
      left: -20px;
      opacity: 1
  }
}

.media--hpp {
  background-color: #fbfcfd;
  background-image: linear-gradient(180deg, rgba(10, 74, 110, .1), rgba(251, 252, 253, 0) 64px), linear-gradient(0deg, rgba(10, 74, 110, .1), rgba(251, 252, 253, 0) 64px)
}

.media--hpp .media__container {
  padding-bottom: 320px;
  padding-top: 60px
}

@media screen and (min-width:68.75em) {
  .media--hpp .media__container {
      padding-bottom: 190px;
      padding-top: 190px
  }
}

.media--hpp .media__img {
  background-image: url("../images/media/hpp-small.png");
  bottom: 60px;
  height: 218px;
  margin-right: -150px;
  right: 50%;
  width: 300px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--hpp .media__img {
      background-image: url("../images/media/hpp-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--hpp .media__img {
      background-image: url("../images/media/hpp.png");
      height: 399px;
      margin-right: 0;
      margin-top: -200px;
      opacity: 0;
      right: -570px;
      top: 50%;
      -webkit-transition: all .15s ease-in-out;
      transition: all .15s ease-in-out;
      width: 550px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .media--hpp .media__img {
      background-image: url("../images/media/hpp@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--hpp .media__img--show {
      right: -20px;
      opacity: 1
  }
}

.media--integration .media__content {
  max-width: 520px
}

@media screen and (min-width:40em) {
  .media--integration .media__content h1, .media--integration .media__content h2,
  .media--integration .media__content h3, .media--integration .media__content h4,
  .media--integration .media__content h5, .media--integration .media__content h6 {
      font-size: 18px
  }
}

.media--integration .media__content h1:not(:first-of-type), .media--integration .media__content h2:not(:first-of-type),
.media--integration .media__content h3:not(:first-of-type), .media--integration .media__content h4:not(:first-of-type),
.media--integration .media__content h5:not(:first-of-type), .media--integration .media__content h6:not(:first-of-type) {
  margin-top: 25px
}

@media screen and (min-width:40em) {
  .media--integration .media__content h1:not(:first-of-type), .media--integration .media__content h2:not(:first-of-type),
  .media--integration .media__content h3:not(:first-of-type), .media--integration .media__content h4:not(:first-of-type),
  .media--integration .media__content h5:not(:first-of-type), .media--integration .media__content h6:not(:first-of-type) {
      margin-top: 35px
  }
}

.media--integration .media__content p {
  margin-top: 0;
  margin-bottom: 0
}

@media screen and (min-width:40em) {
  .media--integration .media__content p {
      font-size: 16px
  }
}

.media--integration-1 {
  background: #fbfcfd;
  padding-bottom: 415px;
  overflow: hidden
}

@media screen and (min-width:68.75em) {
  .media--integration-1 {
      padding-bottom: 80px
  }
}

.media--integration-1 .media__img {
  background-image: url("../images/media/security-1.jpg");
  bottom: -55px;
  height: 465px;
  left: 50%;
  margin-left: -335px;
  width: 671px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--integration-1 .media__img {
      background-image: url("../images/media/security-1@2x.jpg")
  }
}

@media screen and (min-width:68.75em) {
  .media--integration-1 .media__img {
      bottom: 0;
      margin-left: -696px
  }
}

.media--integration-2 {
  padding-bottom: 445px
}

@media screen and (min-width:68.75em) {
  .media--integration-2 {
      padding-bottom: 80px
  }
}

.media--integration-2 .media__img {
  background-image: url("../images/media/security-2.png");
  bottom: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 249px;
  right: 50%;
  margin-right: -135px;
  width: 291px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--integration-2 .media__img {
      background-image: url("../images/media/security-2@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--integration-2 .media__img {
      bottom: 50%;
      margin-bottom: -85px;
      margin-right: -451px
  }
}

.media--integration-2 img {
  margin-top: -40px;
  margin-left: 20px
}

.media--investor {
  padding-bottom: 580px;
  padding-top: 60px
}

@media screen and (min-width:68.75em) {
  .media--investor {
      padding-bottom: 75px;
      padding-top: 85px
  }
}

.media--investor .media__description, .media--investor .media__title {
  text-align: center
}

.media--investor .media__content, .media--investor .media__description,
.media--investor .media__title {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.media--investor .media__description {
  max-width: 100%
}

.media--investor .media__content {
  margin-top: 40px;
  max-width: 520px
}

@media screen and (min-width:40em) {
  .media--investor .media__content {
      margin-top: 70px
  }
}

@media screen and (min-width:68.75em) {
  .media--investor .media__content {
      min-height: 530px
  }
}

.media--investor .media__content p {
  margin-top: 0;
  margin-bottom: 0
}

@media screen and (min-width:40em) {
  .media--investor .media__content p {
      font-size: 16px
  }
}

.media--investor .media__content p + p {
  margin-top: 25px
}

.media--investor .media__img {
  background-image: url("../images/media/investor-small.png");
  bottom: 70px;
  height: 462px;
  margin-right: -177px;
  right: 50%;
  width: 355px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--investor .media__img {
      background-image: url("../images/media/investor-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .media--investor .media__img {
      background-image: url("../images/media/investor.png");
      bottom: 75px;
      height: 523px;
      margin-right: -502px;
      width: 402px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .media--investor .media__img {
      background-image: url("../images/media/investor@2x.png")
  }
}

.media--copilot .media__container {
  padding-bottom: 185px;
  padding-top: 155px
}

@media screen and (min-width:68.75em) {
  .media--copilot .media__container {
      padding-bottom: 220px;
      padding-top: 190px
  }
}

.media--copilot .media__content {
  max-width: 525px
}

.media--copilot .media__description, .media--copilot .media__title {
  color: #fff
}

.media--copilot .media__img {
  background-color: #4060ba;
  background-image: linear-gradient(65deg, rgba(100, 74, 156, .83), rgba(64, 96, 186, .83) 45%, #2471d0), url("../images/media/copilot-small.jpg");
  background-position: 50%;
  background-size: cover;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .media--copilot .media__img {
      background-image: linear-gradient(65deg, rgba(100, 74, 156, .83), rgba(64, 96, 186, .83) 45%, #2471d0), url("../images/media/copilot-small@2x.jpg")
  }
}

@media screen and (min-width:68.75em) {
  .media--copilot .media__img {
      background-image: linear-gradient(65deg, rgba(100, 74, 156, .83), rgba(64, 96, 186, .83) 45%, #2471d0), url("../images/media/copilot.jpg")
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .media--copilot .media__img {
      background-image: linear-gradient(65deg, rgba(100, 74, 156, .83), rgba(64, 96, 186, .83) 45%, #2471d0), url("../images/media/copilot@2x.jpg")
  }
}

.media__container {
  max-width: 1120px;
  position: relative
}

@media screen and (max-width:68.6875em) {
  .media__container {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }
}

.media__container:after {
  background: #f5f8fb;
  content: "";
  display: block;
  position: absolute;
  z-index: -1
}

.media__content {
  max-width: 480px;
  position: relative;
  width: 100%
}

.media__title {
  font-weight: 700
}

.media__title svg {
  display: block;
  margin-bottom: 20px
}

.media__description {
  margin-bottom: 0;
  margin-top: 20px;
  max-width: 480px
}

@media screen and (min-width:40em) {
  .media__description {
      font-size: 18px
  }
}

.media__img {
  background-size: 100% 100%;
  display: block;
  position: absolute
}

.features-diagram__container {
  max-width: 1120px;
  position: relative
}

.features-diagram__title {
  font-weight: 700;
  text-align: center;
  width: 100%
}

.features-diagram__list {
  margin: 45px auto 0;
  text-align: left
}

.features-diagram__item {
  color: #2a2a2a
}

.features-diagram__item:not(:first-of-type) {
  margin-top: 20px
}

.features-diagram--cardpointe {
  background: #eff1f4;
  padding-bottom: 45px;
  padding-top: 65px
}

@media screen and (min-width:40em) {
  .features-diagram--cardpointe {
      padding-bottom: 55px;
      padding-top: 75px
  }
}

@media screen and (max-width:58.125em) {
  .features-diagram--cardpointe .features-diagram__item {
      position: relative;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-left: 30px
  }

  .features-diagram--cardpointe .features-diagram__item:before {
      background: rgba(55, 203, 217, .2)
  }

  .features-diagram--cardpointe .features-diagram__item:after {
      background: #37cbd9
  }

  .features-diagram--cardpointe .features-diagram__item:after, .features-diagram--cardpointe .features-diagram__item:before {
      content: "";
      position: absolute
  }

  .features-diagram--cardpointe .features-diagram__item:before {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      left: 0;
      top: 0
  }

  .features-diagram--cardpointe .features-diagram__item:after {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: 7px;
      top: 7px
  }

  .features-diagram--cardpointe .features-diagram__item:first-child::before,
  .features-diagram--cardpointe .features-diagram__item:nth-child(2):before {
      background: rgba(181, 189, 202, .2)
  }

  .features-diagram--cardpointe .features-diagram__item:first-child::after,
  .features-diagram--cardpointe .features-diagram__item:nth-child(2):after {
      background: #b5bdca
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(3):before,
  .features-diagram--cardpointe .features-diagram__item:nth-child(4):before,
  .features-diagram--cardpointe .features-diagram__item:nth-child(5):before {
      background: rgba(250, 160, 65, .2)
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(3):after,
  .features-diagram--cardpointe .features-diagram__item:nth-child(4):after,
  .features-diagram--cardpointe .features-diagram__item:nth-child(5):after {
      background: #faa041
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(6):before {
      background: hsla(1, 85%, 71%, .2)
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(6):after {
      background: #f47875
  }
}

@media screen and (min-width:58.1875em) {
  .features-diagram--cardpointe .features-diagram__container {
      height: 470px
  }

  .features-diagram--cardpointe .features-diagram__list {
      height: 100%;
      left: 0;
      margin-top: 0;
      padding-left: 0;
      position: absolute;
      top: 0;
      width: 100%
  }

  .features-diagram--cardpointe .features-diagram__item {
      line-height: 1.3;
      position: absolute;
      text-align: center
  }

  .features-diagram--cardpointe .features-diagram__item:not(:first-of-type) {
      margin-top: 0
  }

  .features-diagram--cardpointe .features-diagram__item:first-child,
  .features-diagram--cardpointe .features-diagram__item:nth-child(2),
  .features-diagram--cardpointe .features-diagram__item:nth-child(3),
  .features-diagram--cardpointe .features-diagram__item:nth-child(9) {
      margin-top: -24.5%
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(4),
  .features-diagram--cardpointe .features-diagram__item:nth-child(8) {
      margin-top: -15%
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(5),
  .features-diagram--cardpointe .features-diagram__item:nth-child(7) {
      margin-top: -9%
  }

  .features-diagram--cardpointe .features-diagram__item:first-child,
  .features-diagram--cardpointe .features-diagram__item:nth-child(6),
  .features-diagram--cardpointe .features-diagram__item:nth-child(7),
  .features-diagram--cardpointe .features-diagram__item:nth-child(8),
  .features-diagram--cardpointe .features-diagram__item:nth-child(9) {
      left: 50%
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(2),
  .features-diagram--cardpointe .features-diagram__item:nth-child(3),
  .features-diagram--cardpointe .features-diagram__item:nth-child(4),
  .features-diagram--cardpointe .features-diagram__item:nth-child(5) {
      right: 50%
  }

  .features-diagram--cardpointe .features-diagram__item:first-child {
      margin-left: -160px;
      top: 370px;
      width: 155px
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(2) {
      margin-right: -135px;
      top: 370px;
      width: 100px
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(3) {
      margin-right: -430px;
      top: 458px
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(4) {
      margin-right: -440px;
      top: 448px
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(5) {
      margin-right: -440px;
      top: 476px
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(6) {
      margin-left: -100px;
      margin-top: -3%;
      top: 480px;
      width: 200px
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(7) {
      margin-left: -442px;
      top: 476px
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(8) {
      margin-left: -363px;
      top: 448px
  }

  .features-diagram--cardpointe .features-diagram__item:nth-child(9) {
      margin-left: -380px;
      top: 458px
  }
}

.features-diagram--copilot {
  padding-bottom: 45px;
  padding-top: 65px
}

@media screen and (min-width:40em) {
  .features-diagram--copilot {
      padding-bottom: 118px;
      padding-top: 57px
  }
}

@media screen and (max-width:70.3125em) {
  .features-diagram--copilot .features-diagram__item {
      position: relative;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-left: 30px
  }

  .features-diagram--copilot .features-diagram__item:before {
      background: rgba(55, 203, 217, .2)
  }

  .features-diagram--copilot .features-diagram__item:after {
      background: #37cbd9
  }

  .features-diagram--copilot .features-diagram__item:after, .features-diagram--copilot .features-diagram__item:before {
      content: "";
      position: absolute
  }

  .features-diagram--copilot .features-diagram__item:before {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      left: 0;
      top: 0
  }

  .features-diagram--copilot .features-diagram__item:after {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: 7px;
      top: 7px
  }

  .features-diagram--copilot .features-diagram__item:first-child::before,
  .features-diagram--copilot .features-diagram__item:nth-child(2):before {
      background: rgba(137, 110, 197, .2)
  }

  .features-diagram--copilot .features-diagram__item:first-child::after,
  .features-diagram--copilot .features-diagram__item:nth-child(2):after {
      background: #896ec5
  }

  .features-diagram--copilot .features-diagram__item:nth-child(3):before,
  .features-diagram--copilot .features-diagram__item:nth-child(4):before,
  .features-diagram--copilot .features-diagram__item:nth-child(5):before {
      background: rgba(250, 160, 65, .2)
  }

  .features-diagram--copilot .features-diagram__item:nth-child(3):after,
  .features-diagram--copilot .features-diagram__item:nth-child(4):after,
  .features-diagram--copilot .features-diagram__item:nth-child(5):after {
      background: #faa041
  }

  .features-diagram--copilot .features-diagram__item:nth-child(6):before {
      background: hsla(1, 85%, 71%, .2)
  }

  .features-diagram--copilot .features-diagram__item:nth-child(6):after {
      background: #f47875
  }
}

@media screen and (min-width:70.375em) {
  .features-diagram--copilot .features-diagram__container {
      height: 470px;
      position: relative
  }

  .features-diagram--copilot .features-diagram__logo {
      background-image: url("../images/features//copilot-icon.png");
      background-size: 100%;
      bottom: 115px;
      height: 113px;
      left: 50%;
      margin-left: -56px;
      position: absolute;
      width: 113px;
      z-index: 1
  }
}

@media screen and (min-width:70.375em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:70.375em) and (min-resolution:192dpi) {
  .features-diagram--copilot .features-diagram__logo {
      background-image: url("../images/features//copilot-icon@2x.png")
  }
}

@media screen and (min-width:70.375em) {
  .features-diagram--copilot .features-diagram__list {
      height: 100%;
      left: 0;
      margin-top: 0;
      padding-left: 0;
      position: absolute;
      top: 0;
      width: 100%
  }

  .features-diagram--copilot li {
      line-height: 1.3;
      position: absolute;
      text-align: center
  }

  .features-diagram--copilot li:not(:first-of-type) {
      margin-top: 0
  }

  .features-diagram--copilot li:first-child, .features-diagram--copilot li:nth-child(2),
  .features-diagram--copilot li:nth-child(3), .features-diagram--copilot li:nth-child(9) {
      margin-top: -24.5%
  }

  .features-diagram--copilot li:nth-child(4), .features-diagram--copilot li:nth-child(8) {
      margin-top: -15%
  }

  .features-diagram--copilot li:nth-child(5), .features-diagram--copilot li:nth-child(7) {
      margin-top: -9%
  }

  .features-diagram--copilot li:first-child, .features-diagram--copilot li:nth-child(6),
  .features-diagram--copilot li:nth-child(7), .features-diagram--copilot li:nth-child(8),
  .features-diagram--copilot li:nth-child(9) {
      left: 50%
  }

  .features-diagram--copilot li:nth-child(2), .features-diagram--copilot li:nth-child(3),
  .features-diagram--copilot li:nth-child(4), .features-diagram--copilot li:nth-child(5) {
      right: 50%
  }

  .features-diagram--copilot li:first-child {
      margin-left: -165px;
      top: 370px;
      width: 155px
  }

  .features-diagram--copilot li:nth-child(2) {
      margin-right: -135px;
      top: 370px;
      width: 100px
  }

  .features-diagram--copilot li:nth-child(3) {
      margin-right: -443px;
      top: 458px
  }

  .features-diagram--copilot li:nth-child(4) {
      margin-right: -482px;
      top: 448px
  }

  .features-diagram--copilot li:nth-child(5) {
      margin-right: -518px;
      top: 476px
  }

  .features-diagram--copilot li:nth-child(6) {
      margin-left: -125px;
      margin-top: -3%;
      top: 480px;
      width: 250px
  }

  .features-diagram--copilot li:nth-child(7) {
      margin-left: -449px;
      top: 476px
  }

  .features-diagram--copilot li:nth-child(8) {
      margin-left: -485px;
      top: 448px
  }

  .features-diagram--copilot li:nth-child(9) {
      margin-left: -543px;
      top: 458px
  }
}

@-webkit-keyframes android-2-3-checked-fix {
  0% {
      opacity: 1
  }

  to {
      opactity: 1
  }
}

@keyframes android-2-3-checked-fix {
  0% {
      opacity: 1
  }

  to {
      opactity: 1
  }
}

body {
  -webkit-animation: android-2-3-checked-fix 1s infinite;
  animation: android-2-3-checked-fix 1s infinite
}

.tabs {
  position: relative
}

.tabs__radio {
  position: absolute;
  left: -999em
}

#tab1-radio:focus ~ .tabs__tab-list #tab1, #tab2-radio:focus ~ .tabs__tab-list #tab2,
#tab3-radio:focus ~ .tabs__tab-list #tab3, #tab4-radio:focus ~ .tabs__tab-list #tab4,
#tab5-radio:focus ~ .tabs__tab-list #tab5, .tabs__tab:focus {
  background: hsla(0, 0%, 94%, .5);
  outline: none
}

.tabs__tab-list {
  border-bottom: 1px solid #ececec;
  display: block;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  width: 100%
}

.tabs__tab-item {
  display: inline-block
}

.tabs__tab {
  cursor: pointer;
  display: block;
  font-weight: 700;
  padding: 30px 15px;
  position: relative;
  text-transform: uppercase;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out
}

@media screen and (min-width:40em) {
  .tabs__tab {
      font-size: 16px;
      padding: 30px 20px
  }
}

.tabs__tab:focus, .tabs__tab:hover {
  color: #0a4a6e
}

.tabs__tab:after {
  bottom: -2px;
  content: "";
  display: block;
  height: 2px;
  left: 15px;
  position: absolute;
  right: 15px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out
}

@media screen and (min-width:40em) {
  .tabs__tab:after {
      left: 20px;
      right: 20px
  }
}

#tab1-radio:checked ~ .tabs__tab-list #tab1, #tab2-radio:checked ~ .tabs__tab-list #tab2,
#tab3-radio:checked ~ .tabs__tab-list #tab3, #tab4-radio:checked ~ .tabs__tab-list #tab4,
#tab5-radio:checked ~ .tabs__tab-list #tab5, .tabs__tab-item--selected .tabs__tab {
  color: #0a4a6e;
  cursor: default
}

#tab1-radio:checked ~ .tabs__tab-list #tab1:after, #tab2-radio:checked ~ .tabs__tab-list #tab2:after,
#tab3-radio:checked ~ .tabs__tab-list #tab3:after, #tab4-radio:checked ~ .tabs__tab-list #tab4:after,
#tab5-radio:checked ~ .tabs__tab-list #tab5:after, .tabs__tab-item--selected .tabs__tab:after {
  background: #0a4a6e
}

.tabs__content {
  display: none
}

#tab1-radio:checked ~ .tabs__content-list #tab1-content, #tab2-radio:checked ~ .tabs__content-list #tab2-content,
#tab3-radio:checked ~ .tabs__content-list #tab3-content, #tab4-radio:checked ~ .tabs__content-list #tab4-content,
#tab5-radio:checked ~ .tabs__content-list #tab5-content {
  display: block
}

.title--cardsecure {
  padding-top: 190px
}

@media screen and (min-width:68.75em) {
  .title--cardsecure {
      padding-top: 135px
  }
}

.title--integrate {
  padding-bottom: 15px;
  padding-top: 110px
}

@media screen and (min-width:68.75em) {
  .title--integrate {
      padding-top: 160px
  }
}

.title--integration {
  padding-top: 70px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1
}

@media screen and (min-width:68.75em) {
  .title--integration {
      padding-top: 85px
  }
}

.title--integration.title--dark {
  background-color: #fbfcfd
}

.title--integration .title__description, .title--integration .title__main {
  max-width: 840px
}

.title--culture {
  padding-bottom: 65px
}

@media screen and (min-width:40em) {
  .title--culture {
      padding-bottom: 85px
  }
}

.title--culture .title__main {
  margin-top: 70px
}

@media screen and (min-width:40em) {
  .title--culture .title__main {
      margin-top: 80px
  }
}

.title--copilot-checklist {
  padding-top: 30px
}

.title__container, .title__upper {
  text-align: center
}

.title__upper {
  color: #e06116;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%
}

@media screen and (min-width:40em) {
  .title__upper {
      font-size: 16px
  }
}

.title__main {
  color: #2a2a2a;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 20px;
  max-width: 720px;
  text-align: center;
  width: 100%
}

.title__main span {
  color: #e06116
}

.title__description {
  margin-bottom: 0;
  margin-top: 30px;
  max-width: 720px
}

@media screen and (min-width:40em) {
  .title__description {
      font-size: 18px
  }
}

.title__description span {
  color: #e06116
}

.icon-list {
  background: #fbfcfd;
  padding-bottom: 60px
}

@media screen and (min-width:40em) {
  .icon-list {
      padding-bottom: 70px
  }
}

.icon-list__description {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 950px;
  text-align: center;
  margin: 45px auto 0
}

@media screen and (min-width:40em) {
  .icon-list__description {
      font-size: 18px;
      margin-top: 55px
  }
}

.icon-list__list {
  padding-top: 20px;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 1040px
}

.icon-list__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 30px
}

.icon-list__content {
  position: relative;
  padding-left: 40px;
  max-width: 420px
}

.icon-list__content svg {
  position: absolute
}

.icon-list__content--bolt svg, .icon-list__content--tick svg {
  left: 10px
}

.icon-list__content--card svg {
  left: 8px;
  top: 2px
}

.icon-list__content--padlock svg {
  left: 11px;
  top: -4px
}

.icon-list__content--browser svg {
  left: 10px
}

.icon-list__content--broadcast svg {
  left: 9px;
  top: 3px
}

.icon-list__content--chip svg, .icon-list__content--link svg, .icon-list__content--web svg {
  left: 10px;
  top: -1px
}

.icon-list__content--download svg {
  left: 10px
}

.icon-list__content--egg svg {
  left: 9px;
  top: -1px
}

.icon-list__content--build svg {
  left: 10px;
  top: -3px
}

.icon-list__content--cloud svg {
  left: 10px;
  top: 2px
}

.icon-list__content--pen svg {
  left: 8px;
  top: -1px
}

.icon-list__cta {
  text-align: center
}

.icon-list__button-title {
  margin-top: 45px;
  width: 100%
}

@media screen and (min-width:40em) {
  .icon-list__button-title {
      margin-top: 65px
  }
}

.icon-list__button {
  margin-top: 20px
}

.split-banner {
  max-width: 100%
}

.split-banner__section {
  background-position: 50%, 50%;
  background-size: cover;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center
}

.split-banner--integrate .split-banner__section {
  padding-bottom: 70px;
  padding-top: 90px
}

@media screen and (min-width:40em) {
  .split-banner--integrate .split-banner__section {
      padding-bottom: 150px;
      padding-top: 150px
  }
}

.split-banner--integrate .split-banner__section:first-child {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(82, 145, 215, .9)), to(rgba(82, 145, 215, .9))), url("../img/banner/integrate-split-1.jpg");
  background-image: linear-gradient(rgba(82, 145, 215, .9), rgba(82, 145, 215, .9)), url("../img/banner/integrate-split-1.jpg")
}

.split-banner--integrate .split-banner__section:nth-child(2) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(94, 118, 218, .9)), to(rgba(94, 118, 218, .9))), url("../img/banner/integrate-split-2.jpg");
  background-image: linear-gradient(rgba(94, 118, 218, .9), rgba(94, 118, 218, .9)), url("../img/banner/integrate-split-2.jpg")
}

.split-banner--integrate .split-banner__section:nth-child(3) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(122, 95, 226, .9)), to(rgba(122, 95, 226, .9))), url("../img/banner/integrate-split-3.jpg");
  background-image: linear-gradient(rgba(122, 95, 226, .9), rgba(122, 95, 226, .9)), url("../img/banner/integrate-split-3.jpg")
}

.split-banner--integrate .split-banner__description {
  max-width: 380px
}

.split-banner--integrate .split-banner__button {
  display: inline-block
}

.split-banner--company .split-banner__section {
  padding-bottom: 70px;
  padding-top: 90px
}

@media screen and (min-width:40em) {
  .split-banner--company .split-banner__section {
      padding-top: 95px
  }
}

.split-banner--company .split-banner__section:first-child {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(82, 145, 215, .9)), to(rgba(82, 145, 215, .9))), url("../img/banner/company-split-1.jpg");
  background-image: linear-gradient(rgba(82, 145, 215, .9), rgba(82, 145, 215, .9)), url("../img/banner/company-split-1.jpg")
}

.split-banner--company .split-banner__section:nth-child(2) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(94, 118, 218, .9)), to(rgba(94, 118, 218, .9))), url("../img/banner/company-split-2.jpg");
  background-image: linear-gradient(rgba(94, 118, 218, .9), rgba(94, 118, 218, .9)), url("../img/banner/company-split-2.jpg")
}

.split-banner--company .split-banner__section:nth-child(3) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(56, 182, 192, .9)), to(rgba(56, 182, 192, .9))), url("../img/banner/company-split-4.jpg");
  background-image: linear-gradient(rgba(56, 182, 192, .9), rgba(56, 182, 192, .9)), url("../img/banner/company-split-4.jpg")
}

.split-banner--company .split-banner__section:nth-child(4) {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(122, 95, 226, .9)), to(rgba(122, 95, 226, .9))), url("../img/banner/company-split-3.jpg");
  background-image: linear-gradient(rgba(122, 95, 226, .9), rgba(122, 95, 226, .9)), url("../img/banner/company-split-3.jpg")
}

.split-banner--company .split-banner__button {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  display: inline-block;
  margin-top: 25px
}

.split-banner--company .split-banner__button:focus, .split-banner--company .split-banner__button:hover {
  background-color: hsla(0, 0%, 100%, .1);
  border-color: #fff;
  color: #fff
}

.split-banner__title {
  color: #fff;
  font-size: 24px;
  font-weight: 700
}

.split-banner__description {
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0
}

.split-banner__title + .split-banner__description {
  margin-top: 15px
}

.partner-grid {
  padding-bottom: 40px;
  padding-top: 65px
}

@media screen and (min-width:40em) {
  .partner-grid {
      padding-bottom: 60px;
      padding-top: 80px
  }
}

.partner-grid--template {
  background: #fbfcfd;
  padding-bottom: 80px
}

@media screen and (min-width:40em) {
  .partner-grid--template {
      padding-bottom: 120px;
      padding-top: 80px
  }
}

.partner-grid--template .partner-grid__item {
  padding-bottom: 0;
  padding-top: 0
}

@media screen and (max-width:68.6875em) {
  .partner-grid--template .partner-grid__item:not(:first-of-type) {
      border-left: none;
      padding-top: 45px
  }
}

.partner-grid__container {
  text-align: center
}

.partner-grid__title {
  font-weight: 700;
  width: 100%
}

.partner-grid__description {
  margin-bottom: 0;
  margin-top: 30px;
  width: 100%
}

@media screen and (min-width:40em) {
  .partner-grid__description {
      font-size: 18px
  }
}

.partner-grid__list {
  margin-bottom: 0;
  margin-top: 45px;
  padding-bottom: 30px;
  width: 100%
}

@media screen and (min-width:40em) {
  .partner-grid__list {
      padding-bottom: 55px
  }
}

.partner-grid__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px
}

@media screen and (min-width:40em) {
  .partner-grid__item {
      padding-bottom: 35px;
      padding-top: 25px
  }
}

@media screen and (min-width:40em) {
  .partner-grid__item:not(:first-of-type) {
      border-left: 1px solid #efefef
  }
}

.partner-grid__item img, .partner-grid__item svg {
  margin-bottom: 20px
}

.partner-grid__item svg {
  width: 100%
}

@media screen and (min-width:40em) {
  .partner-grid__item h1, .partner-grid__item h2, .partner-grid__item h3,
  .partner-grid__item h4, .partner-grid__item h5, .partner-grid__item h6 {
      font-size: 18px
  }
}

.partner-grid__item p {
  margin: 0 auto;
  max-width: 320px
}

@media screen and (min-width:40em) {
  .partner-grid__item p {
      font-size: 16px
  }
}

.partner-grid__item a {
  font-weight: 700;
  text-decoration: underline
}

.partner-grid__button {
  color: #2a2a2a;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px
}

.partner-grid__button:focus, .partner-grid__button:hover {
  color: #2a2a2a
}

.comparison {
  background-color: #4060ba;
  background-image: linear-gradient(65deg, #644a9c, #4060ba 45%, #2471d0);
  padding-bottom: 60px;
  padding-top: 65px
}

@media screen and (min-width:40em) {
  .comparison {
      padding-bottom: 50px;
      padding-top: 35px
  }
}

.comparison__container {
  text-align: center
}

.comparison__title {
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  width: 100%
}

@media screen and (min-width:40em) {
  .comparison__title {
      font-size: 16px
  }
}

.comparison__sub-title {
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 20px;
  text-align: center;
  width: 100%
}

.comparison__description {
  color: #b1c1e8;
  margin-bottom: 0;
  margin-top: 20px;
  width: 100%
}

@media screen and (min-width:40em) {
  .comparison__description {
      font-size: 18px
  }
}

.comparison__section-container {
  width: 200%;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease
}

.comparison__section {
  float: left;
  max-width: 50%;
  width: 50%
}

.comparison__list {
  margin-bottom: 0;
  margin-top: 25px
}

.comparison__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 30px !important;
  padding-right: 30px !important
}

@media screen and (min-width:40em) and (max-width:68.6875em) {
  .comparison__item:nth-child(odd) {
      padding-right: 15px !important
  }
}

@media screen and (min-width:40em) and (max-width:68.6875em) {
  .comparison__item:nth-child(2n) {
      padding-left: 15px !important
  }
}

@media screen and (min-width:68.75em) {
  .comparison__item {
      padding-left: 3px !important;
      padding-right: 3px !important
  }
}

.comparison__content {
  border-radius: 5px;
  padding: 20px 25px 15px
}

.comparison__item:nth-child(4n + 1) .comparison__content {
  background-color: #5291d7
}

.comparison__item:nth-child(4n + 2) .comparison__content {
  background-color: #5e82da
}

.comparison__item:nth-child(4n + 3) .comparison__content {
  background-color: #6e6cde
}

.comparison__item:nth-child(4n + 4) .comparison__content {
  background-color: #7a5fe2
}

.comparison__item-title {
  color: #fff;
  font-weight: 700
}

.comparison__item-list {
  margin-bottom: 0;
  margin-top: 20px;
  padding-left: 30px
}

.comparison__item-item {
  color: #fff;
  padding-bottom: 12.6px;
  padding-top: 12.6px;
  position: relative
}

.comparison__item-item:after, .comparison__item-item:before {
  content: "";
  position: absolute
}

.comparison__item-item:before {
  width: 18px;
  height: 18px;
  background: hsla(0, 0%, 100%, .2);
  border-radius: 9px;
  left: -30px;
  top: 16px
}

.comparison__item-item:after {
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 3px;
  left: -24px;
  top: 22px
}

.comparison__item-smallprint {
  color: #fff;
  margin-top: 15px;
  margin-bottom: 0
}

.comparison__control-list {
  text-align: center;
  margin: 40px auto 0
}

@media screen and (min-width:40em) {
  .comparison__control-list {
      margin-top: 45px
  }
}

.comparison__control-item {
  display: inline-block
}

.comparison__control-item:not(:first-child) {
  margin-left: 10px
}

.comparison__control-item--selected .comparison__control {
  background: #fff
}

.comparison__control {
  background: #b1c1e8;
  border: none;
  border-radius: 9px;
  font-size: 18px;
  height: 18px;
  line-height: 1;
  width: 18px
}

.comparison__control:focus, .comparison__control:hover {
  background: #fff;
  outline: none
}

.comparison__hide {
  display: none
}

.slider {
  background: #fbfcfd;
  padding-top: 60px
}

.slider__container {
  text-align: center
}

.slider__title {
  font-weight: 700;
  width: 100%
}

.slider__content-list, .slider__icons-list {
  margin-bottom: 0;
  margin-top: 40px
}

@media screen and (min-width:40em) {
  .slider__content-list, .slider__icons-list {
      margin-top: 50px
  }
}

.slider__icons-list {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

@media screen and (min-width:40em) {
  .slider__icons-list {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }
}

.slider__icons-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative
}

@media screen and (min-width:68.75em) {
  .slider__icons-item {
      padding-left: 20px;
      padding-right: 20px
  }
}

.slider__icons-item--selected:after {
  background: #eff1f4;
  bottom: -47px;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 14px
}

@media screen and (min-width:40em) {
  .slider__icons-item--selected:after {
      bottom: -57px
  }
}

.slider__icons-item--selected .slider__icons-img {
  opacity: 1
}

.slider__icons-img {
  opacity: .5;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out
}

.slider__content-list {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #eff1f4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 360px;
  position: relative
}

.slider__arrow {
  margin-top: -15px;
  position: absolute;
  top: 50%
}

.slider__arrow--left {
  left: 10px
}

@media screen and (min-width:40em) {
  .slider__arrow--left {
      left: 50%;
      margin-left: -300px
  }
}

@media screen and (min-width:68.75em) {
  .slider__arrow--left {
      margin-left: -425px
  }
}

.slider__arrow--right {
  right: 10px
}

@media screen and (min-width:40em) {
  .slider__arrow--right {
      margin-right: -300px;
      right: 50%
  }
}

@media screen and (min-width:68.75em) {
  .slider__arrow--right {
      margin-right: -425px
  }
}

.slider__control {
  background: transparent;
  border: none;
  padding: 0
}

.slider__control svg {
  display: block
}

.slider__control svg path {
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out
}

.slider__control:focus, .slider__control:hover {
  outline: none
}

.slider__control:focus .slider__icons-img, .slider__control:hover .slider__icons-img {
  opacity: 1
}

.slider__control:focus svg path, .slider__control:hover svg path {
  fill: #5b5f6b
}

.slider__content-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  width: 100%
}

.slider__content-description {
  color: #2a2a2a;
  margin: 20px auto 0;
  max-width: 450px
}

@media screen and (min-width:40em) {
  .slider__content-description {
      font-size: 16px;
      margin-top: 25px
  }
}

.slider__content-link {
  color: #2a2a2a;
  display: inline-block;
  font-weight: 700;
  margin-top: 20px
}

@media screen and (min-width:40em) {
  .slider__content-link {
      margin-top: 35px
  }
}

.careers {
  padding-bottom: 60px;
  padding-top: 45px
}

@media screen and (min-width:40em) {
  .careers {
      padding-bottom: 80px;
      padding-top: 80px
  }
}

.careers__title {
  font-weight: 700;
  text-align: center;
  width: 100%
}

.careers__description {
  margin-bottom: 0;
  margin-top: 30px;
  max-width: 580px;
  text-align: center;
  width: 100%
}

@media screen and (min-width:40em) {
  .careers__description {
      font-size: 18px
  }
}

.careers__department-title {
  margin-top: 45px;
  color: #8e929e;
  width: 100%
}

@media screen and (max-width:39.9375em) {
  .careers__department-title {
      text-align: center
  }
}

@media screen and (min-width:40em) {
  .careers__department-title {
      margin-top: 60px
  }
}

.careers__department-list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 20px;
  max-width: 1300px;
  padding-left: 10px;
  padding-right: 10px
}

@media screen and (min-width:40em) {
  .careers__department-list {
      margin-top: 6px;
      padding-left: 8px;
      padding-right: 8px
  }
}

.careers__department-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px
}

@media screen and (min-width:40em) {
  .careers__department-item {
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 14px
  }
}

.careers__department-item--selected .careers__department-link {
  color: #0a4a6e;
  font-weight: 700
}

@media screen and (min-width:40em) {
  .careers__department-item--selected .careers__department-link {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: relative
  }

  .careers__department-item--selected .careers__department-link:after {
      background: #fff;
      bottom: -14px;
      border-left: 1px solid #ececec;
      border-right: 1px solid #ececec;
      content: "";
      display: block;
      height: 14px;
      left: -1px;
      position: absolute;
      width: 100%
  }
}

.careers__department-link {
  border: 1px solid #ececec;
  border-radius: 3px;
  display: block;
  padding-bottom: 17.6px;
  padding-top: 17.6px;
  text-align: center
}

@media screen and (min-width:68.75em) {
  .careers__department-link {
      font-size: 18px
  }
}

.careers__positions {
  border: 1px solid #ececec;
  margin-top: 12px;
  width: 100%
}

.accordion {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 0;
  padding: 20px 10px;
  width: 100%
}

@media screen and (min-width:40em) {
  .accordion {
      padding-left: 30px;
      padding-right: 30px
  }
}

.accordion__item:first-child .accordion__header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px
}

.accordion__item--open .accordion__title {
  color: #0a4a6e;
  font-weight: 700
}

.accordion__item--open .arrow-down {
  display: none !important
}

.accordion__item:not(.accordion__item--open):last-child .accordion__header {
  border-bottom: 1px solid #ececec
}

.accordion__item:not(.accordion__item--open) .accordion__container {
  border-top: none;
  max-height: 0;
  overflow: 0;
  padding-bottom: 0;
  padding-top: 0
}

.accordion__item:not(.accordion__item--open) .arrow-up {
  display: none
}

.accordion__header {
  border: 1px solid #ececec;
  border-bottom: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 15.15px 10px;
  position: relative;
  width: 100%
}

@media screen and (min-width:40em) {
  .accordion__header {
      padding: 13.6px 22px
  }
}

.accordion__header .arrow-down, .accordion__header .arrow-up {
  display: block;
  margin-top: -3px;
  position: absolute;
  right: 10px;
  top: 50%
}

@media screen and (min-width:40em) {
  .accordion__header .arrow-down, .accordion__header .arrow-up {
      right: 22px
  }
}

.accordion__header:focus .accordion__title, .accordion__header:hover .accordion__title {
  color: #0a4a6e
}

.accordion__header:focus .arrow-down path, .accordion__header:hover .arrow-down path {
  fill: #0a4a6e
}

.accordion__title {
  color: #8e929e
}

@media screen and (min-width:40em) {
  .accordion__title {
      font-size: 16px
  }
}

.accordion__container {
  width: 100%;
  border: 1px solid #f4f4f4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fbfcfd;
  margin: 0 !important;
  overflow: hidden;
  padding: 30px 20px 50px
}

@media screen and (min-width:40em) {
  .accordion__container {
      padding: 35px 30px 55px
  }
}

.accordion__content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: 100%
}

@media screen and (min-width:68.75em) {
  .accordion__content {
      padding-right: 80px
  }
}

.accordion__content ul {
  padding-left: 20px;
  list-style-type: disc
}

.accordion__content li, .accordion__content p {
  margin: 0
}

@media screen and (min-width:40em) {
  .accordion__content li, .accordion__content p {
      font-size: 16px
  }
}

.accordion__content li, .accordion__content p + p {
  margin-top: 25px
}

.accordion__content h1, .accordion__content h2, .accordion__content h3,
.accordion__content h4, .accordion__content h5, .accordion__content h6 {
  font-weight: 400;
  margin-top: 50px
}

.accordion__content h1 strong, .accordion__content h2 strong, .accordion__content h3 strong,
.accordion__content h4 strong, .accordion__content h5 strong, .accordion__content h6 strong {
  font-weight: 400
}

.accordion__content a {
  color: #e06116
}

.accordion__content a:focus, .accordion__content a:hover {
  color: #ef5d07
}

.accordion__details {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 300px;
  padding: 0
}

@media screen and (max-width:68.6875em) {
  .accordion__details {
      margin-top: 50px
  }
}

@media screen and (min-width:40em) {
  .accordion__details {
      max-width: 350px
  }
}

@media screen and (min-width:68.75em) {
  .accordion__details {
      border-left: 1px solid #ececec;
      padding-left: 40px
  }
}

.accordion__detail-item {
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;
  width: 100%
}

.accordion__detail-item:not(:first-of-type) {
  margin-top: 30px
}

.accordion__detail-item--location svg {
  top: 1px;
  left: 2px
}

@media screen and (min-width:40em) {
  .accordion__detail-item--location svg {
      top: 4px
  }
}

.accordion__detail-item--type svg {
  top: 3px
}

@media screen and (min-width:40em) {
  .accordion__detail-item--type svg {
      top: 6px
  }
}

.accordion__detail-item--salary svg {
  top: 2px;
  left: 3px
}

@media screen and (min-width:40em) {
  .accordion__detail-item--salary svg {
      top: 6px
  }
}

.accordion__detail-item--apply {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

.accordion__detail-item--apply:not(:first-of-type) {
  margin-top: 60px
}

.accordion__detail-item--apply .accordion__detail-title {
  padding-left: 0
}

.accordion__detail-description, .accordion__detail-title {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

@media screen and (min-width:40em) {
  .accordion__detail-description, .accordion__detail-title {
      font-size: 18px
  }
}

.accordion__detail-title {
  padding-left: 30px
}

.accordion__detail-title svg {
  left: 0;
  position: absolute
}

.accordion__detail-description {
  margin-bottom: 0;
  margin-top: 0;
  padding-right: 0
}

.accordion__details-button-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 0
}

.testimonial {
  display: none;
  overflow: hidden;
  -webkit-transition: all .5s ease;
  transition: all .5s ease
}

.testimonial__container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  position: relative;
  text-align: center
}

@media screen and (min-width:40em) {
  .testimonial__container {
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 40px
  }
}

.testimonial__description {
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  z-index: 1
}

@media screen and (min-width:40em) {
  .testimonial__description {
      font-size: 18px
  }
}

.testimonial__profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 35px
}

.testimonial__details {
  padding-left: 25px;
  text-align: left
}

.testimonial__title {
  color: #0a4a6e;
  font-weight: 700;
  text-transform: uppercase
}

.testimonial__sub-title {
  color: #8e929e
}

.testimonial__img {
  border-radius: 25px
}

.testimonial svg {
  left: 0;
  position: absolute;
  top: -70px
}

@media screen and (min-width:40em) {
  .testimonial svg {
      top: 0
  }
}

.testimonial--active {
  display: block
}

@media screen and (min-width:40em) {
  .testimonial--small .testimonial__description {
      font-size: 16px
  }
}

.testimonial--dark svg path {
  fill: rgba(247, 249, 251, .1)
}

.testimonial--dark .testimonial__description, .testimonial--dark .testimonial__title {
  color: #fff
}

.testimonial--dark .testimonial__sub-title {
  color: #88b3cb
}

.testimonial--banner {
  background-color: #4060ba;
  background-image: url("../images/testimonials/testimonial.jpg"), linear-gradient(65deg, #644a9c, #4060ba 45%, #2471d0);
  background-position: 50%;
  background-size: cover;
  padding-bottom: 80px;
  padding-top: 90px
}

@media screen and (max-width:39.9375em) {
  .testimonial--banner svg {
      left: 15px;
      top: -50px
  }
}

@media screen and (min-width:40em) {
  .testimonial--banner {
      padding-bottom: 120px;
      padding-top: 110px
  }
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .testimonial--banner {
      background-image: url("../images/testimonials/testimonial@2x.jpg"), linear-gradient(65deg, #644a9c, #4060ba 45%, #2471d0)
  }
}

.testimonial--banner .testimonial__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 690px
}

@media screen and (max-width:39.9375em) {
  .testimonial--banner .testimonial__container {
      padding-left: 15px;
      padding-right: 15px
  }
}

.testimonial--banner .testimonial__sub-title {
  color: #fff
}

.team {
  position: relative;
  top: -130px
}

.team__container {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden
}

.team__content {
  display: inline-block;
  overflow: visible;
  text-align: left;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.team__row {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 130px;
  padding-right: 130px;
  white-space: nowrap
}

@media screen and (min-width:40em) {
  .team__row {
      padding-left: 110px;
      padding-right: 110px
  }
}

.team__row:nth-child(2) {
  padding-left: 200px
}

.team__member {
  display: inline-block;
  overflow: visible
}

.team__link {
  display: block;
  padding: 20px;
  position: relative;
  height: 100px;
  width: 100px
}

@media screen and (min-width:40em) {
  .team__link {
      padding: 40px
  }
}

.team__link:focus .team__details, .team__link:hover .team__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.team__img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0)
}

.team__details {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: -5px;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 50%;
  margin-left: -200px;
  padding-top: 15px;
  position: absolute;
  width: 400px
}

@media screen and (min-width:40em) {
  .team__details {
      bottom: 5px
  }
}

.team__details svg {
  margin-left: 10px
}

.team__title {
  color: #0a4a6e;
  display: inline
}

.support {
  background: #fbfcfd;
  padding-bottom: 35px;
  padding-top: 60px
}

@media screen and (min-width:40em) {
  .support {
      padding-bottom: 55px
  }
}

.page--partner .support {
  background: #fff
}

.support__container {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: 1120px
}

.support__call, .support__main {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

@media screen and (max-width:39.9375em) {
  .support__main {
      text-align: center
  }
}

.support__title {
  font-weight: 700;
  margin-top: 20px
}

.support__description {
  margin-bottom: 0;
  margin-top: 15px
}

@media screen and (min-width:40em) {
  .support__description {
      font-size: 18px
  }
}

.support__list {
  margin-bottom: 0;
  margin-top: 0
}

.support__item {
  display: inline-block
}

.support__item:not(:first-child) {
  margin-left: 15px
}

.support__img {
  height: 80px;
  width: 80px
}

@media screen and (max-width:39.9375em) {
  .support__call {
      margin-top: 40px
  }
}

.support__call-container {
  border: 1px solid #ececec;
  text-align: center;
  padding: 30px 20px;
  border-radius: 4px
}

.support__call-title {
  color: #e06116;
  font-size: 24px;
  font-weight: lighter
}

.support__call-title svg {
  padding-right: 5px;
  position: relative;
  top: 5px
}

.support__call-number {
  color: #0a4a6e;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 15px
}

.support__call-number a {
  color: #0a4a6e
}

.support__call-number a:focus, .support__call-number a:hover {
  color: #005d92
}

.support__call-description {
  margin: 15px auto 0;
  max-width: 240px
}

.news-header {
  background-color: #fbfcfd;
  padding-top: 35px;
  width: 100%
}

@media screen and (min-width:40em) {
  .news-header {
      padding-top: 64.2px
  }
}

.news-header__container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.news-header__title {
  font-weight: lighter;
  margin-top: 50px;
  width: 100%
}

@media screen and (max-width:39.9375em) {
  .news-header__title {
      text-align: center
  }
}

.news-header__back {
  font-weight: 700;
  margin-top: 25px
}

@media screen and (max-width:39.9375em) {
  .news-header__back {
      display: none
  }
}

.news-header__back:focus svg path, .news-header__back:hover svg path {
  fill: #5b5f6b
}

.news-header__back svg {
  padding-right: 10px;
  position: relative;
  top: 1px
}

.news-header__tags {
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  padding-right: 25px;
  position: relative
}

@media screen and (min-width:40em) {
  .news-header__tags {
      margin-left: 40px;
      margin-top: 22px
  }
}

.news-header__tags:before {
  content: "Show";
  font-weight: 700;
  padding-right: 5px
}

.news-header__tags-title {
  border-bottom: 1px solid #0a4a6e;
  color: #0a4a6e;
  display: inline-block;
  font-weight: 700;
  position: relative;
  text-transform: lowercase
}

.news-header__tags-title svg {
  position: absolute;
  right: -25px;
  top: 11px
}

.news-header__tags-title svg path {
  fill: #0a4a6e
}

.news-header__tags-list {
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  left: -999em;
  margin-bottom: 0;
  margin-top: 0;
  position: absolute;
  top: -10px;
  white-space: nowrap
}

.news-header__tags-list svg {
  position: absolute;
  right: 18px;
  top: 30px
}

.news-header__tags-list--open {
  left: 52px
}

.news-header__tags-item {
  font-weight: 700
}

.news-header__tags-item .news-header__tags-link {
  padding: 5px 50px 0 30px
}

.news-header__tags-item:first-child .news-header__tags-link {
  padding-top: 20px
}

.news-header__tags-item:last-child .news-header__tags-link {
  padding-bottom: 20px
}

.news-header__tags-item--selected .news-header__tags-link {
  color: #0a4a6e
}

.news-header__tags-item--selected .news-header__tags-link:focus,
.news-header__tags-item--selected .news-header__tags-link:hover {
  color: #005d92
}

.news-header__tags-link {
  display: block
}

.news-header__tags-link:focus {
  outline: none;
  text-decoration: underline
}

.news-header__tags:hover .news-header__tags-list {
  left: 52px
}

.news-list {
  background-color: #fbfcfd;
  padding-bottom: 60px;
  margin-left: 0;
  margin-right: 0;
  width: 100%
}

.news-list:not(.news-list--no-spacing) {
  padding-top: 40px
}

.news-list__list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden
}

.news-list__item {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, .05);
  box-shadow: 0 0 12px rgba(0, 0, 0, .05);
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%
}

.news-list__item:not(:first-child) {
  margin-top: 20px
}

@media screen and (min-width:40em) {
  .news-list__item:not(:first-child) {
      margin-top: 10px
  }
}

.news-list__content, .news-list__img-container, .news-list__link {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.news-list__content {
  overflow: hidden;
  padding-bottom: 30px;
  padding-top: 30px
}

@media screen and (max-width:39.9375em) {
  .news-list__content {
      padding: 30px 20px
  }
}

.news-list__date, .news-list__tag {
  display: inline;
  margin-bottom: 0;
  margin-top: 0
}

.news-list__tag {
  border-right: 2px solid #ececec;
  margin-right: 15px;
  padding-right: 15px
}

.news-list__title {
  margin-top: 15px
}

.news-list__title-link {
  color: #2a2a2a
}

.news-list__img-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%
}

@media screen and (max-width:39.9375em) {
  .news-list__img-container {
      margin-top: 40px
  }
}

@media screen and (min-width:40em) {
  .news-list__img-container {
      width: 175px
  }
}

.news-list__img {
  max-width: 135px;
  height: auto
}

.news-list__link {
  border-bottom-right-radius: 5px;
  color: #e06116;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

@media screen and (max-width:39.9375em) {
  .news-list__link {
      border-bottom-left-radius: 5px;
      border-top: 1px solid #eaeaea;
      padding-bottom: 25px;
      padding-top: 25px;
      width: 100%
  }
}

@media screen and (min-width:40em) {
  .news-list__link {
      border-left: 1px solid #eaeaea;
      border-top-right-radius: 5px;
      padding: 70px 15px
  }
}

.news-list__link:focus, .news-list__link:hover {
  background: #e06116;
  color: #fff;
  outline: none
}

.news-list__description {
  margin-bottom: 0;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis
}

.news-list__description p {
  display: inline;
  margin-bottom: 0;
  margin-top: 0
}

@media screen and (max-width:39.9375em) {
  .news-list__description p {
      font-size: 14px
  }
}

@media screen and (min-width:40em) {
  .news-list__description p {
      white-space: nowrap
  }
}

.news-list__description p:not(:first-child) {
  display: none
}

.pagination-buttons {
  background: #fbfcfd;
  padding-bottom: 60px;
  padding-top: 60px;
  margin-bottom: 0;
  margin-top: -60px;
  text-align: center
}

.pagination-buttons__item {
  display: inline-block
}

.pagination-buttons__item:not(:first-child) {
  margin-left: 10px
}

.related-posts {
  background: #fbfcfd;
  margin-left: auto;
  margin-right: auto
}

.related-posts--blog {
  padding-bottom: 60px
}

.related-posts--narrow {
  max-width: 1190px
}

.related-posts__container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.related-posts__title {
  margin-bottom: 20px;
  margin-top: 50px;
  width: 100%
}

@media screen and (max-width:39.9375em) {
  .related-posts__title {
      text-align: center
  }
}

@media screen and (min-width:40em) {
  .related-posts__title {
      margin-bottom: 40px;
      margin-top: 70px
  }
}

.post {
  padding-bottom: 60px;
  padding-top: 40px
}

@media screen and (min-width:40em) {
  .post {
      padding-bottom: 70px;
      padding-top: 65px
  }
}

.post--blog {
  position: relative;
  padding-top: 0;
  margin-top: -192px
}

.post--blog .post__back {
  background-color: hsla(0, 0%, 100%, .25);
  color: #fff;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px
}

.post--blog .post__back path {
  fill: #fff
}

.post--blog .post__back:focus, .post--blog .post__back:hover {
  border-color: #fff;
  color: #fff
}

.post--blog .post__back:focus path, .post--blog .post__back:hover path {
  fill: #fff
}

.post__container {
  max-width: 800px
}

.post__container--small {
  max-width: 715px
}

.post__container--large {
  max-width: 1030px
}

.post__back {
  font-weight: 700
}

.post__back:focus svg path, .post__back:hover svg path {
  fill: #5b5f6b
}

.post__back svg {
  padding-right: 10px;
  position: relative;
  top: 1px
}

.post__header {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  margin-top: 15px;
  padding: 16px 63px 58px;
  width: 100%
}

.post__title {
  font-size: 35px;
  font-weight: 300;
  line-height: 49px;
  margin-top: 38px
}

.post__date {
  margin-bottom: 0;
  margin-top: 35px;
  width: 100%
}

@media screen and (min-width:40em) {
  .post__date {
      font-size: 16px;
      margin-top: 50px
  }
}

@media screen and (min-width:40em) {
  .post__content {
      margin-top: 13px
  }
}

.post__content h1, .post__content h2, .post__content h3, .post__content h4,
.post__content h5, .post__content h6 {
  font-size: 22px;
  margin-top: 40px
}

@media screen and (min-width:40em) {
  .post__content h1, .post__content h2, .post__content h3, .post__content h4,
  .post__content h5, .post__content h6 {
      margin-top: 45px
  }
}

.post__content ol, .post__content p, .post__content ul {
  color: #2a2a2a;
  margin-bottom: 0;
  margin-top: 40px
}

@media screen and (min-width:40em) {
  .post__content ol, .post__content p, .post__content ul {
      margin-top: 25px
  }
}

.post__content a {
  color: #2a2a2a
}

.post__content strong {
  font-weight: 700
}

.post__content iframe, .post__content img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  padding-top: 40px
}

@media screen and (min-width:40em) {
  .post__content iframe, .post__content img {
      padding-top: 25px
  }
}

.post__content img {
  height: auto !important
}

.post__connect-list, .post__share-list {
  margin-bottom: 0;
  margin-top: 0
}

.post__connect-item, .post__share-item {
  display: inline-block;
  margin: 0 5px
}

.post__share {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px
}

.post__share-title {
  margin-right: 15px
}

.post__connect {
  text-align: center;
  width: 100%
}

.post__connect-title {
  margin-top: 25px
}

.post__connect-item {
  margin-top: 15px
}

.post__comments {
  margin-top: 30px;
  width: 100%
}

.post__author {
  background: #f9f9f9;
  margin-top: 30px;
  padding-bottom: 35px;
  padding-top: 20px
}

.post__author-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: nowrap;
  flex-flow: nowrap;
  max-width: 800px
}

.post__author-img {
  padding-right: 13px
}

.post__author-img img {
  border-radius: 50%
}

.post__author-title {
  color: #0a4a6e;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 10px
}

.post__author-about {
  max-width: 480px
}

.post__author-about p {
  color: #2a2a2a;
  font-weight: 700;
  margin: 5px 0 0
}

.post__details {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  overflow: hidden
}

.post__details-right {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.post__details-right, .post__member-img {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.post__member-img {
  background: #fff;
  padding-right: 15px;
  position: relative;
  z-index: 1
}

.post__member-name {
  color: #0a4a6e;
  font-weight: 700
}

.post__details-date, .post__member-name, .post__read-time, .post__tag {
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap
}

.post__details-date, .post__member-name, .post__tag {
  padding-right: 32px
}

.post__details-date:not(:first-child), .post__read-time:not(:first-child),
.post__tag:not(:first-child) {
  border-left: 2px solid #ececec;
  margin-left: -17px;
  padding-left: 15px
}

.post__read-time {
  color: #0a4a6e
}

.blog {
  background: #f9f9f9
}

.blog__container {
  padding-bottom: 60px;
  margin-top: -100px;
  max-width: 1240px;
  position: relative;
  z-index: 1
}

@media screen and (min-width:68.75em) {
  .blog__container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
  }
}

.blog__aside, .blog__posts {
  width: 100%
}

@media screen and (min-width:68.75em) {
  .blog__aside {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-left: 19px;
      max-width: 300px;
      width: 100%
  }
}

.blog__posts {
  min-width: 0
}

@media screen and (min-width:68.75em) {
  .blog__posts {
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1
  }
}

.blog__posts-list {
  margin: 0
}

@media screen and (min-width:68.75em) {
  .blog__posts-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      max-width: 100%;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }
}

.blog__pagination {
  background: transparent
}

.card {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  width: 100%
}

@media screen and (min-width:68.75em) {
  .card {
      padding-top: 20px
  }

  .card:first-child {
      padding-top: 0
  }

  .card:first-child .card__img {
      padding-top: 48%
  }

  .card:nth-child(1n + 6) .card__img {
      display: none
  }

  .card:nth-child(2), .card:nth-child(4) {
      padding-right: 10px
  }

  .card:nth-child(3), .card:nth-child(5) {
      padding-left: 10px
  }

  .card:nth-child(1n + 2):not(:nth-child(1n + 6)) {
      width: 50%
  }

  .card:nth-child(1n + 2):not(:nth-child(1n + 6)) .card__img {
      padding-top: 67%
  }

  .card:nth-child(1n + 2):not(:nth-child(1n + 6)) .card__container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse
  }

  .card:nth-child(1n + 2):not(:nth-child(1n + 6)) .card__title {
      font-size: 18px;
      margin-top: 24px
  }

  .card:nth-child(1n + 2):not(:nth-child(1n + 6)) .card__description {
      margin-top: 12px
  }

  .card:nth-child(1n + 2):not(:nth-child(1n + 6)) .card__content {
      padding: 0 24px
  }

  .card:nth-child(1n + 2):not(:nth-child(1n + 6)) .card__meta {
      border-top: 1px solid #eee;
      margin-top: 26px;
      padding: 12px 24px
  }
}

.card__link {
  background: #fff;
  -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%
}

.card__link:focus .card__title, .card__link:hover .card__title {
  color: #005d92
}

.card__link:focus .card__more, .card__link:hover .card__more {
  color: #ef5d07
}

.card__img {
  background-size: cover;
  background-position: 50%;
  width: 100%;
  padding-top: 54%
}

.card__container {
  width: 100%
}

@media screen and (min-width:68.75em) {
  .card__container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between
  }
}

.card__content {
  padding: 8px 14px 14px
}

@media screen and (min-width:68.75em) {
  .card__content {
      padding: 21px 34px 37px
  }
}

.card__title {
  color: #0a4a6e;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 800px
}

@media screen and (min-width:68.75em) {
  .card__title {
      font-size: 28px
  }
}

.card__description {
  margin-top: 8px;
  max-width: 800px
}

@media screen and (min-width:68.75em) {
  .card__description {
      margin-top: 20px
  }
}

.card__description p {
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

@media screen and (min-width:68.75em) {
  .card__description p {
      font-size: 18px
  }
}

.card__more {
  color: #e06116;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 8px
}

@media screen and (min-width:68.75em) {
  .card__more {
      font-size: 18px;
      margin-top: 26px
  }
}

.card__meta {
  margin-bottom: 0;
  margin-top: 0;
  padding: 14px 14px 0
}

@media screen and (min-width:68.75em) {
  .card__meta {
      font-size: 14px;
      padding: 34px 34px 0
  }
}

.card__detail {
  white-space: nowrap
}

.card__detail:not(:last-child) {
  margin-right: 15px
}

.card__detail--strong {
  color: #47bdc5
}

.partner {
  max-width: 1440px
}

.partner--right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

.partner--center {
  background: #fbfcfd;
  text-align: center
}

.partner--center .partner__content {
  padding: 90px 15px
}

@media screen and (min-width:68.75em) {
  .partner--center .partner__content {
      padding: 140px 15px
  }
}

.partner--center .partner__content-container {
  margin-left: auto;
  margin-right: auto
}

@media screen and (min-width:56.3125em) {
  .partner--center .partner__content-container {
      max-width: 620px
  }
}

.partner__content, .partner__img {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

@media screen and (max-width:56.25em) {
  .partner__content, .partner__img {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }
}

@media screen and (max-width:56.25em) {
  .partner__content-container {
      text-align: center
  }
}

.partner:not(.partner--right):not(.partner--center) .partner__content {
  padding: 90px 30px 90px 15px
}

@media screen and (min-width:68.75em) {
  .partner:not(.partner--right):not(.partner--center) .partner__content {
      padding: 140px 100px
  }
}

.partner:not(.partner--right):not(.partner--center) .partner__content-container {
  float: right
}

@media screen and (min-width:56.3125em) {
  .partner:not(.partner--right):not(.partner--center) .partner__content-container {
      max-width: 450px
  }
}

.partner--right .partner__content {
  padding: 90px 15px 90px 30px
}

@media screen and (min-width:68.75em) {
  .partner--right .partner__content {
      padding: 140px 75px
  }
}

@media screen and (min-width:56.3125em) {
  .partner--right .partner__content-container {
      max-width: 500px
  }
}

.partner__img {
  background-position: 50%;
  background-size: cover
}

@media screen and (max-width:56.25em) {
  .partner__img {
      height: 375px
  }
}

.partner__title {
  font-weight: 700
}

.partner__description h1, .partner__description h2, .partner__description h3,
.partner__description h4, .partner__description h5, .partner__description h6,
.partner__description img, .partner__description ol, .partner__description p,
.partner__description ul {
  margin-bottom: 0;
  margin-top: 20px
}

@media screen and (min-width:40em) {
  .partner__description h1, .partner__description h2, .partner__description h3,
  .partner__description h4, .partner__description h5, .partner__description h6,
  .partner__description img, .partner__description ol, .partner__description p,
  .partner__description ul {
      margin-top: 25px
  }
}

@media screen and (min-width:40em) {
  .partner__description img, .partner__description ol, .partner__description p,
  .partner__description ul {
      font-size: 18px
  }
}

.partner__description ol, .partner__description ul {
  display: inline-block
}

.partner__description ol li, .partner__description ul li {
  text-align: left
}

.partner__description img {
  height: auto !important;
  max-width: 100%
}

.partner__description strong {
  font-weight: 700
}

.partner__description a {
  font-weight: 700;
  text-decoration: underline
}

.integration-list {
  padding: 90px 15px 60px
}

@media screen and (min-width:40em) {
  .integration-list {
      padding-bottom: 80px;
      padding-top: 100px
  }
}

.integration-list__container {
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  max-width: 1084px;
  position: relative
}

@media screen and (min-width:68.75em) {
  .integration-list__container:after, .integration-list__container:before {
      border: 1px solid #ececec;
      border-radius: 5px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      content: "";
      height: 130px;
      position: absolute;
      z-index: -1
  }
}

@media screen and (min-width:68.75em) {
  .integration-list__container:before {
      left: 0;
      right: 6px;
      top: -60px
  }
}

@media screen and (min-width:68.75em) {
  .integration-list__container:after {
      left: 6px;
      right: 0;
      top: -54px
  }
}

.integration-list__item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 80px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 30.95px;
  margin: 35px 20px 0;
  position: relative;
  width: 80px
}

@media screen and (min-width:40em) {
  .integration-list__item {
      padding-bottom: 36.14px
  }
}

.integration-list__item:before {
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 40px;
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 20px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  content: "";
  height: 80px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  top: 0;
  width: 80px
}

.integration-list__item svg {
  position: relative;
  z-index: 1
}

.integration-list__item--cart svg {
  padding-right: 5px
}

.integration-list__item--secure svg {
  padding-left: 5px;
  padding-top: 5px
}

.integration-list__title {
  bottom: 0;
  color: #2a2a2a;
  left: 50%;
  margin: 0 0 0 -75px;
  position: absolute;
  text-align: center;
  width: 150px
}

@media screen and (min-width:40em) {
  .integration-list__title {
      font-size: 18px
  }
}

.map {
  position: relative
}

@media screen and (min-width:40em) {
  .map {
      min-height: 560px
  }
}

@media screen and (min-width:68.75em) {
  .map {
      min-height: 680px
  }
}

.map__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  padding-left: 15px;
  padding-right: 15px
}

@media screen and (min-width:40em) {
  .map__container {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      padding-top: 120px
  }
}

@media screen and (min-width:68.75em) {
  .map__container {
      padding-top: 155px
  }
}

.map__title {
  color: #0a4a6e;
  font-weight: 700
}

.map__details, .map__title {
  position: relative;
  z-index: 1
}

.map__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px
}

@media screen and (max-width:39.9375em) {
  .map__details {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-bottom: 80px;
      width: 100%
  }
}

.map__bg {
  background-color: #eee;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

@media screen and (max-width:39.9375em) {
  .map__bg {
      display: none
  }
}

@media screen and (min-width:40em) {
  .map__card--small-only {
      display: none
  }
}

.map__card--with-bg {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 53px rgba(0, 0, 0, .06);
  box-shadow: 0 0 53px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 400px;
  padding: 15px 15px 20px 20px
}

@media screen and (max-width:39.9375em) {
  .map__card--with-bg {
      width: 100%
  }
}

@media screen and (max-width:39.9375em) {
  .map__card--with-bg + .map__card--with-bg {
      margin-top: 15px
  }
}

@media screen and (min-width:40em) {
  .map__card--with-bg + .map__card--with-bg {
      margin-left: 4px
  }
}

.map__card--hq .map__card-type {
  color: #e06116;
  font-size: 14px;
  line-height: 26px;
  margin-top: 3px;
  text-transform: none
}

.map__card-icon {
  margin-left: -2px
}

.map__card-address, .map__card-name, .map__card-type {
  font-family: MarkPro, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  margin-bottom: 0;
  margin-top: 0
}

.map__card-type {
  color: #44ba91;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  margin-top: 10px
}

.map__card-name {
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px
}

.map__card-address {
  color: #8e939e;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px
}

.map__card-list {
  margin-top: 26px
}

.map__card-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.map__card-key, .map__card-val {
  line-height: 26px
}

.map__card-key {
  color: #0a4a6e;
  font-weight: 700;
  width: 75px
}

.map__card-val {
  margin-bottom: 0;
  margin-top: 0
}

.si-float-wrapper {
  position: absolute;
  width: 100%
}

.si-float-wrapper, .si-float-wrapper * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

[class *=si-wrapper] {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  cursor: default
}

.si-wrapper-top {
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: -20px;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%)
}

.si-wrapper-bottom, .si-wrapper-top {
  -webkit-box-orient: vertical;
  margin-left: 0
}

.si-wrapper-bottom {
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  margin-top: 0;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.si-wrapper-left {
  margin-left: -11px;
  margin-top: -20px;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%)
}

.si-wrapper-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-left: 11px;
  margin-top: -20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

[class *=si-shadow-wrapper] {
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  opacity: .29804;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1
}

.si-shadow-wrapper-bottom, .si-shadow-wrapper-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.si-shadow-pointer-bottom, .si-shadow-pointer-right {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1
}

.si-shadow-frame {
  -webkit-box-shadow: 0 0 53px rgba(0, 0, 0, .06);
  box-shadow: 0 0 53px rgba(0, 0, 0, .06)
}

[class *=si-shadow-pointer] {
  position: relative;
  height: 7px;
  margin: auto;
  width: 7px
}

[class *=si-shadow-inner-pointer] {
  -webkit-box-shadow: 0 0 53px rgba(0, 0, 0, .06);
  box-shadow: 0 0 53px rgba(0, 0, 0, .06);
  height: 141%;
  position: absolute;
  width: 141%
}

.si-shadow-inner-pointer-top {
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg)
}

.si-shadow-inner-pointer-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%) rotate(-45deg);
  transform: translate(-50%, 50%) rotate(-45deg)
}

.si-shadow-inner-pointer-left {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg)
}

.si-shadow-inner-pointer-right {
  right: 0;
  top: 50%;
  -webkit-transform: translate(50%, -50%) rotate(-45deg);
  transform: translate(50%, -50%) rotate(-45deg)
}

.si-frame {
  border-radius: 3px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  z-index: 2
}

.si-content-wrapper {
  background-color: #fff;
  max-height: 100%;
  max-width: 100%;
  padding: 15px 15px 20px 20px;
  width: 100%
}

.si-has-border .si-content-wrapper {
  border: 1px solid #ededed
}

.si-content {
  overflow: visible
}

.si-close-button {
  display: none
}

.si-close-button:focus, .si-close-button:hover {
  opacity: .7
}

[class *=si-pointer-border] {
  border: 7px solid transparent;
  position: absolute;
  z-index: 3
}

[class *=si-pointer-bg] {
  border: 7px solid transparent;
  position: relative;
  z-index: 4
}

.si-has-border [class *=si-pointer-bg] {
  border-width: 7px
}

.si-pointer-border-bottom, .si-pointer-border-top {
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%)
}

.si-pointer-border-left, .si-pointer-border-right {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.si-pointer-top {
  border-bottom: 0
}

.si-pointer-border-top {
  border-top-color: #ededed;
  bottom: 0;
  margin-left: 50px
}

.si-pointer-bg-top {
  border-top-color: #fff;
  border-width: 7px;
  margin-left: 100px
}

.si-has-border .si-pointer-bg-top {
  margin-bottom: 0;
  top: -1px
}

.si-pointer-bottom {
  border-top: 0
}

.si-pointer-border-bottom {
  border-bottom-color: #ededed;
  top: 0
}

.si-pointer-bg-bottom {
  border-bottom-color: #fff
}

.si-has-border .si-pointer-bg-bottom {
  bottom: -1px;
  margin-top: 0
}

.si-pointer-left {
  border-right: 0
}

.si-pointer-border-left {
  border-left-color: #ededed;
  right: 0
}

.si-pointer-bg-left {
  border-left-color: #fff
}

.si-has-border .si-pointer-bg-left {
  left: -1px;
  margin-right: 0
}

.si-pointer-right {
  border-left: 0
}

.si-pointer-border-right {
  left: 0;
  border-right-color: #ededed
}

.si-pointer-bg-right {
  border-right-color: #fff
}

.si-has-border .si-pointer-bg-right {
  margin-left: 0;
  right: -1px
}

.document-list {
  background: #24719d;
  padding-bottom: 45px;
  padding-top: 80px
}

@media screen and (min-width:50.0625em) {
  .document-list {
      padding-bottom: 30px;
      padding-top: 30px
  }
}

.document-list--investor {
  padding-bottom: 80px;
  padding-top: 40px
}

@media screen and (min-width:50.0625em) {
  .document-list--investor {
      padding-bottom: 60px;
      padding-top: 50px
  }
}

.document-list--investor .document-list__description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.document-list--investor .document-list__list {
  padding-top: 45px
}

@media screen and (max-width:50em) {
  .document-list--investor .document-list__list {
      padding-top: 40px
  }
}

.document-list__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.document-list__header, .document-list__list, .document-list__testimonial {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

@media screen and (max-width:50em) {
  .document-list__header, .document-list__list, .document-list__testimonial {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
  }
}

.document-list__header {
  text-align: center
}

.document-list__header-title {
  color: #fff;
  font-weight: 700
}

.document-list__list {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 15px
}

@media screen and (max-width:50em) {
  .document-list__list {
      padding-top: 60px
  }
}

.document-list__item:not(:first-child) {
  margin-top: 10px
}

.document-list__link {
  display: block;
  background: #3580ab;
  padding: 45px 20px
}

.document-list__link:focus, .document-list__link:hover {
  -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, .1)
}

.document-list__link:focus .document-list__title svg, .document-list__link:hover .document-list__title svg {
  opacity: 1
}

@media screen and (min-width:40em) {
  .document-list__link {
      padding: 35px 40px
  }
}

.document-list__description, .document-list__title {
  max-width: 520px
}

.document-list__title {
  color: #fff
}

.document-list__title svg {
  margin-left: 10px;
  opacity: 0
}

.document-list__description {
  color: #88b3cb;
  margin-bottom: 0;
  margin-top: 0
}

@media screen and (min-width:40em) {
  .document-list__description {
      font-size: 16px
  }
}

.event {
  margin-top: 45px;
  padding: 25px 35px 40px;
  position: relative
}

.event:after, .event:before {
  border: 1px solid #ececec;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  content: "";
  position: absolute
}

.event:before {
  bottom: 6px;
  left: 0;
  right: 6px;
  top: 0
}

.event:after {
  bottom: 0;
  left: 6px;
  right: 0;
  top: 6px
}

.event__button, .event__date, .event__title {
  position: relative;
  z-index: 1
}

.event__title {
  font-weight: 700;
  margin-top: 25px;
  text-transform: uppercase
}

.event__date {
  color: #0a4a6e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around
}

.event__day, .event__time {
  margin-top: 20px;
  margin-bottom: 0
}

.event__rule {
  width: 1px;
  height: 1px;
  margin-top: 20px;
  position: relative
}

.event__rule:after {
  background: #0a4a6e;
  content: "";
  height: 24.68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px
}

.event__button {
  margin-top: 35px
}

.newsletter {
  padding-bottom: 80px;
  padding-top: 70px
}

@media screen and (min-width:40em) {
  .newsletter {
      padding-top: 80px
  }
}

.newsletter__container {
  max-width: 680px;
  text-align: center
}

.newsletter__title {
  font-weight: 700;
  width: 100%
}

.newsletter__description {
  color: #2a2a2a;
  margin-bottom: 0;
  margin-top: 40px;
  width: 100%
}

@media screen and (min-width:40em) {
  .newsletter__description {
      margin-top: 35px
  }
}

.newsletter__form {
  width: 100%
}

.newsletter__form form {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%
}

.newsletter__field {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-top: 20px
}

@media screen and (max-width:39.9375em) {
  .newsletter__field {
      display: block;
      width: 100%
  }
}

@media screen and (min-width:40em) {
  .newsletter__field {
      margin-right: 50px
  }
}

.newsletter__input {
  border: none;
  border-bottom: 1px solid #b5bdca;
  background: transparent;
  color: #0a4a6e;
  line-height: 1.55;
  outline: none;
  padding-bottom: 12.6px;
  padding-top: 12.6px;
  width: 100%
}

.newsletter__input:focus {
  border-color: #0a4a6e
}

.newsletter__input::-webkit-input-placeholder {
  color: #0a4a6e
}

.newsletter__input:-ms-input-placeholder, .newsletter__input::-ms-input-placeholder {
  color: #0a4a6e
}

.newsletter__input::placeholder {
  color: #0a4a6e
}

.newsletter__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fbfcfd inset;
  -webkit-text-fill-color: #0a4a6e !important
}

.newsletter__button {
  display: inline-block
}

@media screen and (max-width:39.9375em) {
  .newsletter__button {
      margin: 40px auto 0
  }
}

.newsletter svg {
  margin-top: 40px;
  width: 100%
}

.fancy-list__list, .fancy-list__title {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto
}

.fancy-list__title {
  font-weight: 700;
  text-transform: uppercase
}

.fancy-list__title:not(:first-of-type) {
  margin-top: 65px
}

.fancy-list__item {
  color: #2a2a2a;
  line-height: 26px;
  margin-top: 25px;
  padding-left: 36px;
  position: relative
}

@media screen and (min-width:40em) {
  .fancy-list__item {
      font-size: 18px
  }

  .fancy-list__item span {
      font-size: 16px
  }
}

.fancy-list__item span {
  color: #8e929e
}

.fancy-list__item svg {
  left: 0;
  position: absolute;
  top: 0
}

.layout {
  margin-top: 100px;
  max-width: 1140px
}

.layout__container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 130px
}

@media screen and (max-width:56.25em) {
  .layout__container {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      margin-bottom: 80px;
      max-width: 100%
  }
}

.option-list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 15px
}

.option-list__item {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 65px;
  width: 340px
}

@media screen and (min-width:50em) {
  .option-list__item {
      display: inline-block
  }
}

@media screen and (min-width:50em) {
  .option-list__item:first-of-type {
      border-right: 1px solid #efefef;
      padding-right: 40px
  }
}

@media screen and (min-width:50em) {
  .option-list__item:last-of-type {
      padding-left: 40px
  }
}

.option-list__button {
  margin-top: 25px
}

.integrate-buttons {
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 480px;
  padding: 20px 45px 50px
}

@media screen and (min-width:40em) {
  .integrate-buttons {
      padding: 30px 55px 60px
  }
}

.integrate-buttons__title {
  color: #8e929e;
  text-align: center
}

@media screen and (min-width:40em) {
  .integrate-buttons__title {
      font-size: 16px
  }
}

.integrate-buttons__list {
  margin-bottom: 0;
  margin-top: 0
}

.integrate-buttons__item {
  margin-top: 15px
}

.integrate-buttons__item:first-child {
  margin-top: 20px
}

@media screen and (min-width:40em) {
  .integrate-buttons__item {
      margin-top: 20px
  }

  .integrate-buttons__item:first-child {
      margin-top: 30px
  }
}

.integrate-buttons__link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.integrate-buttons__link:focus, .integrate-buttons__link:hover {
  background: #f8f9fb
}

.advanced-media__container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 1070px;
  position: relative
}

@media screen and (max-width:68.6875em) {
  .advanced-media__container {
      text-align: center
  }
}

.advanced-media__content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.advanced-media__title {
  color: #e06116;
  font-weight: 700;
  text-transform: uppercase
}

@media screen and (min-width:40em) {
  .advanced-media__title {
      font-size: 16px
  }
}

.advanced-media__sub-title {
  color: #2a2a2a;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 20px;
  width: 100%
}

.advanced-media__text {
  margin-top: 30px
}

@media screen and (min-width:68.75em) {
  .advanced-media__text--grid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }

  .advanced-media__text--grid span {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      width: 50%
  }

  .advanced-media__text--grid span:first-child h1, .advanced-media__text--grid span:first-child h2,
  .advanced-media__text--grid span:first-child h3, .advanced-media__text--grid span:first-child h4,
  .advanced-media__text--grid span:first-child h5, .advanced-media__text--grid span:first-child h6,
  .advanced-media__text--grid span:first-child p, .advanced-media__text--grid span:nth-child(2) h1,
  .advanced-media__text--grid span:nth-child(2) h2, .advanced-media__text--grid span:nth-child(2) h3,
  .advanced-media__text--grid span:nth-child(2) h4, .advanced-media__text--grid span:nth-child(2) h5,
  .advanced-media__text--grid span:nth-child(2) h6, .advanced-media__text--grid span:nth-child(2) p {
      margin-top: 0
  }

  .advanced-media__text--grid span:nth-child(odd) {
      padding-right: 10px
  }

  .advanced-media__text--grid span:nth-child(2n + 2) {
      padding-left: 10px
  }
}

.advanced-media__text h1, .advanced-media__text h2, .advanced-media__text h3,
.advanced-media__text h4, .advanced-media__text h5, .advanced-media__text h6 {
  margin-top: 25px
}

@media screen and (min-width:40em) {
  .advanced-media__text h1, .advanced-media__text h2, .advanced-media__text h3,
  .advanced-media__text h4, .advanced-media__text h5, .advanced-media__text h6 {
      font-size: 18px;
      margin-top: 35px
  }
}

.advanced-media__text > h1:first-child, .advanced-media__text > h2:first-child,
.advanced-media__text > h3:first-child, .advanced-media__text > h4:first-child,
.advanced-media__text > h5:first-child, .advanced-media__text > h6:first-child,
.advanced-media__text > span:first-child h1:first-child, .advanced-media__text > span:first-child h2:first-child,
.advanced-media__text > span:first-child h3:first-child, .advanced-media__text > span:first-child h4:first-child,
.advanced-media__text > span:first-child h5:first-child, .advanced-media__text > span:first-child h6:first-child {
  margin-top: 0
}

.advanced-media__text p {
  margin-top: 0;
  margin-bottom: 0
}

@media screen and (min-width:40em) {
  .advanced-media__text p {
      font-size: 16px
  }
}

@media screen and (min-width:40em) {
  .advanced-media__text p:only-child {
      font-size: 18px
  }
}

.advanced-media__button {
  display: inline-block;
  margin-top: 30px
}

@media screen and (min-width:40em) {
  .advanced-media__button {
      margin-top: 50px
  }
}

.advanced-media__media {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.advanced-media--cardsecure-1 .advanced-media__container {
  padding-bottom: 560px;
  padding-top: 195px
}

@media screen and (min-width:68.75em) {
  .advanced-media--cardsecure-1 .advanced-media__container {
      padding-bottom: 110px;
      padding-top: 85px
  }
}

.advanced-media--cardsecure-1 .advanced-media__media {
  background-image: url("../img/advanced-media/cardsecure-1-small.png");
  background-size: 100% 100%;
  bottom: 60px;
  height: 463px;
  margin-right: -178px;
  position: absolute;
  right: 50%;
  width: 356px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .advanced-media--cardsecure-1 .advanced-media__media {
      background-image: url("../img/advanced-media/cardsecure-1-small@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .advanced-media--cardsecure-1 .advanced-media__media {
      background-image: url("../img/advanced-media/cardsecure-1.png");
      bottom: auto;
      height: 523px;
      margin-right: auto;
      right: 0;
      top: 75px;
      width: 402px
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .advanced-media--cardsecure-1 .advanced-media__media {
      background-image: url("../img/advanced-media/cardsecure-1@2x.png")
  }
}

@media screen and (min-width:76.5625em) {
  .advanced-media--cardsecure-1 .advanced-media__media {
      right: -60px
  }
}

.advanced-media--cardsecure-2 {
  background: #fbfcfd
}

.advanced-media--cardsecure-2 .advanced-media__container {
  padding-bottom: 390px;
  padding-top: 70px
}

@media screen and (min-width:68.75em) {
  .advanced-media--cardsecure-2 .advanced-media__container {
      padding-bottom: 80px;
      padding-top: 90px
  }
}

.advanced-media--cardsecure-2 .advanced-media__media {
  background-image: url("../img/advanced-media/cardsecure-2.png");
  background-size: 100% 100%;
  bottom: 50px;
  height: 304px;
  margin-left: -207px;
  position: absolute;
  left: 50%;
  width: 415px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .advanced-media--cardsecure-2 .advanced-media__media {
      background-image: url("../img/advanced-media/cardsecure-2@2x.png")
  }
}

@media screen and (min-width:68.75em) {
  .advanced-media--cardsecure-2 .advanced-media__media {
      bottom: auto;
      margin-left: auto;
      left: 0;
      top: 120px
  }
}

@media screen and (min-width:76.5625em) {
  .advanced-media--cardsecure-2 .advanced-media__media {
      left: -60px
  }
}

.advanced-media--cardsecure-3 .advanced-media__container {
  padding-bottom: 70px;
  padding-top: 70px
}

@media screen and (min-width:68.75em) {
  .advanced-media--cardsecure-3 .advanced-media__container {
      padding-bottom: 80px;
      padding-top: 140px
  }
}

@media screen and (min-width:39.375em) {
  .advanced-media--cardsecure-3 .advanced-media__media {
      margin-top: 55px
  }
}

@media screen and (min-width:68.75em) {
  .advanced-media--cardsecure-3 .advanced-media__media {
      margin-top: -55px;
      padding-left: 0
  }
}

.cardsecure-banner {
  padding-bottom: 170px;
  padding-top: 150px;
  background-color: #e06116;
  background-image: url("../img/banner/cardsecure.png"), -webkit-gradient(linear, right top, left top, from(#e06116), to(#d05e5e));
  background-image: url("../img/banner/cardsecure.png"), linear-gradient(270deg, #e06116, #d05e5e);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1245px 358px, 100% 100%
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .cardsecure-banner {
      background-image: url("../img/banner/cardsecure@2x.png"), -webkit-gradient(linear, right top, left top, from(#e06116), to(#d05e5e));
      background-image: url("../img/banner/cardsecure@2x.png"), linear-gradient(270deg, #e06116, #d05e5e)
  }
}

@media screen and (min-width:40em) {
  .cardsecure-banner {
      background-position: 0;
      padding-bottom: 180px;
      padding-top: 140px
  }
}

.cardsecure-banner__container {
  max-width: 1040px
}

.cardsecure-banner__title {
  color: #fff;
  font-weight: 700;
  line-height: 1.3;
  max-width: 720px;
  position: relative
}

@media screen and (max-width:68.6875em) {
  .cardsecure-banner__title {
      margin-left: auto;
      margin-right: auto;
      text-align: center
  }
}

.cardsecure-banner__title svg {
  display: block;
  margin-bottom: 30px
}

@media screen and (max-width:68.6875em) {
  .cardsecure-banner__title svg {
      margin-left: auto;
      margin-right: auto
  }
}

.cardsecure-banner__title svg path {
  fill: #fff
}

.cardsecure-banner__title:after {
  background: #fff;
  bottom: -40px;
  content: "";
  display: block;
  height: 6px;
  left: 50%;
  margin-left: -80px;
  position: absolute;
  width: 160px
}

@media screen and (min-width:68.75em) {
  .cardsecure-banner__title:after {
      bottom: -30px;
      left: 0;
      margin-left: auto
  }
}

.cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.cards__card {
  width: 300px
}

.cards__card--flip {
  min-height: 380px;
  -webkit-perspective: 1000px;
  perspective: 1000px
}

.cards__card--flip:hover .cards__container {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg)
}

.cards__card--flip .cards__face {
  background-color: #fff;
  border: 1px solid #ededed;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  left: 0;
  min-height: 380px;
  position: absolute;
  top: 0
}

.cards__card--flip .cards__face:after, .cards__card--flip .cards__face:before {
  content: "";
  background-color: #d8d8d8;
  border-radius: 7px;
  bottom: 25px;
  height: 14px;
  left: 50%;
  position: absolute;
  width: 14px
}

.cards__card--flip .cards__face:before {
  margin-left: -22px
}

.cards__card--flip .cards__face:after {
  margin-left: 8px
}

.cards__card--flip .cards__face:first-child {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  z-index: 2
}

.cards__card--flip .cards__face:first-child:before {
  background-color: #de5e00
}

.cards__card--flip .cards__face:last-child {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg)
}

.cards__card--flip .cards__face:last-child:after {
  background-color: #de5e00
}

.cards__card--flipped .cards__container {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg)
}

.cards__card--flipped:hover .cards__container {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg)
}

.cards__container {
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: .4s;
  transition: .4s
}

.cards__face {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 300px;
  padding: 55px 25px 65px;
  text-align: center;
  width: 100%
}

.cards__title {
  color: #e06116;
  font-weight: 700;
  text-transform: uppercase
}

@media screen and (min-width:40em) {
  .cards__title {
      font-size: 16px
  }
}

.cards__sub-title {
  margin-top: 15px
}

.cards__list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;
  text-align: left
}

.cards__item {
  margin-top: 20px;
  padding-left: 24px;
  position: relative
}

.cards__item:first-child {
  margin-top: 25px
}

.cards__item:after, .cards__item:before {
  content: "";
  display: block;
  position: absolute
}

.cards__item:before {
  background: hsla(22, 96%, 70%, .2);
  border-radius: 8px;
  height: 16px;
  left: 0;
  top: 5.84px;
  width: 16px
}

.cards__item:after {
  background: #fca16b;
  border-radius: 4px;
  height: 8px;
  left: 4px;
  top: 9.84px;
  width: 8px
}

.features-block {
  padding-bottom: 90px;
  padding-top: 90px
}

.features-block + .features-block {
  padding-top: 0
}

.features-block__container {
  max-width: 1040px;
  text-align: center
}

.features-block__title {
  font-weight: 700;
  width: 100%
}

.features-block__description {
  margin-bottom: 0;
  margin-top: 30px;
  width: 100%
}

@media screen and (min-width:40em) {
  .features-block__description {
      font-size: 18px
  }
}

.features-block__description p {
  font-size: 100%;
  margin-bottom: 0;
  margin-top: 0
}

.features-block__description p + p {
  margin-top: 30px
}

.features-block__grid {
  width: 100%
}

.features-block__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 35px;
  text-align: left
}

@media screen and (min-width:40em) {
  .features-block__item h1, .features-block__item h2, .features-block__item h3,
  .features-block__item h4, .features-block__item h5, .features-block__item h6 {
      font-size: 18px
  }
}

.features-block__item p {
  margin-bottom: 0;
  margin-top: 0
}

@media screen and (min-width:40em) {
  .features-block__item p {
      font-size: 16px
  }
}

.features-block__item a {
  font-weight: 700;
  text-decoration: underline
}

.lightbox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, .8);
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  -webkit-transition: opacity .25s ease;
  transition: opacity .25s ease;
  top: 0;
  z-index: 5
}

.lightbox--hidden {
  opacity: 0
}

.lightbox--removed {
  display: none
}

.lightbox--error .lightbox__title {
  color: #bc1d32
}

.lightbox--success .lightbox__title {
  color: #44ba91
}

.lightbox__container {
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 100%;
  max-width: 520px;
  overflow: scroll;
  padding: 25px 35px 45px;
  position: relative;
  text-align: center;
  width: 100%
}

@media screen and (min-width:32.5em) {
  .lightbox__container {
      border-radius: 3px
  }
}

.lightbox__description, .lightbox__title {
  font-size: 22px;
  font-weight: 700
}

.lightbox__title {
  margin-bottom: 20px;
  margin-top: 15px
}

.lightbox__description {
  color: #0a4a6e;
  margin-bottom: 15px;
  margin-top: 0
}

.lightbox__content ol, .lightbox__content p, .lightbox__content ul {
  color: #0a4a6e;
  margin: 0 auto;
  max-width: 390px
}

.lightbox__content ol:not(:first-child), .lightbox__content p:not(:first-child),
.lightbox__content ul:not(:first-child) {
  margin-top: 20px
}

.lightbox__content ol, .lightbox__content ul {
  display: inline-block
}

.lightbox__content ol li, .lightbox__content ul li {
  text-align: left
}

.lightbox__close {
  position: absolute;
  right: 15px;
  top: 15px
}

.lightbox__close svg path {
  fill: #8e939e
}

.lightbox__close:focus svg path, .lightbox__close:hover svg path {
  fill: #676d78
}

.lightbox--leadership .lightbox__container {
  max-width: 700px;
  padding: 0
}

.full-image {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  padding: 80px 15px;
  width: 100%
}

.full-image__img {
  height: auto;
  width: 100%
}

.videos {
  background-color: #4060ba;
  background-image: linear-gradient(65deg, #644a9c, #4060ba 45%, #2471d0);
  padding-bottom: 60px;
  padding-top: 60px
}

@media screen and (min-width:40em) {
  .videos {
      padding-bottom: 102px;
      padding-top: 92px
  }
}

.videos__container {
  max-width: 1040px;
  position: relative
}

.videos__content {
  width: 100%
}

@media screen and (max-width:68.6875em) {
  .videos__content {
      text-align: center
  }
}

@media screen and (min-width:68.75em) {
  .videos__content {
      width: 44.2%
  }
}

.videos__title {
  color: #fff;
  line-height: 1.3;
  margin-top: 20px
}

.videos__description {
  color: #fff;
  margin: 20px auto 0;
  max-width: 378px
}

@media screen and (min-width:40em) {
  .videos__description {
      font-size: 18px
  }
}

@media screen and (min-width:68.75em) {
  .videos__description {
      margin-left: 0
  }
}

.videos__list {
  margin-bottom: 0;
  margin-top: 27px
}

@media screen and (max-width:68.6875em) {
  .videos__list {
      margin-left: auto;
      margin-right: auto;
      max-width: 460px
  }
}

.videos__item:not(:first-of-type) {
  margin-top: 10px
}

.videos__item--active .videos__link {
  background: rgba(46, 46, 46, .42)
}

.videos__link {
  background: transparent;
  border: none;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  padding: 0;
  padding-left: 57px;
  position: relative;
  height: 55px;
  text-align: left;
  width: 100%
}

@media screen and (min-width:40em) {
  .videos__link {
      font-size: 18px
  }
}

@media screen and (min-width:68.75em) {
  .videos__link {
      border-bottom-right-radius: none;
      border-top-right-radius: none
  }
}

.videos__link:focus, .videos__link:hover {
  background: rgba(46, 46, 46, .42)
}

.videos__link:after, .videos__link:before {
  content: "";
  position: absolute
}

.videos__link:before {
  width: 18px;
  height: 18px;
  background: hsla(0, 0%, 100%, .2);
  border-radius: 9px;
  left: 20px;
  top: 19px
}

.videos__link:after {
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 3px;
  left: 26px;
  top: 25px
}

.videos__player {
  width: 780px
}

@media screen and (max-width:68.6875em) {
  .videos__player {
      margin: 40px auto 0;
      max-width: 100%
  }
}

@media screen and (min-width:68.75em) {
  .videos__player {
      left: 44.2%;
      position: absolute;
      top: 22px
  }
}

.videos__chrome {
  background: #e4e4e4;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 30px;
  width: 100%
}

.videos__button {
  border-radius: 50%;
  float: left;
  height: 10px;
  margin-left: 6px;
  margin-top: 10px;
  width: 10px
}

.videos__button:first-of-type {
  margin-left: 13px
}

.videos__button--red {
  background-color: #f47875
}

.videos__button--orange {
  background-color: #faa041
}

.videos__button--green {
  background-color: #44ba91
}

.videos__ratio {
  background: #323232;
  height: 0;
  padding-bottom: 81.3%;
  position: relative;
  width: 100%
}

.videos__video {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.videos__loader {
  height: 50%;
  left: 50%;
  margin-left: -22px;
  margin-top: -22px;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: all .25s ease;
  transition: all .25s ease
}

.videos__loader svg {
  -webkit-animation: pulse 1s infinite cubic-bezier(0, .3, .9, .1);
  animation: pulse 1s infinite cubic-bezier(0, .3, .9, .1)
}

.videos--loading .videos__video {
  opacity: 0
}

.videos--loading .videos__loader {
  opacity: 1
}

@-webkit-keyframes pulse {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  50% {
      -webkit-transform: scale(2);
      transform: scale(2)
  }

  to {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes pulse {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  50% {
      -webkit-transform: scale(2);
      transform: scale(2)
  }

  to {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

.social-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  width: 30px
}

.social-icon--facebook {
  background-color: #39579a
}

.social-icon--facebook:focus, .social-icon--facebook:hover {
  background-color: #2b4275
}

.social-icon--twitter {
  background-color: #059ff5
}

.social-icon--twitter:focus, .social-icon--twitter:hover {
  background-color: #047fc3
}

.social-icon--linkedin {
  background-color: #0072b4
}

.social-icon--linkedin:focus, .social-icon--linkedin:hover {
  background-color: #005281
}

.social-icon--instagram {
  background-color: #8a3ab9
}

.social-icon--instagram:focus, .social-icon--instagram:hover {
  background-color: #6d2e92
}

.checklist__container {
  padding-bottom: 400px;
  padding-top: 238px;
  position: relative
}

@media screen and (min-width:68.75em) {
  .checklist__container {
      padding-bottom: 273px;
      padding-top: 68px
  }
}

.checklist__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 470px;
  width: 100%
}

@media screen and (min-width:68.75em) {
  .checklist__list {
      opacity: 0;
      padding-left: 60px;
      -webkit-transition: opacity 1s ease-out;
      transition: opacity 1s ease-out
  }
}

.checklist__list.checklist__list--show {
  opacity: 1
}

.checklist__image {
  position: absolute;
  display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat
}

.checklist__image--macbook {
  background-image: url(../images/checklist/macbook-small.png);
  height: 194px;
  left: 50%;
  margin-left: -166px;
  top: 30px;
  width: 332px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .checklist__image--macbook {
      background-image: url(../images/checklist/macbook-small@2x.png)
  }
}

@media screen and (min-width:68.75em) {
  .checklist__image--macbook {
      background-image: url(../images/checklist/macbook.png);
      margin-left: -369.5px;
      top: 80px;
      height: 432px;
      -webkit-transition: -webkit-transform 1s ease-in-out 1s;
      transition: -webkit-transform 1s ease-in-out 1s;
      transition: transform 1s ease-in-out 1s;
      transition: transform 1s ease-in-out 1s, -webkit-transform 1s ease-in-out 1s;
      width: 739px
  }

  .checklist__image--macbook.checklist__image--show {
      -webkit-transform: translateX(-560px);
      transform: translateX(-560px)
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .checklist__image--macbook {
      background-image: url(../images/checklist/macbook@2x.png)
  }
}

.checklist__image--ipad {
  background-image: url(../images/checklist/ipad-small.png);
  height: 329px;
  margin-right: -194.5px;
  right: 50%;
  bottom: 30px;
  width: 389px
}

@media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
  .checklist__image--ipad {
      background-image: url(../images/checklist/ipad-small@2x.png)
  }
}

@media screen and (min-width:68.75em) {
  .checklist__image--ipad {
      background-image: url(../images/checklist/ipad.png);
      height: 419px;
      margin-right: -748.5px;
      opacity: 0;
      top: 30px;
      -webkit-transform: scale(.75);
      transform: scale(.75);
      width: 497px
  }

  .checklist__image--ipad.checklist__image--show {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
      transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
      transition: opacity .2s ease-out, transform .2s ease-out;
      transition: opacity .2s ease-out, transform .2s ease-out, -webkit-transform .2s ease-out
  }
}

@media screen and (min-width:68.75em) and (-webkit-min-device-pixel-ratio:2), screen and (min-width:68.75em) and (min-resolution:192dpi) {
  .checklist__image--ipad {
      background-image: url(../images/checklist/ipad@2x.png)
  }
}

.checklist__item {
  color: #2a2a2a;
  line-height: 47px;
  padding-left: 55px;
  padding-right: 10px;
  position: relative;
  height: 47px;
  -webkit-transition: background .35s cubic-bezier(.175, .885, .32, 1.275), -webkit-box-shadow .35s cubic-bezier(.175, .885, .32, 1.275);
  transition: background .35s cubic-bezier(.175, .885, .32, 1.275), -webkit-box-shadow .35s cubic-bezier(.175, .885, .32, 1.275);
  transition: background .35s cubic-bezier(.175, .885, .32, 1.275), box-shadow .35s cubic-bezier(.175, .885, .32, 1.275);
  transition: background .35s cubic-bezier(.175, .885, .32, 1.275), box-shadow .35s cubic-bezier(.175, .885, .32, 1.275), -webkit-box-shadow .35s cubic-bezier(.175, .885, .32, 1.275)
}

@media screen and (min-width:68.75em) {
  .checklist__item {
      font-size: 18px;
      height: 60px;
      line-height: 60px;
      padding-left: 75px
  }
}

.checklist__item:not(:first-child) {
  margin-top: 16px
}

.checklist__item .checklist__icon, .checklist__item:after, .checklist__item:before {
  border-radius: 13px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 26px;
  left: 15px;
  margin-top: -13px;
  position: absolute;
  top: 50%;
  width: 26px
}

@media screen and (min-width:68.75em) {
  .checklist__item .checklist__icon, .checklist__item:after, .checklist__item:before {
      border-radius: 17px;
      height: 34px;
      left: 19px;
      margin-top: -17px;
      width: 34px
  }
}

.checklist__item:before {
  border: 1px solid #ececec
}

.checklist__item:after {
  background: #018001;
  -webkit-box-shadow: 0 2px 14px rgba(0, 0, 0, .22);
  box-shadow: 0 2px 14px rgba(0, 0, 0, .22);
  opacity: 1;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: opacity .35s cubic-bezier(.175, .885, .32, 1.275), -webkit-transform .35s cubic-bezier(.175, .885, .32, 1.275);
  transition: opacity .35s cubic-bezier(.175, .885, .32, 1.275), -webkit-transform .35s cubic-bezier(.175, .885, .32, 1.275);
  transition: opacity .35s cubic-bezier(.175, .885, .32, 1.275), transform .35s cubic-bezier(.175, .885, .32, 1.275);
  transition: opacity .35s cubic-bezier(.175, .885, .32, 1.275), transform .35s cubic-bezier(.175, .885, .32, 1.275), -webkit-transform .35s cubic-bezier(.175, .885, .32, 1.275)
}

.checklist__item .checklist__icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: opacity .2s ease-out .2s, -webkit-transform .2s ease-out .2s;
  transition: opacity .2s ease-out .2s, -webkit-transform .2s ease-out .2s;
  transition: opacity .2s ease-out .2s, transform .2s ease-out .2s;
  transition: opacity .2s ease-out .2s, transform .2s ease-out .2s, -webkit-transform .2s ease-out .2s;
  z-index: 2
}

.checklist__item svg {
  height: auto;
  width: 16px
}

@media screen and (min-width:68.75em) {
  .checklist__item svg {
      width: 21px
  }
}

.checklist__item--checked {
  background: #fff;
  border-radius: 30px;
  -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, .14);
  box-shadow: 0 2px 9px rgba(0, 0, 0, .14)
}

.checklist__item--checked .checklist__icon, .checklist__item--checked:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.subscribe {
  background: #fff;
  -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
  margin-top: 10px;
  padding: 14px
}

@media screen and (min-width:68.75em) {
  .subscribe {
      margin-top: 20px;
      padding: 27px 20px
  }
}

.subscribe__title {
  font-weight: 700
}

.subscribe__description {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 14px
}

.subscribe__button, .subscribe__input {
  max-width: 340px
}

.subscribe__input {
  border: 1px solid #efefef;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #2a2a2a;
  margin-top: 8px;
  outline: none;
  padding: 15px;
  width: 100%
}

.subscribe__input::-webkit-input-placeholder {
  color: #c5c7ce
}

.subscribe__input:-ms-input-placeholder, .subscribe__input::-ms-input-placeholder {
  color: #c5c7ce
}

.subscribe__input::placeholder {
  color: #c5c7ce
}

.subscribe__button {
  margin-top: 10px;
  width: 100%
}

.subscribe--large {
  text-align: center;
  width: 100%
}

@media screen and (min-width:68.75em) {
  .subscribe--large {
      padding: 37px 20px
  }
}

.subscribe--large .subscribe__title {
  color: #0a4a6e;
  font-size: 20px
}

@media screen and (min-width:68.75em) {
  .subscribe--large .subscribe__title {
      font-size: 28px
  }
}

.subscribe--large .subscribe__button, .subscribe--large .subscribe__input {
  margin-left: auto;
  margin-right: auto;
  max-width: 390px
}

.subscribe--large .subscribe__input {
  margin-top: 20px
}

.tags {
  background: #fff;
  -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
  padding: 14px;
  position: relative
}

@media screen and (max-width:68.6875em) {
  .tags {
      cursor: pointer
  }
}

@media screen and (min-width:68.75em) {
  .tags {
      padding: 27px 20px
  }
}

@media screen and (max-width:68.6875em) {
  .tags--hide .tags__list {
      border-top: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      max-height: 0;
      padding-bottom: 0;
      padding-top: 0
  }

  .tags--hide .tags__title:before {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  .tags--hide .tags__title:after {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg)
  }
}

.tags__title {
  color: #555a6a;
  font-weight: 700;
  position: relative
}

@media screen and (max-width:68.6875em) {
  .tags__title:after, .tags__title:before {
      border-radius: 1px;
      background: #0a4a6e;
      content: "";
      display: block;
      height: 14px;
      margin-top: -7px;
      position: absolute;
      right: 13px;
      top: 50%;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition: -webkit-transform .2s ease-in-out;
      transition: transform .2s ease-in-out;
      transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
      width: 2px
  }

  .tags__title:after {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg)
  }
}

.tags__list {
  margin-bottom: 0;
  margin-top: 0
}

@media screen and (max-width:68.6875em) {
  .tags__list {
      background: #fff;
      border-top: 1px solid #eee;
      -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
      box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      left: 0;
      overflow: hidden;
      padding: 14px 14px 25px;
      position: absolute;
      top: 100%;
      width: 100%
  }
}

.tags__item {
  margin-top: 19px
}

@media screen and (max-width:68.6875em) {
  .tags__item:first-child {
      margin-top: 0
  }
}

.tags__item--active .tags__link {
  background: #dfdede;
  color: #2a2a2a
}

.tags__link {
  background: #f8f8f8;
  border-radius: 3px;
  font-weight: 700;
  padding: 5px 12px
}

@media screen and (min-width:68.75em) {
  .sticky {
      position: relative
  }
}

@media screen and (min-width:68.75em) {
  .sticky__container {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transition: -webkit-transform .2s linear;
      transition: -webkit-transform .2s linear;
      transition: transform .2s linear;
      transition: transform .2s linear, -webkit-transform .2s linear
  }
}

.form-card {
  background: #fff;
  border: 1px solid #ededed;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 50px;
  max-width: 620px;
  padding: 44px 45px 59px
}

@media screen and (max-width:68.6875em) {
  .form-card {
      margin-left: auto;
      margin-right: auto
  }
}

.form-card__title {
  color: #2a2a2a;
  margin-bottom: 0;
  margin-top: 0
}

@media screen and (min-width:40em) {
  .form-card__title {
      font-size: 18px
  }
}

.form-card__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px
}

@media screen and (max-width:68.6875em) {
  .form-card__container {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
  }
}

@media screen and (max-width:32.5em) {
  .form-card__container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column
  }
}

.form-card__input {
  border: 1px solid #efefef;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #2a2a2a;
  margin-right: 5px;
  max-width: 100%;
  outline: none;
  padding: 15px;
  width: 330px
}

@media screen and (max-width:32.5em) {
  .form-card__input {
      width: 100%
  }
}

.form-card__input::-webkit-input-placeholder {
  color: #c5c7ce
}

.form-card__input:-ms-input-placeholder, .form-card__input::-ms-input-placeholder {
  color: #c5c7ce
}

.form-card__input::placeholder {
  color: #c5c7ce
}

.form-card__button {
  -ms-flex-negative: 1;
  flex-shrink: 1
}

@media screen and (max-width:32.5em) {
  .form-card__button {
      margin-top: 10px
  }
}

.video {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, .8);
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5
}

.video:not([data-open=true]) {
  display: none
}

.video__container {
  position: relative
}

.video__iframe {
  border: none;
  display: block;
  max-height: 100%;
  max-width: 100%
}

.video__close {
  background: transparent;
  border: none;
  height: 16px;
  padding: 0;
  position: absolute;
  right: 0;
  top: -26px;
  width: 16px
}

@media screen and (min-width:46.875em) {
  .video__close {
      right: -26px
  }
}

.video__close svg {
  display: block
}

.list-banner {
  background-color: #e06116;
  background-image: -webkit-gradient(linear, right top, left top, from(#e06116), to(#d05e5e));
  background-image: linear-gradient(270deg, #e06116, #d05e5e);
  padding-bottom: 70px;
  padding-top: 60px
}

@media screen and (min-width:40em) {
  .list-banner {
      padding-bottom: 119px;
      padding-top: 111px
  }
}

.list-banner__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center
}

.list-banner__byline {
  color: #fff;
  margin-top: 15px;
  max-width: 620px
}

@media screen and (min-width:40em) {
  .list-banner__byline {
      margin-top: 20px
  }
}

.list-banner__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-top: 5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

@media screen and (min-width:40em) {
  .list-banner__list {
      margin-top: 40px
  }
}

.list-banner__item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 25px
}

@media screen and (max-width:39.9375em) {
  .list-banner__item {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%
  }
}

@media screen and (min-width:40em) {
  .list-banner__item {
      font-size: 18px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-top: 30px
  }
}

@media screen and (max-width:39.9375em) {
  .list-banner__item svg {
      margin-right: 15px
  }
}

@media screen and (min-width:40em) {
  .list-banner__item svg {
      margin-bottom: 11px
  }
}

.features-banner {
  background: #272838;
  padding-bottom: 70px;
  padding-top: 60px
}

@media screen and (min-width:40em) {
  .features-banner {
      padding-bottom: 70px;
      padding-top: 88px
  }
}

.features-banner__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center
}

.features-banner__byline, .features-banner__title {
  color: #fff
}

.features-banner__row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative
}

@media screen and (max-width:39.9375em) {
  .features-banner__row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column
  }
}

@media screen and (min-width:68.75em) {
  .features-banner__row:after {
      content: "";
      bottom: 40px;
      top: 140px;
      left: 50%;
      width: 1px;
      position: absolute;
      background: #47495b
  }
}

.features-banner__column {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 50px;
  width: 100%
}

@media screen and (max-width:39.9375em) {
  .features-banner__column {
      max-width: 280px
  }

  .features-banner__column + .features-banner__column {
      border-top: 1px solid #47495b;
      padding-top: 50px
  }
}

@media screen and (min-width:40em) {
  .features-banner__column {
      margin-top: 60px
  }
}

@media screen and (min-width:68.75em) {
  .features-banner__column {
      max-width: 50%
  }
}

@media screen and (min-width:68.75em) {
  .features-banner__column:first-child {
      padding-right: 8%
  }
}

@media screen and (min-width:68.75em) {
  .features-banner__column:last-child {
      padding-left: 8%
  }
}

.features-banner__column-title {
  color: #ff842a;
  font-weight: 700;
  text-transform: uppercase
}

.features-banner__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-top: 0
}

.features-banner__item {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px
}

@media screen and (max-width:39.9375em) {
  .features-banner__item {
      max-width: 130px
  }
}

@media screen and (max-width:39.9375em) {
  .features-banner__item:nth-child(odd) {
      padding-right: 10px
  }
}

@media screen and (max-width:39.9375em) {
  .features-banner__item:nth-child(2n + 2) {
      padding-left: 10px
  }
}

.features-banner__item-byline, .features-banner__item-title {
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 130px
}

@media screen and (min-width:40em) {
  .features-banner__item-byline, .features-banner__item-title {
      max-width: 200px
  }
}

.features-banner__item-title {
  font-weight: 700;
  margin-top: 16px
}

@media screen and (min-width:40em) {
  .features-banner__item-title {
      font-size: 18px
  }
}

.features-banner__item-byline {
  margin-bottom: 0;
  margin-top: 10px
}

@media screen and (min-width:40em) {
  .features-banner__item-byline {
      font-size: 16px
  }
}

.more-features {
  background: #fbfcfd;
  padding-bottom: 70px;
  padding-top: 60px
}

@media screen and (min-width:40em) {
  .more-features {
      padding-bottom: 106px;
      padding-top: 106px
  }
}

.more-features__container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  padding-left: 15px;
  padding-right: 15px
}

@media screen and (min-width:68.75em) {
  .more-features__container {
      max-width: 1040px
  }
}

.more-features__header-split {
  margin-top: 20px
}

@media screen and (min-width:68.75em) {
  .more-features__header-split {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex
  }
}

.more-features__title {
  color: #e06116;
  font-weight: 700;
  text-transform: uppercase
}

@media screen and (min-width:68.75em) {
  .more-features__title {
      font-size: 16px
  }
}

.more-features__byline, .more-features__sub-title {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  color: #2a2a2a
}

@media screen and (min-width:68.75em) {
  .more-features__sub-title {
      padding-right: 20px
  }
}

.more-features__byline {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 0
}

@media screen and (max-width:68.6875em) {
  .more-features__byline {
      margin-top: 20px;
      max-width: 480px
  }
}

@media screen and (min-width:68.75em) {
  .more-features__byline {
      padding-left: 20px
  }
}

.more-features__list {
  margin-bottom: 0;
  margin-top: 15px
}

@media screen and (min-width:68.75em) {
  .more-features__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }
}

.more-features__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 40px;
  width: 100%
}

@media screen and (min-width:68.75em) {
  .more-features__item {
      max-width: 50%
  }
}

@media screen and (min-width:68.75em) {
  .more-features__item:nth-child(odd) {
      padding-right: 30px
  }
}

@media screen and (min-width:68.75em) {
  .more-features__item:nth-child(2n + 2) {
      padding-left: 30px
  }
}

.more-features__item-content {
  position: relative;
  padding-left: 50px
}

.more-features__item svg {
  float: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0
}

.more-features__item-title {
  margin-top: 0
}

.more-features__item-byline {
  margin-bottom: 0;
  margin-top: 10px
}

.more-features__item--padlock svg {
  left: -4px
}

@media screen and (min-width:68.75em) {
  .more-features__item--padlock svg {
      left: -7px
  }
}

.more-features__item--pci svg {
  left: -7px
}

@media screen and (min-width:68.75em) {
  .more-features__item--pci svg {
      left: -10px
  }
}

.bolt-loop {
  background-color: #fff;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 70px
}

@media screen and (min-width:40em) {
  .bolt-loop {
      padding-top: 121px;
      padding-bottom: 134px
  }
}

.bolt-loop__container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;
  padding-left: 15px;
  padding-right: 15px
}

@media screen and (max-width:53.125em) {
  .bolt-loop__container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 640px
  }
}

.bolt-loop__title {
  color: #e06116;
  font-weight: 700;
  text-transform: uppercase
}

@media screen and (min-width:68.75em) {
  .bolt-loop__title {
      font-size: 16px;
      margin-top: 129px
  }
}

.bolt-loop__sub-title {
  color: #2a2a2a;
  margin-top: 20px
}

.bolt-loop__max-width {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 439px;
  width: 100%
}

@media screen and (max-width:53.125em) {
  .bolt-loop__max-width {
      margin-left: auto;
      margin-right: auto;
      margin-top: 55px;
      padding-left: 15px;
      padding-right: 15px
  }
}

@media screen and (min-width:73.75em) {
  .bolt-loop__max-width {
      position: relative;
      right: -70px
  }
}

.bolt-loop__aspect-ratio {
  padding-bottom: 83.599%;
  position: relative;
  width: 100%
}

.bolt-loop__aspect-ratio .svg--bolt-loop {
  display: block;
  height: 100%;
  left: 0;
  overflow: visible !important;
  position: absolute;
  top: 0;
  width: 100%
}

.bolt-loop__button {
  display: inline-block;
  margin-top: 30px
}

.how-it-works {
  background-color: #fff;
  padding-bottom: 60px;
  padding-top: 70px
}

@media screen and (min-width:73.75em) {
  .how-it-works {
      padding-bottom: 147px;
      padding-top: 120px
  }
}

.how-it-works__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 669px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center
}

.how-it-works__list {
  margin: 0 auto
}

@media screen and (max-width:73.6875em) {
  .how-it-works__list {
      max-width: 270px
  }
}

.how-it-works__item {
  color: #2a2a2a;
  position: relative
}

@media screen and (min-width:73.75em) {
  .how-it-works__item {
      font-size: 24px
  }
}

.how-it-works__item:first-child {
  margin-top: 183px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item:first-child {
      margin-top: 108px
  }
}

.how-it-works__item:nth-child(2) {
  margin-top: 164px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item:nth-child(2) {
      margin-top: 184px
  }
}

.how-it-works__item:nth-child(3) {
  margin-top: 161px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item:nth-child(3) {
      margin-top: 151px
  }
}

.how-it-works__item:nth-child(4) {
  margin-top: 119px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item:nth-child(4) {
      margin-top: 235px
  }
}

.how-it-works__item:nth-child(5) {
  margin-top: 181px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item:nth-child(5) {
      margin-top: 351px
  }
}

.how-it-works__item .svg {
  display: block;
  overflow: visible !important;
  position: absolute;
  width: 100%
}

.how-it-works__item .svg--how-it-works-1, .how-it-works__item .svg--how-it-works-1-icon {
  height: 73px;
  top: -112px
}

@media screen and (max-width:73.6875em) {
  .how-it-works__item .svg--how-it-works-1, .how-it-works__item .svg--how-it-works-1-icon {
      left: 50%;
      margin-left: -110px
  }
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-1, .how-it-works__item .svg--how-it-works-1-icon {
      height: 93px;
      left: -101px;
      top: -21px
  }
}

.how-it-works__item .svg--how-it-works-1-icon {
  width: 73px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-1-icon {
      width: 93px
  }
}

.how-it-works__item .svg--how-it-works-2, .how-it-works__item .svg--how-it-works-2-icon {
  height: 107px;
  top: -130px
}

@media screen and (max-width:73.6875em) {
  .how-it-works__item .svg--how-it-works-2, .how-it-works__item .svg--how-it-works-2-icon {
      left: 50%;
      margin-left: 12px
  }
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-2, .how-it-works__item .svg--how-it-works-2-icon {
      height: 185px;
      left: 100%;
      margin-left: 65px;
      top: -179px
  }
}

.how-it-works__item .svg--how-it-works-2-icon {
  width: 100px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-2-icon {
      width: 173px
  }
}

.how-it-works__item .svg--how-it-works-3, .how-it-works__item .svg--how-it-works-3-icon {
  height: 85px;
  top: -120px
}

@media screen and (max-width:73.6875em) {
  .how-it-works__item .svg--how-it-works-3, .how-it-works__item .svg--how-it-works-3-icon {
      left: 50%;
      margin-left: -99px
  }
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-3, .how-it-works__item .svg--how-it-works-3-icon {
      height: 173px;
      left: -140px;
      top: 95px
  }
}

.how-it-works__item .svg--how-it-works-3-icon {
  width: 96px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-3-icon {
      width: 196px
  }
}

.how-it-works__item .svg--how-it-works-4, .how-it-works__item .svg--how-it-works-4-icon {
  height: 70px;
  top: -90px
}

@media screen and (max-width:73.6875em) {
  .how-it-works__item .svg--how-it-works-4, .how-it-works__item .svg--how-it-works-4-icon {
      left: 50%;
      margin-left: 16px
  }
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-4, .how-it-works__item .svg--how-it-works-4-icon {
      height: 86px;
      left: 100%;
      margin-left: 9px;
      top: -163px
  }
}

.how-it-works__item .svg--how-it-works-4-icon {
  width: 89px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-4-icon {
      width: 109px
  }
}

.how-it-works__item .svg--how-it-works-5, .how-it-works__item .svg--how-it-works-5-icon {
  height: 69px;
  left: 50%;
  margin-left: -50px;
  top: -90px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-5, .how-it-works__item .svg--how-it-works-5-icon {
      height: 119px;
      margin-left: -86px;
      top: -154px
  }
}

.how-it-works__item .svg--how-it-works-5-icon {
  width: 100px
}

@media screen and (min-width:73.75em) {
  .how-it-works__item .svg--how-it-works-5-icon {
      width: 173px
  }
}

.business-type {
  background-color: #fbfcfd;
  padding-bottom: 100px
}

@media screen and (min-width:40em) {
  .business-type {
      padding-bottom: 300px
  }
}

.business-type__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 960px;
  width: 100%
}

.business-type__item {
  margin: 20px 10px 0;
  max-width: 100%;
  width: 300px
}

.business-type__item--orange .business-type__feature, .business-type__item--orange .business-type__title {
  color: #e06116
}

.business-type__item--orange .business-type__link:focus, .business-type__item--orange .business-type__link:hover {
  border-color: #e06116
}

.business-type__item--orange .svg--tick-alt circle {
  fill: #fbeadf;
  stroke: #e06116
}

.business-type__item--orange .svg--tick-alt path {
  fill: #e06116
}

.business-type__item--black .business-type__feature, .business-type__item--black .business-type__title {
  color: #2a2a2a
}

.business-type__item--black .business-type__link:focus, .business-type__item--black .business-type__link:hover {
  border-color: #2a2a2a
}

.business-type__item--black .svg--tick-alt circle {
  fill: #dbdbdb;
  stroke: #2a2a2a
}

.business-type__item--black .svg--tick-alt path {
  fill: #2a2a2a
}

.business-type__item--purple .business-type__feature, .business-type__item--purple .business-type__title {
  color: #896ec5
}

.business-type__item--purple .business-type__link:focus, .business-type__item--purple .business-type__link:hover {
  border-color: #896ec5
}

.business-type__item--purple .svg--tick-alt circle {
  fill: #e5dff2;
  stroke: #896ec5
}

.business-type__item--purple .svg--tick-alt path {
  fill: #896ec5
}

.business-type__item--green .business-type__feature, .business-type__item--green .business-type__title {
  color: #44ba91
}

.business-type__item--green .business-type__link:focus, .business-type__item--green .business-type__link:hover {
  border-color: #44ba91
}

.business-type__item--green .svg--tick-alt circle {
  fill: #e2f4ee;
  stroke: #44ba91
}

.business-type__item--green .svg--tick-alt path {
  fill: #44ba91
}

.business-type__item--blue .business-type__feature, .business-type__item--blue .business-type__title {
  color: #0a4a6e
}

.business-type__item--blue .business-type__link:focus, .business-type__item--blue .business-type__link:hover {
  border-color: #0a4a6e
}

.business-type__item--blue .svg--tick-alt circle {
  fill: #dbe5ea;
  stroke: #0a4a6e
}

.business-type__item--blue .svg--tick-alt path {
  fill: #0a4a6e
}

.business-type__link {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  outline: none;
  padding: 27px 10px 38px;
  text-align: center;
  -webkit-transition: border-color .3s ease-in-out;
  transition: border-color .3s ease-in-out;
  width: 100%
}

.business-type__title {
  font-weight: 600;
  font-size: 26px;
  line-height: 28px
}

.business-type__list {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 19px
}

.business-type__feature {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-top: 14px
}

.business-type__feature .svg {
  display: block;
  margin-right: 13px;
  overflow: visible
}

.pricing {
  background-color: #fbfcfd;
  padding-top: 40px
}

@media screen and (min-width:40em) {
  .pricing {
      padding-top: 62px
  }
}

.pricing__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1100px
}

@media screen and (max-width:68.6875em) {
  .pricing__container {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }
}

.pricing__column {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 600px;
  width: 100%
}

@media screen and (min-width:68.75em) {
  .pricing__column {
      max-width: 50%
  }
}

@media screen and (min-width:68.75em) {
  .pricing__column:first-child {
      margin-right: 10px
  }
}

@media screen and (min-width:68.75em) {
  .pricing__column:last-child {
      margin-left: 10px
  }
}

.pricing__group {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%
}

.pricing__group .svg--pricing-join {
  display: block;
  position: absolute;
  width: 16px;
  height: 7px;
  top: -7px;
  left: 50%;
  margin-left: -8px
}

.pricing__group--black {
  background-color: #2a2a2a;
  border-color: #2a2a2a
}

@media screen and (max-width:68.6875em) {
  .pricing__group--black {
      margin-top: 30px
  }
}

@media screen and (max-width:39.9375em) {
  .pricing__group--black {
      margin-top: 20px
  }
}

.pricing__group--black .pricing__title {
  border-color: hsla(0, 0%, 93%, .11);
  color: #fff
}

.pricing__group--black .pricing__cost-byline, .pricing__group--black .pricing__cost-title,
.pricing__group--black .pricing__cost-value {
  color: #fff
}

.pricing__group--black .pricing__features {
  border-color: hsla(0, 0%, 93%, .11)
}

.pricing__group--black .pricing__features-item {
  color: #fff
}

.pricing__group--black .pricing__features-tick {
  background-color: #e06116;
  background-image: -webkit-gradient(linear, left top, right top, from(#d05e5e), to(#e06116));
  background-image: linear-gradient(90deg, #d05e5e, #e06116)
}

.pricing__group--black .pricing__button {
  background-color: transparent;
  border: 1px solid #b5bdca;
  color: #fff;
  margin-top: 10px
}

@media screen and (min-width:40em) {
  .pricing__group--black .pricing__button {
      margin-top: 0
  }
}

.pricing__group--black .pricing__button:focus, .pricing__group--black .pricing__button:hover {
  border-color: #fff
}

.pricing__group + .pricing__group {
  margin-top: 5px
}

.pricing__title {
  border-bottom: 1px solid #ededed;
  color: #e06116;
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 18px;
  padding-top: 19px;
  text-align: center;
  width: 100%
}

.pricing__cost, .pricing__costs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.pricing__cost {
  padding: 15px 15px 20px;
  width: 100%
}

@media screen and (min-width:40em) {
  .pricing__cost {
      padding: 20px 15px 30px
  }
}

.pricing__cost-title {
  font-size: 14px;
  line-height: 20px
}

@media screen and (min-width:40em) {
  .pricing__cost-title {
      font-size: 18px
  }
}

.pricing__cost-title + .pricing__cost-value {
  margin-top: 9px
}

.pricing__cost-subtitle {
  color: #8e929e;
  display: block;
  font-size: 12px;
  width: 100%
}

@media screen and (min-width:40em) {
  .pricing__cost-subtitle {
      font-size: 14px
  }
}

.pricing__cost-value {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #e06116;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 44px
}

@media screen and (min-width:40em) {
  .pricing__cost-value {
      font-size: 52px;
      line-height: 66px
  }
}

.pricing__cost-value + .pricing__cost-title {
  margin-top: 5px
}

.pricing__cost-plus {
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 400
}

@media screen and (min-width:40em) {
  .pricing__cost-plus {
      font-size: 18px
  }
}

.pricing__cost-byline {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 19px;
  line-height: 20px
}

@media screen and (min-width:40em) {
  .pricing__cost-byline {
      font-size: 18px
  }
}

.pricing__cost + .pricing__cost {
  border-left: 1px solid #ededed
}

.pricing__cost:not(:only-child) {
  margin-top: 19px;
  max-width: 50%;
  padding-bottom: 20px
}

.pricing__cost--center {
  text-align: center
}

.pricing__partners {
  margin-top: 24px;
  text-align: center
}

.pricing__partners-title {
  color: #8e929e;
  font-size: 14px;
  line-height: 20px
}

@media screen and (min-width:40em) {
  .pricing__partners-title {
      font-size: 18px
  }
}

.pricing__partners-list {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0
}

.pricing__partners-item {
  margin-left: 21px;
  margin-right: 21px;
  margin-top: 25px
}

.pricing__partners-image {
  display: block
}

.pricing__partners-smallprint {
  color: #2a2a2a;
  font-size: 12px;
  margin-bottom: 30px;
  margin-top: 20px
}

@media screen and (min-width:40em) {
  .pricing__partners-smallprint {
      font-size: 14px
  }
}

.pricing__partners-smallprint a {
  color: #e06116;
  text-decoration: underline
}

.pricing__partners-smallprint a:focus, .pricing__partners-smallprint a:hover {
  color: #ef5d07
}

.pricing__line {
  border-top: 1px solid #ededed;
  color: #2a2a2a;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 19px;
  text-align: center
}

@media screen and (min-width:40em) {
  .pricing__line {
      font-size: 18px
  }
}

.pricing__line strong {
  font-weight: 600
}

.pricing__line span {
  color: #e06116;
  font-weight: 700
}

.pricing__line a {
  color: #e06116;
  font-weight: 600;
  text-decoration: underline
}

.pricing__line a:focus, .pricing__line a:hover {
  color: #ef5d07
}

.pricing__line + .pricing__line {
  border-top: none;
  padding-top: 0
}

.pricing__box {
  border: 1px solid #ededed;
  margin-top: 26px;
  margin-left: 35px;
  margin-right: 35px;
  padding-bottom: 12px;
  padding-top: 19px;
  text-align: center
}

.pricing__box-title {
  color: #8e929e;
  font-size: 14px;
  line-height: 20px
}

@media screen and (min-width:40em) {
  .pricing__box-title {
      font-size: 18px
  }
}

.pricing__box-byline {
  color: #2a2a2a;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 5px
}

@media screen and (min-width:40em) {
  .pricing__box-byline {
      font-size: 32px;
      line-height: 40px
  }
}

.pricing__box-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0
}

.pricing__box-item {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 4px
}

@media screen and (min-width:40em) {
  .pricing__box-item {
      font-size: 14px
  }
}

.pricing__button {
  margin: 34px 16px 15px
}

.pricing__features {
  border-top: 1px solid #ededed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px
}

.pricing__features-list {
  margin-bottom: 0;
  margin-top: 0
}

.pricing__features-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px
}

@media screen and (min-width:40em) {
  .pricing__features-item {
      font-size: 18px;
      margin-top: 30px
  }
}

.pricing__features-item:last-child {
  margin-bottom: 20px
}

@media screen and (min-width:40em) {
  .pricing__features-item:last-child {
      margin-bottom: 30px
  }
}

.pricing__features-tick {
  background-color: #1eada7;
  background-image: linear-gradient(45deg, #1eada7, #218dbd);
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 9px rgba(0, 0, 0, .22);
  box-shadow: 0 1px 9px rgba(0, 0, 0, .22);
  height: 25px;
  margin-right: 15px;
  width: 25px
}

@media screen and (min-width:40em) {
  .pricing__features-tick {
      margin-right: 30px
  }
}

.pricing__features-tick .svg {
  display: block;
  height: 100%;
  width: 100%
}

.pricing__features-tick .svg circle {
  display: none
}

.pricing__features-tick .svg path {
  fill: #fff
}

.hardware {
  background-color: #fbfcfd;
  padding-bottom: 41px;
  padding-top: 80px
}

@media screen and (min-width:40em) {
  .hardware {
      padding-top: 160px
  }
}

.hardware__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center
}

.hardware__title {
  font-size: 32px;
  font-weight: 600;
  line-height: 41px
}

.hardware__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 30px
}

@media screen and (max-width:68.6875em) {
  .hardware__list {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }
}

@media screen and (min-width:40em) {
  .hardware__list {
      margin-top: 51px
  }
}

.hardware__product {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  max-width: 600px;
  width: 100%
}

@media screen and (max-width:68.6875em) {
  .hardware__product:not(:first-child) {
      margin-top: 6px
  }
}

@media screen and (min-width:68.75em) {
  .hardware__product {
      -ms-flex-preferred-size: 33%;
      flex-basis: 33%
  }
}

@media screen and (min-width:68.75em) {
  .hardware__product:first-child {
      margin-right: 13.33333333px
  }
}

@media screen and (min-width:68.75em) {
  .hardware__product:nth-child(2) {
      margin-left: 6.66666667px;
      margin-right: 6.66666667px
  }
}

@media screen and (min-width:68.75em) {
  .hardware__product:nth-child(3) {
      margin-left: 13.33333333px
  }
}

.hardware__product-title {
  color: #e06116;
  font-size: 18px;
  line-height: 26px;
  margin-top: 31px
}

.hardware__product-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 25px
}

.hardware__product-icon .svg {
  display: block
}

.hardware__product-price {
  border-top: 1px solid #ededed;
  color: #2a2a2a;
  margin-bottom: 0;
  margin-top: 33px;
  padding-top: 19px;
  padding-bottom: 24px
}

.hardware__smallprint {
  color: #2a2a2a;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 35px
}

.hardware__smallprint a {
  color: #e06116
}

.hardware__smallprint a:focus, .hardware__smallprint a:hover {
  color: #ef5d07;
  text-decoration: underline
}

.fm__section {
  border: none;
  border-left: 1px solid;
  display: block;
  padding: 0 0 70px 30px;
  position: relative;
  margin-left: 0;
  margin-right: 0
}

@media screen and (min-width:40em) {
  .fm__section {
      padding-left: 45px
  }
}

.fm__section:before {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  height: 27px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: -15px;
  position: absolute;
  top: -4px;
  width: 27px
}

.fm__section:first-child::before {
  content: "1"
}

.fm__section:nth-child(2):before {
  content: "2"
}

.fm__section:nth-child(3):before {
  content: "3"
}

.fm__section:nth-child(4):before {
  content: "4"
}

.fm__section:nth-child(5):before {
  content: "5"
}

.fm__section:nth-child(6):before {
  content: "6"
}

.fm__title {
  color: #e06116;
  font-size: 14px;
  line-height: 20px
}

.fm__title + .fm__description {
  margin-top: 10px
}

.fm__description {
  color: #2a2a2a;
  margin-bottom: 0;
  margin-top: 35px
}

.fm__grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%
}

.fm__grid--end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

@media (min-width:665px) {
  .fm__grid--2 .fm__item {
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%
  }
}

@media (min-width:665px) {
  .fm__grid--2 .fm__item:nth-child(odd) {
      padding-left: 0;
      padding-right: 25px
  }
}

@media (min-width:665px) {
  .fm__grid--2 .fm__item:nth-child(2n + 2) {
      padding-left: 25px;
      padding-right: 0
  }
}

.fm__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 30px;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  width: 100%
}

@media (min-width:665px) and (max-width:949px) {
  .fm__item {
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%
  }
}

@media (min-width:950px) and (max-width:1099px) {
  .fm__item {
      -ms-flex-preferred-size: 33.333%;
      flex-basis: 33.333%;
      max-width: 33.333%
  }
}

@media screen and (min-width:68.75em) {
  .fm__item {
      -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
      max-width: 25%
  }
}

@media (min-width:665px) and (max-width:949px) {
  .fm__item:nth-child(odd) {
      padding-right: 25px
  }
}

@media (min-width:665px) and (max-width:949px) {
  .fm__item:nth-child(2n + 2) {
      padding-left: 25px
  }
}

@media (min-width:950px) and (max-width:1099px) {
  .fm__item:nth-child(3n + 1) {
      padding-right: 33.33333333px
  }
}

@media (min-width:950px) and (max-width:1099px) {
  .fm__item:nth-child(3n + 2) {
      padding-left: 16.66666667px;
      padding-right: 16.66666667px
  }
}

@media (min-width:950px) and (max-width:1099px) {
  .fm__item:nth-child(3n + 3) {
      padding-left: 33.33333333px
  }
}

@media screen and (min-width:68.75em) {
  .fm__item:nth-child(4n + 1) {
      padding-right: 37.5px
  }
}

@media screen and (min-width:68.75em) {
  .fm__item:nth-child(4n + 2) {
      padding-left: 12.5px;
      padding-right: 25px
  }
}

@media screen and (min-width:68.75em) {
  .fm__item:nth-child(4n + 3) {
      padding-left: 25px;
      padding-right: 12.5px
  }
}

@media screen and (min-width:68.75em) {
  .fm__item:nth-child(4n + 4) {
      padding-left: 37.5px
  }
}

@media screen and (min-width:68.75em) {
  .fm__item:not(.fm__item--medium) + .fm__item--medium {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important
  }

  .fm__item:not(.fm__item--medium) + .fm__item--medium:nth-child(7) {
      padding-left: 25px !important;
      padding-right: 0 !important
  }
}

.fm__item--medium {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%
}

@media (min-width:665px) and (max-width:949px) {
  .fm__item--medium {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%
  }
}

@media (min-width:950px) and (max-width:1099px) {
  .fm__item--medium {
      padding-right: 16.66666667px !important;
      -ms-flex-preferred-size: 66.666%;
      flex-basis: 66.666%;
      max-width: 66.666%
  }
}

@media screen and (min-width:68.75em) {
  .fm__item--medium:first-child {
      padding-right: 25px !important
  }
}

@media screen and (min-width:68.75em) {
  .fm__item--medium:nth-child(2) {
      padding-left: 25px !important
  }
}

@media screen and (min-width:68.75em) {
  .fm__item--medium:nth-child(7) {
      padding-right: 0 !important
  }
}

@media screen and (min-width:68.75em) {
  .fm__item--medium {
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%
  }
}

.fm__item > .fm__description {
  margin-top: 25px
}

.fm__wrapper {
  position: relative
}

.fm__wrapper + .fm__smallprint {
  max-width: 350px
}

.fm__wrapper--file .fm__input {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1
}

.fm__wrapper--file svg {
  margin-top: -11px;
  height: 18px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  width: 22px
}

.fm__textarea {
  background-color: #fff;
  border: 1px solid #dbdfe5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #2a2a2a;
  height: 90px;
  margin-top: 20px;
  padding: 10px 15px;
  outline: none;
  width: 100%
}

.fm__textarea:focus, .fm__textarea:hover {
  border-color: #b5bdca
}

.fm__fake-input, .fm__input, .fm__select {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #b5bdca;
  color: #2a2a2a;
  line-height: 20px;
  outline: none;
  margin-top: 20px;
  -webkit-transition: border-color .1s ease-in-out;
  transition: border-color .1s ease-in-out;
  width: 100%
}

.fm__fake-input--has-value + .fm__label, .fm__input--has-value + .fm__label,
.fm__select-el--has-value + .fm__label {
  bottom: 42px;
  color: #8e929e;
  font-size: 14px
}

.fm__fake-input--has-value + .fm__label span, .fm__input--has-value + .fm__label span,
.fm__select-el--has-value + .fm__label span {
  color: #8e929e
}

.fm__fake-input--has-error:not(.fm__input--has-value) + .fm__label,
.fm__fake-input--has-error:not(.fm__input--has-value) + .fm__label span,
.fm__input--has-error:not(.fm__input--has-value) + .fm__label, .fm__input--has-error:not(.fm__input--has-value) + .fm__label span,
.fm__select-el--has-error:not(.fm__input--has-value) + .fm__label,
.fm__select-el--has-error:not(.fm__input--has-value) + .fm__label span {
  color: red
}

.fm__fake-input:not(.fm__input--has-value):focus + .fm__label,
.fm__fake-input:not(.fm__input--has-value):focus + .fm__label:after,
.fm__fake-input:not(.fm__input--has-value):focus + .fm__label span,
.fm__input:not(.fm__input--has-value):focus + .fm__label, .fm__input:not(.fm__input--has-value):focus + .fm__label:after,
.fm__input:not(.fm__input--has-value):focus + .fm__label span, .fm__select-el:not(.fm__input--has-value):focus + .fm__label,
.fm__select-el:not(.fm__input--has-value):focus + .fm__label:after,
.fm__select-el:not(.fm__input--has-value):focus + .fm__label span {
  color: #ededed
}

.fm__fake-input:focus, .fm__input:focus, .fm__select-el:focus {
  border-color: #e06116
}

.fm__fake-input:focus + .fm__label, .fm__input:focus + .fm__label,
.fm__select-el:focus + .fm__label {
  color: #e06116
}

.fm__fake-input:focus ~ .fm__fake-input, .fm__input:focus ~ .fm__fake-input,
.fm__select-el:focus ~ .fm__fake-input {
  border-color: #e06116
}

.fm__fake-input[required] + .fm__label:after, .fm__input[required] + .fm__label:after,
.fm__select-el[required] + .fm__label:after {
  content: "*";
  color: #e06116;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out
}

.fm__fake-input, .fm__input {
  padding: 8px 0 13px
}

.fm__fake-input:-webkit-autofill, .fm__input:-webkit-autofill {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 1000px #fff inset
}

.fm__fake-input {
  cursor: pointer;
  display: block;
  min-height: 20px
}

.fm__label {
  color: #2a2a2a;
  cursor: text;
  line-height: 20px;
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 14px;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out
}

.fm__label span {
  color: #8e929e;
  font-size: 14px
}

.fm__select {
  cursor: pointer;
  padding: 0;
  position: relative;
  height: 42px
}

.fm__select .arrow-down {
  display: block;
  height: 6px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  width: 12px
}

.fm__select .arrow-down path {
  fill: #b5bdca
}

.fm__select-el {
  background: transparent;
  background-image: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  font: inherit;
  height: 42px;
  outline: none !important;
  padding-bottom: 13px;
  padding-top: 8px;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none
}

.fm__select-el:focus {
  outline: none;
  border-radius: none
}

.fm__select-el::-ms-expand {
  display: none
}

.fm__select-el:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000
}

.fm__smallprint {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0;
  margin-top: 8px
}

.fm__smallprint strong {
  font-style: normal
}

.fm__button {
  margin-left: auto;
  margin-top: 30px
}

.fm__button[disabled] {
  opacity: .5
}

.fm--orange .fm__section {
  border-color: #e06116
}

.fm--orange .fm__section:before {
  background-color: #fbeadf;
  border-color: #e06116;
  color: #e06116
}

.fm--black .fm__section {
  border-color: #2a2a2a
}

.fm--black .fm__section:before {
  background-color: #dbdbdb;
  border-color: #2a2a2a;
  color: #2a2a2a
}

.fm--purple .fm__section {
  border-color: #896ec5
}

.fm--purple .fm__section:before {
  background-color: #e5dff2;
  border-color: #896ec5;
  color: #896ec5
}

.fm--green .fm__section {
  border-color: #44ba91
}

.fm--green .fm__section:before {
  background-color: #e2f4ee;
  border-color: #44ba91;
  color: #44ba91
}

.fm--blue .fm__section {
  border-color: #0a4a6e
}

.fm--blue .fm__section:before {
  background-color: #dbe5ea;
  border-color: #0a4a6e;
  color: #0a4a6e
}

.fm--grey-bg, .fm--grey-bg .fm__fake-input, .fm--grey-bg .fm__input,
.fm--grey-bg .fm__select, .fm--grey-bg .fm__textarea {
  background-color: #fbfcfd
}

.fm--grey-bg .fm__fake-input:-webkit-autofill, .fm--grey-bg .fm__input:-webkit-autofill {
  background-color: #fbfcfd !important;
  -webkit-box-shadow: 0 0 0 1000px #fbfcfd inset
}

.fm--signup {
  padding-top: 33px
}

.fm--signup .fm__container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px
}

.fm__recaptcha {
  margin-top: 30px;
  margin-left: auto
}

.selectables {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 10px
}

.selectables__item {
  position: relative
}

.selectables__item--with-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

@media screen and (max-width:39.9375em) {
  .selectables__item--with-text {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }
}

.selectables__input {
  left: 5px;
  position: absolute;
  top: 5px;
  z-index: -1
}

.selectables__input:checked + .selectables__label, .selectables__input:checked + .selectables__select {
  background: #0f567e;
  border-color: #0f567e;
  color: #fff
}

.selectables__input:checked + .selectables__label .arrow-down path,
.selectables__input:checked + .selectables__select .arrow-down path {
  fill: #fff
}

.selectables__input:checked ~ .selectables__text {
  opacity: 1
}

.selectables__input:not(:checked) + .selectables__label {
  border-color: #ededed
}

.selectables__input:focus + .selectables__label {
  border-color: #b5bdca
}

.selectables__input:checked:focus + .selectables__label {
  border-color: #0a4a6e
}

.selectables__label, .selectables__select, .selectables__text {
  margin-right: 10px;
  margin-top: 10px;
  position: relative
}

.selectables__label, .selectables__select, .selectables__text-input {
  border: 1px solid;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2)
}

.selectables__label, .selectables__text-input {
  display: block;
  white-space: nowrap
}

.selectables__label:focus, .selectables__label:hover, .selectables__text-input:focus,
.selectables__text-input:hover {
  border-color: #b5bdca
}

.selectables__label--large-margin, .selectables__text-input--large-margin {
  margin-right: 34px
}

.selectables__label--large-margin + .selectables__description,
.selectables__text-input--large-margin + .selectables__description {
  right: 34px
}

.selectables__label {
  font-size: 16px;
  line-height: 28px;
  padding: 5px 23px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  z-index: 1
}

.selectables__label:focus, .selectables__label:hover {
  cursor: pointer
}

.selectables__text {
  opacity: 0;
  max-width: 100%
}

.selectables__text, .selectables__text-input {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  width: 100%
}

.selectables__text-input {
  border-color: #ededed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #0a4a6e;
  font-size: 12px;
  line-height: 15px;
  max-width: 320px;
  outline: none;
  padding: 12px 15px 11px
}

@media screen and (min-width:40em) {
  .selectables__text-input {
      min-width: 320px
  }
}

.selectables__text-input::-webkit-input-placeholder {
  color: #b5bdca
}

.selectables__text-input:-ms-input-placeholder, .selectables__text-input::-ms-input-placeholder {
  color: #b5bdca
}

.selectables__text-input::placeholder {
  color: #b5bdca
}

.selectables__text-input:focus + .selectables__arrow, .selectables__text-input:hover + .selectables__arrow {
  border-color: #b5bdca
}

.selectables__select {
  border-color: #ededed;
  cursor: pointer
}

.selectables__select .arrow-down {
  display: block;
  height: 6px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 17px;
  top: 50%;
  width: 12px
}

.selectables__select .arrow-down path {
  fill: #b5bdca
}

.selectables__select select {
  background: transparent;
  background-image: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  font: inherit;
  height: 40px;
  padding: 0 46px 0 23px;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none
}

.selectables__select select:focus {
  outline: none
}

.selectables__select select::-ms-expand {
  display: none
}

.selectables__arrow {
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  height: 8px;
  left: -4px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(55deg) skew(20deg);
  transform: rotate(55deg) skew(20deg);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  width: 8px
}

.selectables__description {
  font-size: 12px;
  font-style: italic;
  left: 0;
  line-height: 15px;
  margin-bottom: 0;
  margin-top: 0;
  position: absolute;
  right: 10px;
  text-align: right;
  top: 54px
}

.selectables__description a {
  color: #e06116;
  font-style: normal;
  font-weight: 600;
  text-decoration: underline
}

.selectables__description a:focus, .selectables__description a:hover {
  color: #ef5d07
}

.selectables__hidden {
  display: none
}

.boxes {
  padding-bottom: 100px
}

@media screen and (min-width:40em) {
  .boxes {
      padding-bottom: 150px
  }
}

@media screen and (min-width:68.75em) {
  .boxes {
      padding-bottom: 200px
  }
}

.boxes__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1270px
}

.boxes__box {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #0a4a6e;
  -ms-flex-preferred-size: 400px;
  flex-basis: 400px;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  padding: 20px 15px;
  text-align: center;
  width: 400px
}

@media screen and (min-width:40em) {
  .boxes__box {
      font-size: 22px;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
      padding: 30px 20px
  }
}

.boxes__box:focus, .boxes__box:hover {
  border-color: #e06116;
  color: #e06116
}

@-webkit-keyframes pulseDot {
  0% {
      opacity: .5;
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }
}

@keyframes pulseDot {
  0% {
      opacity: .5;
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }
}

.timeline {
  background-color: #fbfcfd;
  padding-bottom: 50px;
  padding-top: 60px
}

@media screen and (min-width:40em) {
  .timeline {
      padding-top: 100px
  }
}

.timeline__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px
}

.timeline__container, .timeline__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.timeline__list {
  margin-bottom: 0;
  margin-top: 0;
  max-width: 1000px;
  position: relative;
  width: 100%
}

@media screen and (min-width:40em) {
  .timeline__list {
      padding-bottom: 200px
  }
}

.timeline__list:after {
  background-color: #e06116;
  bottom: 12px;
  content: "";
  left: 0;
  position: absolute;
  top: 14px;
  -webkit-transition: opacity 2s ease-in, bottom 5s ease-in;
  transition: opacity 2s ease-in, bottom 5s ease-in;
  width: 1px
}

@media screen and (min-width:40em) {
  .timeline__list:after {
      bottom: 100%;
      left: 50%;
      top: 26px
  }
}

@media screen and (min-width:40em) {
  .timeline__list[data-started]:after {
      bottom: 0
  }
}

.timeline__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #2a2a2a;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  font-weight: 700;
  position: relative
}

@media screen and (max-width:39.9375em) {
  .timeline__item {
      padding-left: 70px
  }
}

@media screen and (min-width:40em) {
  .timeline__item {
      font-size: 32px;
      width: 50%
  }
}

.timeline__item:after, .timeline__item:before {
  -webkit-animation: pulseDot 1s infinite alternate;
  animation: pulseDot 1s infinite alternate;
  background-color: #e06116;
  border-radius: 50%;
  content: "";
  position: absolute
}

.timeline__item:before {
  height: 14px;
  opacity: .65;
  top: 8px;
  width: 14px
}

@media screen and (max-width:39.9375em) {
  .timeline__item:before {
      left: 24px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:before {
      top: 20px
  }
}

.timeline__item:after {
  height: 8px;
  top: 11px;
  width: 8px
}

@media screen and (max-width:39.9375em) {
  .timeline__item:after {
      left: 27px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:after {
      top: 23px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(odd) {
      padding-right: 90px;
      text-align: right
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(odd):before {
      right: 24px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(odd):after {
      right: 27px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(odd) .timeline__join {
      right: 0
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(odd) .timeline__index {
      right: 80px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(2n) {
      margin-left: auto;
      padding-left: 55px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(2n):before {
      left: 24px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(2n):after {
      left: 27px
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(2n) .timeline__join {
      left: 0
  }
}

@media screen and (min-width:40em) {
  .timeline__item:nth-child(2n) .timeline__index {
      left: 45px
  }
}

.timeline__item:not(:first-child) {
  margin-top: 60px
}

@media screen and (min-width:40em) {
  .timeline__item:not(:first-child) {
      margin-top: 80px
  }
}

.timeline__join {
  background-color: #e06116;
  height: 1px;
  position: absolute;
  top: 14px;
  width: 28px
}

@media screen and (max-width:39.9375em) {
  .timeline__join {
      left: 0
  }
}

@media screen and (min-width:40em) {
  .timeline__join {
      top: 26px
  }
}

.timeline__index {
  color: #2a2a2a;
  font-size: 60px;
  font-weight: 400;
  opacity: .04;
  position: absolute;
  top: -35px
}

@media screen and (min-width:40em) {
  .timeline__index {
      font-size: 120px;
      top: -80px
  }
}

.timeline__button {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px
}

@media screen and (max-width:39.9375em) {
  .timeline__button {
      margin-top: 60px
  }
}

.video-row {
  background-color: #fbfcfd
}

.video-row__container {
  padding-bottom: 80px;
  padding-top: 65px;
  margin-left: auto;
  margin-right: auto;
  max-width: 945px
}

.video-row__title {
  font-weight: 700;
  text-align: center;
  width: 100%
}

.video-row__videos {
  margin-top: 45px
}

.video-row__video {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px
}

@media screen and (max-width:39.9375em) {
  .video-row__video:not(:first-child) {
      margin-top: 20px
  }
}

.video-row__button {
  border: none;
  border-radius: 4px;
  outline: none;
  overflow: hidden;
  padding-top: 59%;
  position: relative;
  z-index: 1;
  width: 100%
}

.video-row__button svg {
  height: 34px;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
  position: absolute;
  width: 34px;
  top: 50%
}

.video-row__button:focus .video-row__gradient, .video-row__button:hover .video-row__gradient {
  opacity: .95
}

.video-row__gradient, .video-row__img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1
}

.video-row__gradient {
  background-image: -webkit-gradient(linear, left top, right top, from(#5256a8), to(#2b71ca));
  background-image: linear-gradient(90deg, #5256a8, #2b71ca);
  opacity: .87
}

.video-row__img {
  background-color: red;
  background-position: 50%;
  background-size: cover
}

.video-row__name {
  font-weight: 700;
  margin-left: 3px;
  margin-top: 15px;
  width: 100%
}

.rn-form {
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 58px;
  padding: 45px 15px
}

.rn-form__container {
  max-width: 500px
}

.rn-form__logo {
  height: auto;
  max-width: 100%
}

.rn-form__title {
  color: #0a4a6e;
  margin-top: 30px;
  font-weight: 700
}

.rn-form__form {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.rn-form__button {
  margin-top: 65px
}

.rn-form__recaptcha {
  margin-top: 45px
}

.leadership {
  padding-bottom: 80px;
  padding-top: 60px
}

@media screen and (min-width:40em) {
  .leadership {
      padding-bottom: 160px;
      padding-top: 75px
  }
}

.leadership__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 925px
}

.leadership__title {
  font-weight: lighter;
  line-height: 1.15;
  text-align: center;
  width: 100%
}

.leadership__title span {
  color: #e06116
}

.leadership__team {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%
}

@media screen and (max-width:39.9375em) {
  .leadership__team {
      max-width: 295px
  }
}

.leadership__member {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 40px;
  width: 100%
}

@media screen and (min-width:40em) {
  .leadership__member {
      margin-top: 88px;
      width: 33.333%
  }
}

@media screen and (min-width:40em) {
  .leadership__member:nth-child(3n + 1) {
      padding-right: 13.33333333px
  }
}

@media screen and (min-width:40em) {
  .leadership__member:nth-child(3n + 2) {
      padding-left: 6.66666667px;
      padding-right: 6.66666667px
  }
}

@media screen and (min-width:40em) {
  .leadership__member:nth-child(3n + 3) {
      padding-left: 13.33333333px
  }
}

.leadership__link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: left
}

.leadership__headshot {
  -webkit-box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  box-shadow: 0 2px 53px rgba(0, 0, 0, .06);
  display: block;
  height: auto;
  max-width: 100%
}

.leadership__name {
  color: #0a4a6e;
  margin-top: 12px
}

.leadership__position {
  margin-bottom: 0;
  margin-top: 2px
}

.leadership-lightbox__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fafafa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 22px
}

.leadership-lightbox__headshot {
  border-radius: 50%;
  height: 100px;
  overflow: hidden;
  width: 100px
}

.leadership-lightbox__img {
  display: block
}

.leadership-lightbox__name {
  color: #0a4a6e;
  margin-top: 16px
}

.leadership-lightbox__position {
  margin-bottom: 0;
  margin-top: 0
}

.leadership-lightbox__content {
  padding: 15px 50px 46px;
  text-align: left
}

.leadership-lightbox__content p {
  color: #2a2a2a;
  margin-top: 15px
}

.integrate-more * , .integrate-options * , .integrate__hero * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.integrate__container {
  position: relative;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 15px
}

.integrate__hero {
  position: relative;
  min-height: 510px;
  text-align: center;
  z-index: 0;
  overflow: hidden
}

.integrate__hero-content {
  max-width: 630px;
  margin: 0 auto;
  padding: 135px 0 0
}

@media screen and (max-width:68.6875em) {
  .integrate__hero-content {
      padding: 375px 0 80px
  }
}

.integrate__hero-title {
  padding-bottom: 25px;
  font-size: 52px;
  font-weight: 600;
  color: #0ab7c5
}

@media screen and (max-width:68.6875em) {
  .integrate__hero-title {
      font-size: 32px
  }
}

.integrate__hero-title span {
  color: #0a4a6e
}

.integrate__hero-byline {
  font-size: 18px;
  line-height: 1.67;
  color: #2a2a2a
}

@media screen and (min-width:40em) {
  .integrate__hero-byline span {
      display: block
  }
}

.integrate__hero-cta-text {
  padding: 50px 0 30px;
  font-size: 16px;
  font-weight: 600;
  color: #2a2a2a
}

@media screen and (max-width:68.6875em) {
  .integrate__hero-cta-text {
      padding: 30px 50px
  }
}

.integrate__hero-cta-button {
  display: inline-block;
  padding: 12px 50px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
  background: #0ab7c5;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.integrate__hero-cta-button:active, .integrate__hero-cta-button:focus,
.integrate__hero-cta-button:hover {
  color: #fff
}

.integrate__hero-cta-button:hover {
  -webkit-box-shadow: 6px 19px 19px 0 rgba(10, 183, 197, .25);
  box-shadow: 6px 19px 19px 0 rgba(10, 183, 197, .25);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px)
}

.integrate__hero-cta-button:active {
  background: #068893;
  -webkit-box-shadow: 6px 19px 19px 0 rgba(10, 183, 197, 0);
  box-shadow: 6px 19px 19px 0 rgba(10, 183, 197, 0);
  -webkit-transform: translateY(1px);
  transform: translateY(1px)
}

.integrate__hero-bg {
  position: absolute;
  width: 100%;
  max-width: 1240px;
  top: 85px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1
}

@media screen and (max-width:68.6875em) {
  .integrate__hero-bg {
      height: 100%
  }
}

.integrate__hero-bg .svg {
  position: absolute
}

.integrate__hero-bg .svg--blob {
  position: relative
}

@media screen and (min-width:40em) {
  .integrate__hero-bg .svg--blob {
      max-width: 100%
  }
}

@media screen and (max-width:68.6875em) {
  .integrate__hero-bg .svg--blob {
      width: 140%;
      top: 225px
  }
}

@media screen and (max-width:39.9375em) {
  .integrate__hero-bg .svg--blob {
      top: 250px;
      left: -20%
  }
}

.integrate__hero-bg .svg--planning {
  max-width: 240px;
  top: 30px;
  left: 60px
}

@media screen and (max-width:68.6875em) {
  .integrate__hero-bg .svg--planning {
      max-width: 65%;
      top: -100px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
  }
}

.integrate__hero-bg .svg--computer {
  max-width: 280px;
  top: 0;
  right: 70px
}

@media screen and (max-width:68.6875em) {
  .integrate__hero-bg .svg--computer {
      display: none
  }
}

.integrate__hero-bg .svg--business-man {
  max-width: 130px;
  bottom: 0;
  right: 140px
}

@media screen and (max-width:68.6875em) {
  .integrate__hero-bg .svg--business-man, .integrate__hero-bg .svg .hide-on-mobile {
      display: none
  }
}

.integrate__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none
}

.integrate__column {
  width: 100%
}

@media screen and (min-width:40em) {
  .integrate__column--1\/2, .integrate__column--1\/3 {
      width: 50%
  }
}

@media screen and (min-width:68.75em) {
  .integrate__column--1\/3 {
      width: 33.3333%
  }
}

.integrate__card {
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.integrate__card-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 150px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.integrate-more .integrate__card-image {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

@media screen and (min-width:40em) {
  .integrate__card--alt .integrate__card-image {
      margin-right: 35px
  }
}

.integrate__card-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.integrate__card-title {
  margin: 15px 0 10px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.9;
  color: #0a4a6e
}

.integrate__card-description {
  margin: 0 0 15px;
  font-size: 16px;
  line-height: 1.75;
  color: #2a2a2a
}

.integrate__card-link {
  font-size: 16px;
  font-weight: 600;
  color: #0ab7c5;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.integrate__card-link:hover {
  color: #068893
}

.integrate__card-link:hover:after {
  border-color: transparent transparent transparent #068893
}

.integrate__card-link:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 4px;
  border-style: solid;
  border-width: 4.5px 0 4.5px 5px;
  border-color: transparent transparent transparent #0ab7c5;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.integrate-options {
  padding: 40px 0
}

@media screen and (max-width:68.6875em) {
  .integrate-options {
      padding: 25px 0
  }
}

.integrate-options__title {
  margin: 0 0 10px;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: #0ab7c5
}

.integrate-options__byline {
  margin: 0 0 40px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #0a4a6e
}

.integrate-options .integrate__card {
  padding: 20px 30px 30px;
  border: 1px solid transparent;
  border-radius: 4px
}

@media screen and (min-width:68.75em) {
  .integrate-options .integrate__card {
      padding: 50px 35px
  }

  .integrate-options .integrate__card--alt {
      padding: 30px 35px 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between
  }
}

.integrate-options .integrate__card:focus, .integrate-options .integrate__card:hover {
  border-color: #ececec;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .05);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .05)
}

.integrate-more {
  padding: 50px 15px 15px;
  text-align: center;
  background: #f6f7f9;
  border-top: 1px solid #e8e8e8
}

@media screen and (min-width:68.75em) {
  .integrate-more {
      padding: 50px 15px 80px
  }
}

.integrate-more__title {
  margin-bottom: 50px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.6;
  color: #0a4a6e
}

@media screen and (min-width:68.75em) {
  .integrate-more__title {
      margin-bottom: 80px
  }
}

@media screen and (min-width:68.75em) {
  .integrate-more .integrate__column:not(:last-child) .integrate__card {
      border-right: 1px solid rgba(0, 0, 0, .1)
  }
}

.integrate-more .integrate__card {
  margin-bottom: 50px
}

.integrate-more .integrate__card-title {
  margin: 30px 0 0;
  color: #0ab7c5;
  line-height: 22px
}

.video-lightbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100
}

.video-lightbox__container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 2rem
}

.video-lightbox__content {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%
}

.video-lightbox__content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.video-lightbox__close {
  position: absolute;
  top: .5rem;
  right: 1rem;
  color: #fff;
  z-index: 0
}

.video-lightbox__backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, .65)
}
